import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';

const InstallationChargesModal = props => {
    const [open, setOpen] = useState(false);
    const [userInputValue, setUserInputValue] = useState(0);
    const [InstalationChargesGlobal, setInstalationChargesGlobal] = useState(props.Instalationchargesreal);
    const [userInputValuetotal, setUserInputValuetotal] = useState(props.Instalationchargesreal);
    console.log(props)
    useEffect(() => {
        setUserInputValuetotal(props.Instalationchargesreal)
        setInstalationChargesGlobal(props.Instalationchargesreal)
        setOpen(props.Open);
    }, [props.Open]);
    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
            props.OnExit();
        }
    };

    const handleInput = event => {
        // if (event.target.value >= 0 && event.target.value < 101) {
        if (event.target.value >= -100) {
            setUserInputValue(parseFloat(event.target.value));

            const TotalCalAmount = InstalationChargesGlobal + InstalationChargesGlobal*event.target.value/100;
            setUserInputValuetotal(parseFloat(TotalCalAmount));
        }
    }
    const handleInputtotal = event => {

        // if (event.target.value > 0 && InstalationChargesGlobal*2>event.target.value){
        if (event.target.value > 0){
            setUserInputValuetotal(parseFloat(event.target.value));
            const TotalcalMargin = 100*(event.target.value - InstalationChargesGlobal)/InstalationChargesGlobal
            setUserInputValue(parseFloat(TotalcalMargin.toFixed(2)));
        }
       
         

    }

    

    const handleSubmit = () => {
        // props.CallbackSubmit(userInputValue);
        props.CallbackSubmit({"margin":userInputValue, "installation":InstalationChargesGlobal, "totalvalue":userInputValuetotal});
        console.log(typeof (userInputValue))
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Enter Installation Charges (if any)"}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Margin"
                        size='small'
                        name='input'
                        type='number'
                        value={userInputValue}
                        onChange={handleInput}
                    />%
                    <br /><br />
                    <TextField
                        label="Installation Charges"
                        size='small'
                        name='input'
                        type='number'
                        disabled='true'
                        value={InstalationChargesGlobal}
                    />
                    <br /><br />
                    <TextField
                        label="Final Amount"
                        size='small'
                        name='input'
                        type='number'
                        value={userInputValuetotal}
                        onChange={handleInputtotal}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                    <Button onClick={handleClose} color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default InstallationChargesModal;