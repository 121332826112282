import React, { useEffect, useState } from 'react';
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';

import { MathComponent } from 'mathjax-react';

const useStyles = makeStyles({
  table: {
    minWidth: "90vw",
    maxWidth: "90vw"
  }
});

const MeraKiQuoteTableFormHead = [
  {
    label: 'Meraki',
    value: 'Y',
    showFor: new Set(['T1']),
    // disableFor: new Set(['T1', 'T2', 'T6']),
  },
  {
    label: 'Cisco',
    value: 'N',
    showFor: new Set(['T1']),
    // disableFor: new Set(['T2']),
  }
];



const QuotationOptionsTableFormHead = [
  {
    label: 'Q01 - Proactive Markup',
    value: '1',
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    showFornum: new Set(['1', '2', '3', '5']),
    // disableFor: new Set(['T1', 'T2', 'T6']),
  },
  {
    label: 'Q02 - End Customer Discount',
    value: '2',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    // disableFor: new Set(['T2']),
  },
  {
    label: 'Q03 - Markup on Cisco Consolidated',
    value: '3',
    showFornum: new Set(['1']),
    showFor: new Set(['T1']),
    // disableFor: new Set(['T2']),
  },
  {
    label: 'Q04 - Markup on Cisco Product',
    value: '4',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    // disableFor: new Set(['T2']),
  },
  {
    label: 'Q05 - End Customer Price',
    value: '5',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    // disableFor: new Set(['T2']),
  }
];

const QuotationOptionsTableFormBody = [
  {
    id: '1',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    label: 'End Customer Price = (Cisco Consolidated Transfer + Handling)*(1 + Other Cost)/(1-Markup)',
    texLabel: String.raw`\displaylines{End Customer Price = \\{\displaylines{(Cisco Consolidated Transfer + Handling)*\\(1+Other Cost)}\over(1-Markup)}}`
  },
  {
    id: '2',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    label: 'End Customer Price = (Cisco Consolidated Transfer)*(1 - End Customer Discount)',
    texLabel: String.raw`\displaylines{End Customer Price = \\{\displaylines{(Cisco Consolidated Transfer)*\\(1 - End Customer Discount)}}}`
  },
  {
    id: '3',
    label: 'End Customer Price = (Cisco Consolidated Transfer)*(1 + Markup on Cisco Consolidated)',
    showFornum: new Set(['1']),
    showFor: new Set(['T1']),
    texLabel: String.raw`\displaylines{End Customer Price = \\{\displaylines{(Cisco Consolidated Transfer)*\\(1 + Markup On Cisco Consolidated)}}}`
  },
  {
    id: '4',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    label: 'End Customer Price = (Cisco Consolidated Transfer)*(1 + Markup on Cisco Product)',
    texLabel: String.raw`\displaylines{End Customer Price = \\{\displaylines{(Cisco Consolidated Transfer)*\\(1 + Markup on Cisco Product)}}}`
  },
  {
    id: '5',
    showFornum: new Set(['1', '2', '3', '5']),
    showFor: new Set(['T1', 'T2', 'T6', 'T6R']),
    label: 'Proactive Markup = 1 - [(Cisco Consolidated Transfer + Handling)*(1 + Other Cost)/End Customer Price]',
    // texLabel: String.raw`\displaylines{ = \\{1 - [(Cisco Consolidated Transfer + Handling)*(1 + Other Cost)/End Customer Price]}}`
    texLabel: String.raw`\displaylines{Proactive Markup = \\1-{\displaylines{\\ {\displaylines{(Cisco Consolidated Transfer + Handling)*\\(1 + Other Cost)\over(End Customer Price)}}}}}`
  }
];

const QuotationTypeTableFormHead = [
  { id: '1', label: 'T1', value: '1', disabled: sessionStorage.getItem('estimate_type_by_login') == "T2" ? true : false },
  { id: '2', label: 'T2', value: '2', disabled: false },
  { id: '3', label: 'T6', value: '3', disabled: sessionStorage.getItem('estimate_type_by_login') == "T2" ? true : false },
  { id: '5', label: 'T6R', value: '5', disabled: sessionStorage.getItem('estimate_type_by_login') == "T2" ? true : false },
  { id: '4', label: 'T4', value: '4', disabled: true }
];
const QuotationTypeTableFormBody = [
  { id: '1', label: 'Cisco Products & Attach TS / Software Services' },
  { id: '2', label: 'Cisco TS / Software Services (Future)' },
  { id: '3', label: 'Cisco XAAS Services' },
  { id: '5', label: 'Cisco XAAS Renewal' },
  { id: '4', label: 'Projects (Future)' }
];

const BillingTableForm = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center"><Typography>Customer Type<br />Parameter - 1</Typography></TableCell>
              <TableCell align="center"><Typography>Goods FTWZ<br />Parameter - 2</Typography></TableCell>
              <TableCell align="center"><Typography>Goods Zero-Rated GST<br />Parameter - 3</Typography></TableCell>
              <TableCell align="center"><Typography>Services Zero-Rated GST<br />Parameter - 4</Typography></TableCell>
              <TableCell align="center"><Typography>Code</Typography></TableCell>
              <TableCell align="center"><Typography color={props.Error ? 'error' : 'inherit'}>Select One</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* First Row = DTA (D)*/}
              <TableCell size="small" align="center">DTA (D)</TableCell>
              <TableCell size="small" align="center">DTA (D)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">DDFF</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "DDFF" || sessionStorage.getItem('cust_type') == "DDFF"}
                  value="DDFF"
                  onChange={props.OnSelectRadioButton}
                  disabled={ sessionStorage.getItem('cust_type') !== "null"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Second Row = SEZ (S)*/}
              <TableCell size="small" align="center" rowSpan={3}>SEZ (S)</TableCell>
              <TableCell size="small" align="center">FTWZ (F)</TableCell>
              <TableCell size="small" align="center">GST - Zero (Z)</TableCell>
              <TableCell size="small" align="center">GST - Zero (Z)</TableCell>
              <TableCell size="small" align="center">SFZZ</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "SFZZ" || sessionStorage.getItem('cust_type') == "SFZZ"}
                  value="SFZZ"
                  onChange={props.OnSelectRadioButton}
                  disabled={ sessionStorage.getItem('cust_type') !== "null"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Second Row = SEZ (S)*/}
              <TableCell size="small" align="center" rowSpan={2}>DTA (D)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">SDFF</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "SDFF" || sessionStorage.getItem('cust_type') == "SDFF"}
                  value="SDFF"
                  onChange={props.OnSelectRadioButton}
                  disabled={ sessionStorage.getItem('cust_type') !== "null"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Second Row = SEZ (S)*/}
              <TableCell size="small" align="center">GST - Zero (Z)</TableCell>
              <TableCell size="small" align="center">GST - Zero (Z)</TableCell>
              <TableCell size="small" align="center">SDZZ</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "SDZZ" || sessionStorage.getItem('cust_type') == "SDZZ"}
                  value="SDZZ"
                  onChange={props.OnSelectRadioButton}
                  disabled={sessionStorage.getItem('cust_type') !== "null"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Third Row = STPI (T)*/}
              <TableCell size="small" align="center" rowSpan={2}>STPI (T)</TableCell>
              <TableCell size="small" align="center">FTWZ (F)</TableCell>
              <TableCell size="small" align="center">GST - Zero (Z)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">TFZF</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "TFZF" || sessionStorage.getItem('cust_type') == "TFZF"}
                  value="TFZF"
                  onChange={props.OnSelectRadioButton}
                  disabled={ sessionStorage.getItem('cust_type') !== "null"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Third Row = STPI (T)*/}
              <TableCell size="small" align="center">DTA (D)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">GST - Full (F)</TableCell>
              <TableCell size="small" align="center">TDFF</TableCell>
              <TableCell size="small" align="center">
                <Radio
                  checked={props.RadioButtonValue === "TDFF" || sessionStorage.getItem('cust_type') == "TDFF"}
                  value="TDFF"
                  onChange={props.OnSelectRadioButton}
                  disabled={ sessionStorage.getItem('cust_type')!=='null'}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

const QuotationOptionsTableForm = props => {
  // console.log(props.QuotationTypeValue)
  console.log(props)
  const classes = useStyles();
  // const [merakiQuote,setMerakiQuote]=useState(false);
  return (
    <React.Fragment>
      {
       
        // (props.QuotationTypeValue !== "0" )
        // &&
        <TableContainer>
          <Table className={classes.table} size="small">

            <TableHead>
              <TableRow>
                {
                  QuotationOptionsTableFormHead.map(header => {


                    console.log(props.QuotationTypeValue)
                    // console.log(header.value)
                    // console.log(header.showFor)
                    // if (
                    //   // ((props.QuotationTypeValue !== "T1" || sessionStorage.getItem('estimate_type_by_login') !== "T1") && header.value === "3")
                    //   // &&
                    //   (!header.showFornum.has(props.QuotationTypeValue) || !header.showFor.has(sessionStorage.getItem('estimate_type_by_login')) && header.value === "3")
                    // ) {
                    //   console.log(header.showFor.has(props.QuotationTypeValue))
                    //   console.log(props.QuotationTypeValue)
                    //   return <div key={header.value}></div>;
                    // } else {
                      if(props.QuotationTypeValue !== "Other"){
                        if( header.showFornum.has(props.QuotationTypeValue) || header.showFor.has(props.QuotationTypeValue) ){
                      const disabledBool = header.disableFor &&
                        header.disableFor.has(props.QuotationTypeValue);
                      return (
                        <TableCell
                          align="center"
                          key={header.value}
                          style={{ cursor: disabledBool && 'not-allowed' }}
                        >
                          <Radio
                            checked={sessionStorage.getItem('option_type') == header.value ? true : props.RadioButtonValue === header.value}
                            onChange={props.OnChange}
                            value={header.value}
                            // disabled={sessionStorage.getItem('estimate_type_by_login') == "T2" && sessionStorage.getItem('option_type') !== header.value ? true : disabledBool}
                          />
                          <Typography
                            gutterBottom
                            component="span"
                            color={disabledBool ? 'textSecondary' : 'inherit'}
                          >
                            {header.label}
                            {/* {console.log(header.label)} */}
                          </Typography>
                        </TableCell>
                      );
                    }
                  }
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {
                  QuotationOptionsTableFormBody.map(data => {
                    
                    // if (
                    //   // props.QuotationTypeValue === "T6" || sessionStorage.getItem('estimate_type_by_login') === "T6"
                    //   // ||
                    //   // (props.QuotationTypeValue !== "T6" || props.QuotationTypeValue !== "T2") && data.id === "3"
                    //   (!data.showFornum.has(props.QuotationTypeValue) || !data.showFor.has(sessionStorage.getItem('estimate_type_by_login')) && !data.id === "3")

                    // ) {
                    //   return <div key={data.id}></div>;
                    // } else {
                      if(props.QuotationTypeValue !== "Other"){
                        if( data.showFornum.has(props.QuotationTypeValue) || data.showFor.has(props.QuotationTypeValue) ){
                      return (
                        <TableCell
                          align="center"
                          key={data.id}
                          style={{ "padding": "0" }}
                        >
                          {/* {data.label} */}
                          <div style={{ "fontSize": "0.68rem", "padding": "0" }}>
                            <MathComponent
                              settings={{
                                fonts: ["STIX"]
                              }}
                              tex={data.texLabel}
                            />
                          </div>
                        </TableCell>
                      );
                    }
                  }
                  })
                }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    </React.Fragment>
  )
}

const MeraKiQuoteTableForm = props => {
  console.log(props.QuotationTypeValue)
  const classes = useStyles();
  return (
    <>
      {props.QuotationTypeValue === 'T1' ?
        <>
          <hr style={{ color: "black", backgroundColor: "black", width: "98%", paddingLeft: "1vw" }} />
          <React.Fragment>
            <TableContainer>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    {
                      MeraKiQuoteTableFormHead.map(header => {
                        // console.log((props.goods_FTWZ.toString()[1] !=='F') && header.value ==='Y')
                        // console.log(props.goods_FTWZ)
                        // console.log(header.value)
                        return (
                          <TableCell align="center">
                            <Radio
                              checked={props.RadioButtonValue === header.value}
                              onChange={props.OnChange}
                              value={header.value}
                              disabled={props.goods_FTWZ && (props.goods_FTWZ.toString()[1] !=='F' && header.value =='Y') }

                            />
                            {header.label}
                          </TableCell>
                        )
                      }
                      )
                    }

                  </TableRow>
                </TableHead>

              </Table>
            </TableContainer>

          </React.Fragment>  </>
        : <span></span>}
    </>
  );
}





const QuotationTypeTableForm = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>

              {console.log(QuotationTypeTableFormHead)}
              {
               
                QuotationTypeTableFormHead.map(header => (
                  <TableCell align="center" key={header.id}>
                    <Radio
                      checked={sessionStorage.getItem('estimate_type_by_login') === header.label  ? true : props.RadioButtonValue === header.value}
                      value={header.value}
                      onChange={props.OnChange}
                      disabled={sessionStorage.getItem('estimate_type_by_login') !== 'Other' ||(sessionStorage.getItem('estimate_type_by_login') === 'Other' && header.label==='T4')}
                    />
                    {/* {props.RadioButtonValue}
                    {header.value} */}
                    {header.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {
                QuotationTypeTableFormBody.map(data => (

                  <TableCell align="center" key={data.id}>
                    {data.label}


                  </TableCell>



                ))

              }

            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export { BillingTableForm, QuotationOptionsTableForm, QuotationTypeTableForm, MeraKiQuoteTableForm };