import React,{Component} from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class SampleTemplateImport extends Component{
    constructor(){
        super();
        this.state={
            sampleData:[{
                'Invoice No.':'123456789',
                'Expected Date':'30/04/2020',
                'Remarks for Sales':'Remarks for sales go here',
                'Internal Remarks':'Internal remarks go here',
            }]
        }
    }
    onClickExport=(exportData,fileName)=>{
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb= {Sheets:{'data':ws},SheetNames:['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    render(){
        return (
            <button className="btn btn-primary btn-sm" onClick={()=>{this.onClickExport(this.state.sampleData,'sample_import_template')}}>
                Download Sample Import
            </button>
        );
    }
}

export default SampleTemplateImport;