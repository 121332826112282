import React, { useState, createContext } from "react";
import PropTypes from 'prop-types';

const BASE_URI = "/pro";

export const EstimateTableContext = createContext({
  viewProductContext: [false, function() {}],
  emailResponseContext: 0,
  emailButtonClickedContext: [false, function() {}],
  emailMeFunction: function() {},
  //
  viewEstCalcContext: [false, function() {}],
  estimateTypeContext: [0, function() {}],
  optionTypeContext: [0, function() {}],
  estimateDataContext: [{}, function() {}],
  // disableFormContext:[false,function(){}]
}); 

export const EstimateTableProvider = props => {
  const [viewEstCalcModal, setViewEstCalcModal] = useState(false);
  const [viewProductsActive, setViewProductsActive] = useState(false);
  const [emailResponseCode, setEmailResponseCode] = useState(0);
  const [emailButtonClicked, setEmailButtonClicked] = useState(false);
  const [estimateType, setEstimateType] = useState(0);
  const [optionType, setOptionType] = useState(0);
  const [estimateData, setEstimateData] = useState({
    uid: '',
    customerName: '',
    version: '',
    estimateId: '',
    isExported: false,
    refreshRequired: false,
  });
  estimateData.propTypes = {
    uid: PropTypes.string,
    customerName: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    estimateId: PropTypes.string.isRequired
  };
  // const [disableForm, setDisableForm] = useState(false);
  const emailMe = async quoteId => {
    const response = await fetch(`${BASE_URI}/emailQuote/${quoteId}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`
      }
    });
    if (response.status === 200) {
      setEmailResponseCode(200);
      setEmailButtonClicked(false);
    } else if (response.status === 404 || response.status === 500) {
      setEmailResponseCode(404);
      setEmailButtonClicked(false);
    } else {
      setEmailResponseCode(400);
      setEmailButtonClicked(false);
    }
  };
  console.log(estimateType)
  return (
    <EstimateTableContext.Provider
      value={{
        viewProductContext: [viewProductsActive, setViewProductsActive],
        emailResponseContext: emailResponseCode,
        emailButtonClickedContext: [emailButtonClicked, setEmailButtonClicked],
        emailMeFunction: emailMe,
        viewEstCalcContext: [viewEstCalcModal, setViewEstCalcModal],
        estimateTypeContext: [estimateType, setEstimateType],
        optionTypeContext: [optionType, setOptionType],
        estimateDataContext: [estimateData, setEstimateData],
        // disableFormContext:[disableForm,setDisableForm]
      }}
    >
      {props.children}
    </EstimateTableContext.Provider>
  );
};
