import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/**
 * This component handles the rendering of Approval Table Subcomponent Buttons.
 */
const TableButton = ({
  onClickHandler, 
  buttonText,
  CustomClasses = null,
}) => (
  <StyledButton 
    className={
      CustomClasses === null 
      ? "btn btn-primary" 
      : "btn btn-primary " + CustomClasses
    }
    onClick={onClickHandler}
  >
  {buttonText}
  </StyledButton>
)

const StyledButton = styled.button`
  font-size: 0.875em;
  @media only screen and (max-width: 768px) {
    font-size: 60%;
  }
`;

TableButton.propTypes = {
  /**
   * The function that gets executed when button is clicked.
   */
   onClickHandler: PropTypes.func.isRequired,
  /**
   * Button text.
   */
   buttonText: PropTypes.string.isRequired,
   /**
    * User defined css classes in addition to the default.
    */
   CustomClasses: PropTypes.string,
}

export default TableButton;

