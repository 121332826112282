
import React, { useContext, useState, useEffect } from 'react';
import { Grid, Button, Tooltip, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import { EstimateTableContext } from 'quotationSystem/EstimateTableContext';
import CCWDownloadPageModal from 'quotationSystem/Modals/CCWDownloadPageModal';


const useStyles = makeStyles(theme => ({
  subComponent: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  subComponentButtons: {
    backgroundColor: "#9ba3cf",
    "&:hover": {
      backgroundColor: "#5c66a1"
    },
    "&:active": {
      outline: "none"
    },
    "&:focus": {
      outline: "none"
    }
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const SubComponent = props => {
  const classes = useStyles();
  const {
    viewProductContext,
    emailButtonClickedContext,
    estimateTypeContext,
    optionTypeContext,
    emailResponseContext,
    // NOTE: Not implemented in current version.
    // emailMeFunction,
    estimateDataContext
  } = useContext(EstimateTableContext);
  const [viewProductsActive, setViewProductsActive] = viewProductContext;
  const [emailButtonClicked] = emailButtonClickedContext;
  const [, setEstimateType] = estimateTypeContext;
  const [, setOptionType] = optionTypeContext;
  const emailResponseCode = emailResponseContext;
  const [estimateData, setEstimateData] = estimateDataContext;
  const [uid, setUID] = useState(0);
  // State variables.
  const [openTooltip, setOpenTooltip] = useState(false);
  console.log(props)
  // useEffect()
  useEffect(() => {
    setUID(props.RowData.id);
  }, []);

  // Button click handlers
  const onClickViewProducts = () => {
    setEstimateType(props.RowData.estimate_type);
    setOptionType(props.RowData.option_type);
    setEstimateData({
      ...estimateData,
      uid: props.RowData.id,
      customerName: props.RowData.customer_name,
      version: props.RowData.version_number,
      estimateId: props.RowData.estimate_id,
      isExported: props.RowData.is_estimate_exported
    });
    setViewProductsActive(true);
  }

  const onClickCreateVersion = async () => {
    console.log(props.RowData.id)

    const responseData = await props.onCreateVersion(props.RowData.id);
    console.log(responseData)
    if (responseData !== undefined) { 
      const newId = responseData['Id'];
      if (newId !== undefined) {
        setEstimateType(props.RowData.estimate_type);
        setOptionType(props.RowData.option_type);
        setEstimateData({
          uid: newId,
          customerName: props.RowData.customer_name,
          version: props.RowData.version_number,
          estimateId: props.RowData.estimate_id,
          isExported: false,
          refreshRequired: true,
        });
        setViewProductsActive(true);
        setUID(newId);
      }
    }
  }

  // Managing tooltip.
  // const onOpenTooltip = () => {
  //   if (props.RowData.is_estimate_exported) {
  //     setOpenTooltip(false);
  //   } else {
  //     setOpenTooltip(true);
  //   }
  // }
  const onCloseTooltip = () => {
    setOpenTooltip(false);
  }

  return (
    <div className={classes.subComponent}>
      {emailResponseCode === 400 ? (
        <CustomizedSnackbars
          Variant="error"
          Message="Server Error. Please try again after refreshing."
          Open={true}
        />
      ) : null}
      {emailResponseCode === 404 ? (
        <CustomizedSnackbars
          Variant="error"
          Message="Server Error. Please contact administrator."
          Open={true}
        />
      ) : null}
      {emailResponseCode === 200 ? (
        <CustomizedSnackbars
          Variant="info"
          Message="Email sent successfully."
          Open={true}
        />
      ) : null}
      <BackdropLoader Open={emailButtonClicked} />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            className={classes.subComponentButtons}
            onClick={onClickViewProducts}
          >
            CCW Download Page
          </Button>
        </Grid>
        <Grid item>
          {/* <HtmlTooltip
              open={openTooltip}
              onOpen={onOpenTooltip}
              onClose={onCloseTooltip}
              placement="right"
              title={<CustomTooltipComponent/>}
            >
              <span> */}
          <Button
            variant="contained"
            size="small"
            className={classes.subComponentButtons}
            onClick={onClickCreateVersion}
          // disabled={props.RowData.is_estimate_exported === true ? false : true}
          >
            Create New Version
          </Button>
          {/* </span>
            </HtmlTooltip> */}
        </Grid>
      </Grid>
      {viewProductsActive ? (
        <CCWDownloadPageModal
          Open={viewProductsActive}
          Id={uid}
          EstimateId={props.RowData.estimate_id}
          disableEdit={estimateData.isExported}
          refreshData={props.refreshData}
        />
      ) : null}
    </div>
  );
}

const CustomTooltipComponent = () => (
  <React.Fragment>
    <Typography variant="body2" color="inherit">Note</Typography>
    {"The estimate has "}<b>{'not'}</b>{' been exported yet'}.{' '}
    {"Only allowed to create new version after estimate is exported."}
  </React.Fragment>
)

export default SubComponent;