import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import StatusButton from 'components/buttons/StatusButton';
import Modal from 'components/table/Modal';
import ViewMoreModal from 'components/table/ViewMoreModal';
import 'react-table-6/react-table.css';
import 'css/tabledata.css';
import ButtonComponent from 'components/table/ButtonComponent';
import Comments from 'components/table/Comments';

import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';
/**
 * This component displays data provided in a tabular format.
 */
// eslint-disable-next-line
Number.prototype.toLocaleFixed = function (n) {
    return this.toLocaleString("en-IN", {
        minimumFractionDigits: n,
        maximumFractionDigits: n
    });
}
const BASE_URI = '/pro';
const TESTING = false;

class TableData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waitLoading: false,
            // delegateUsersDetails:[],
            searchby: false,
            searchbyFilter: '',
            searchbyInput: '',
            statusFilter: false,
            statusFilterBy: '',
            filtered: [],
            data: [],
            typeModal: 0,
            modalData: {},
            commentData: null,
            statusButtonClick: false,
            showModal: false,
            viewMoreModal: false,
            viewMoreData: [],
            viewMoreTotalTheoreticalMargin: { total: 0, percentage: 0 },
            loadingViewMoreData: false,
            responseViewMoreData: 0,
            loadingComments: false,
            loadingCommentsResponseStatus: 0,
            headerStyle: {
                background: '#2f2655',
                color: 'whitesmoke',
            },
            hideExtraComments: true,
            columnsHeaders: [
                {
                    Header: "Document No",
                    accessor: "Document No_",
                    Cell: props => <div className="test"><span>{props.value}</span></div>,
                    width: 160,
                },
                {
                    Header: <span>Sender</span>,
                    accessor: "Sender Name",
                    width: 120
                },
                // ===============
                {
                    Header: <span>Creation Date</span>,
                    accessor: "Date-Time Sent for Approval",
                    Cell: props => <div>{Intl.DateTimeFormat('en-IN', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(props.value))}</div>,
                    width: 170
                },
                // ==============
                {
                    Header: <span>Purchase Amount</span>,
                    accessor: "Amount",
                    Cell: props => <span className="number">{(Math.round(props.value * 100) / 100).toLocaleFixed(2)}</span>,
                    headerClassName: "po-table-header",
                    className: "number"
                },
                {
                    Header: "Purchase Amount (LCY)",
                    accessor: "Amount (LCY)",
                    Cell: props => <span className="number">{(Math.round(props.value * 100) / 100).toLocaleFixed(2)}</span>,
                    width: 150,
                    headerClassName: "po-table-header",
                    className: "number",
                },
                {
                    Header: <span>Sale Amount</span>,
                    accessor: "Purchase Amount",
                    headerClassName: "po-table-header",
                },
                {
                    Header: <span>Theoretical Margin</span>,
                    accessor: "Theoretical Margin",
                    headerClassName: "po-table-header",
                },
                {
                    Header: "Status",
                    accessor: "Status",
                    id: "Status",
                    width: 130,
                    Cell: props => <StatusButton Type="Table" StatusValue={props.value} />,
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "pending") {
                            return row.Status === 0 || row.Status === 1;
                        }
                        if (filter.value === "cancelled") {
                            return row.Status === 2;
                        }
                        if (filter.value === "rejected") {
                            return row.Status === 3;
                        }
                        if (filter.value === "approved") {
                            return row.Status === 4;
                        }
                        if (filter.value === "sentforfinalapproval") {
                            return row.Status === 5;
                        }
                    },
                },
                {
                    Header: "Document Line No_",
                    accessor: "Document Line No_",
                    show: false,
                }
            ]
        };
    }
    componentDidMount() {
        if (this.props.typeModal === 1) {
            this.setState({
                data: this.props.Data,
                tableData: this.props.Data,
                typeModal: this.props.typeModal,
                filtered: [{ id: 'Document Line No_', value: 0 }],
                // delegateUsersDetails:tis.props.DelegateUsersDetails
            });
        } else {
            this.setState({
                data: this.props.Data,
                tableData: this.props.Data,
                typeModal: this.props.typeModal,
            });
        }
        // this.getDelegateUsersDetails();
    }
    componentDidUpdate(prevProps) {
        if (this.props.Data !== prevProps.Data) {
            this.setState({ dataPresent: true, data: this.props.Data });
        }
    }
    rowClicked = (data) => {
        this.setState({
            modalData: data,
            showModal: true,
        });
    }
    updateStatus = async (data, newStatusValue, otherChargesValue, callback, userComment) => {
        this.setState({ waitLoading: true });
        var docNo;
        if (!TESTING) {
            docNo = data["Document No_"];
        } else {
            docNo = undefined;
        }
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${BASE_URI}/updateStatusApi/${docNo}`, {
            method: "PUT",
            headers: {
                Authorization: `Token ${token}`
            },
            body: JSON.stringify({
                status: newStatusValue,
                comment: userComment,
                //   otherCharges:otherChargesValue
            })
        });
        if (response.status === 200) {
            // Wait for Snackbar to appear and stay.
            this.setState({ waitLoading: false });
            setTimeout(() => { this.props.refreshData(); }, 1500);
        }
        callback(response.status);
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered.slice();
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    }
    onCloseModal = () => {
        this.setState({
            showModal: false,
        });
    }
    onFilterSearchBy = (event) => {
        let value = event.target.value;
        let searchbyFilter = null;
        if (value === "documentNo") {
            searchbyFilter = "Document No_";
        } else if (value === "approver") {
            searchbyFilter = "Approver Employee ID";
        }
        if (searchbyFilter !== null) {
            this.setState({
                searchbyFilter,
                searchby: true
            });
        } else {
            // Reset Search by Filter
            let filtered = [];
            if (this.state.statusFilter) {
                filtered.push({ id: "Status", value: this.state.statusFilterBy });
            }
            if (this.state.typeModal === 1) {
                // For PO Modal
                filtered.push({ id: 'Document Line No_', value: 0 });
            }
            this.setState({
                searchbyFilter: '',
                searchby: false,
                filtered: filtered,
                searchbyInput: ''
            });
        }
    }
    onInputSearchBy = (event) => {
        this.setState({ searchbyInput: event.target.value });
        this.onFilteredChangeCustom(event.target.value, this.state.searchbyFilter);
    }
    onFilterStatus = (value) => {
        if (sessionStorage.getItem('usertype') === '1') {
            // IF USER IS ADMIN
            if (value === "pending") {
                // CHANGE FILTER OF PENDING TO SENTFORFINALAPPROVAL
                // (BECAUSE FOR THAT ADMIN USER PENDING IS EQUAL TO SENTFORFINALAPPROVAL)
                value = "sentforfinalapproval";
            }
        }
        this.setState({ statusFilterBy: value, statusFilter: true });
        this.onFilteredChangeCustom(value, "Status");
    }
    trPropsForPo = (state, rowInfo, col, instance) => {
        return {
            onClick: e => {
                const { expanded } = state;
                const path = rowInfo.nestingPath[0];
                const diff = { [path]: expanded[path] ? false : true };
                this.setState({
                    commentData: null,
                    loadingComments: true,
                });
                instance.setState({
                    expanded: {
                        expanded,
                        ...diff
                    }
                });
            },
            style: {
                cursor: 'pointer',
            }
        };
    }
    trPropsForOther = (state, rowInfo, col, instance) => {
        if (rowInfo !== undefined) {
            if (rowInfo && rowInfo.row) {
                return {
                    onClick: (e) => {
                        this.rowClicked(rowInfo.row._original);
                    },
                }
            }
        } else {
            return {};
        }
    }
    getCommentForPo = async (poNumber) => {
        const response = await fetch(`${BASE_URI}/getCommentApi/${poNumber}`, {
            method: "GET",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            }
        });
        if (response.status === 204 || response.status === 500 || response.status === 404) {
            this.setState({ commentData: [], loadingComments: false, loadingCommentsResponseStatus: response.status });
        } else {
            const responseJSON = await response.json();
            this.setState({ commentData: responseJSON, loadingComments: false, loadingCommentsResponseStatus: response.status });
        }
    }
    extraComments = (prevValue) => {
        this.setState({ hideExtraComments: !prevValue });
    }
    onClickViewMore = async (docNo) => {
        this.setState({ loadingViewMoreData: true });
        const response = await fetch(`${BASE_URI}/getPoInsideApi/${docNo}`, {
            method: "GET",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            },
        });
        if (response.status === 204 || response.status === 500 || response.status === 404) {
            this.setState({ viewMoreModal: true, loadingViewMoreData: false, responseViewMoreData: response.status });
        } else {
            const responseJSON = await response.json();
            let total = 0;
            let percentage = 0;
            for (let i = 0; i < responseJSON.length; i++) {
                if (responseJSON[i]['Theoritical Margin'] === "0E-20") {
                    total = total + 0;
                    percentage = percentage + 0;
                } else {
                    total = total + Number(responseJSON[i]['Theoritical Margin']);
                    percentage = percentage + Number(responseJSON[i]['Theoritical Margin _'])
                }
            }
            percentage = Number(percentage / (responseJSON.length - 1));
            total = Number(total);
            this.setState({
                viewMoreModal: true,
                viewMoreData: responseJSON,
                loadingViewMoreData: false,
                responseViewMoreData: response.status,
                viewMoreTotalTheoreticalMargin: {
                    total: total,
                    percentage: percentage
                }
            });
        }
    }
    subComponentPo = row => {
        let rowData = row.original;
        // let hideExtraComments=this.state.hideExtraComments;
        let attach = rowData.URL1;
        attach = attach.split(',');
        var commentData = this.state.commentData;
        if (this.state.commentData === null) {
            this.getCommentForPo(rowData["Document No_"]);
        }
        let count = 0;
        let rowStatus = row.original.Status;
        let commentCount = 0;
        let viewButtonClass = "";
        if (rowStatus === 1 || rowStatus === 0) {
            viewButtonClass = "col-1";
        } else {
            viewButtonClass = "col-1 offset-3";
        }
        return (
            <div className="row" style={{ backgroundColor: "whitesmoke", padding: "1em" }}>
                {this.state.loadingCommentsResponseStatus === 500 ? <CustomizedSnackbars Variant="error" Message="Server Error. Unable to get PO comments data. Please try refresh." Open={true} /> : null}
                {this.state.loadingCommentsResponseStatus === 404 ? <CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true} /> : null}
                {/*===================================== Comments Data Here ================================*/}
                <div className="col-3">
                    {
                        this.state.loadingComments ?
                            <CircularIndeterminate Type="PoComments" />
                            :
                            <Comments
                                LoadingComments={this.state.loadingComments}
                                Data={commentData}
                                CommentCount={commentCount}
                                HideExtraComments={this.state.hideExtraComments}
                                ExtraCommentsHandler={(v) => { this.extraComments(v) }}
                            />
                    }
                </div>
                {/* ====================================================================================  */}
                <div className="col-4">
                    {
                        attach.map(
                            link => {
                                count = count + 1;
                                return (
                                    <div key={count} style={{ display: "inline-block" }}>
                                        {
                                            link.trim() === "None" ?
                                                null
                                                :
                                                <a
                                                    style={{ marginLeft: "10px", fontSize: "0.9em" }}
                                                    href={link.trim()}
                                                    className="badge badge-pill badge-primary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Link {count}
                                                </a>
                                        }
                                    </div>
                                );
                            }
                        )
                    }
                </div>
                <div className={viewButtonClass}>
                    <button
                        type="button"
                        className="btn btn-secondary mr-2 subcomponent-button"
                        onClick={() => {
                            this.onClickViewMore(row.original['Document No_']);
                        }}
                    >
                        View More
                    </button>
                </div>
                <ButtonComponent
                    RowStatus={rowStatus}
                    HandleApproveClick={(callback, userComment) => {
                        this.updateStatus(rowData, sessionStorage.getItem('approveValue'), 0, callback, userComment);
                    }}
                    HandleRejectClick={(callback, userComment) => {
                        this.updateStatus(rowData, sessionStorage.getItem('rejectValue'), 0, callback, userComment);
                    }}
                />
                {console.log(row)}
                <ViewMoreModal
                    ResponseStatus={this.state.responseViewMoreData}
                    RowStatus={rowStatus}
                    BaseLine={row.original}
                    Open={this.state.viewMoreModal}
                    ShowLoader={this.state.waitLoading}
                    DocLineNumber={row.original['Document Line No_']}
                    DocNumber={row.original["Document No_"]}
                    TotalTheoreticalMargin={this.state.viewMoreTotalTheoreticalMargin}
                    Data={this.state.viewMoreData}
                    CloseModal={() => {
                        this.setState({ viewMoreModal: false, viewMoreData: [] });
                    }}
                    ClickHandlerApprove={(callback, userComment) => {
                        this.updateStatus(rowData, sessionStorage.getItem('approveValue'), 0, callback, userComment);
                    }}
                    ClickHandlerReject={(callback, userComment) => {
                        this.updateStatus(rowData, sessionStorage.getItem('rejectValue'), 0, callback, userComment);
                    }}
                    LoadingData={this.state.loadingViewMoreData}
                />
            </div>
        );
    }
    // =============================================== RENDER RETURN ==========================================
    render() {
        const modalData = this.state.modalData;
        const showModal = this.state.showModal;
        return (
            <div>
                <div className="secndlayer">
                    {/* Snackbar for info related to failed request etc. */}
                    {this.props.ResponseStatus === 500 ? <CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true} /> : null}
                    {this.props.ResponseStatus === 404 ? <CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true} /> : null}
                    {this.props.ResponseStatus === 204 ? <CustomizedSnackbars Variant="info" Message="No data. There are no PO's for your account." Open={true} /> : null}
                    {/* Backdrop Loader waiting for data  */}
                    <BackdropLoader Open={this.props.LoadingData} />
                    <BackdropLoader Open={this.state.waitLoading} />
                    <div className={showModal ? "table-filter blur-me mt-1" : "table-filter mt-1"}>
                        <div className="row box-margin" >
                            <div className="col-md-6 col-6 filter-search-by-dropdown"
                            >

                                <span className={ this.state.statusFilterBy=='all' || this.state.statusFilterBy==''?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('all')}>All</span>
                                <span className={ this.state.statusFilterBy==='pending'?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('pending')}>Pending</span>
                                <span className={ this.state.statusFilterBy==='cancelled'?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('cancelled')}>Cancelled</span>
                                <span className={ this.state.statusFilterBy==='rejected'?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('rejected')}>Rejected</span>
                                <span className={ this.state.statusFilterBy==='approved'?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('approved')}>Approved</span>
                                {sessionStorage.getItem('usertype') === '2' ? <span className={ this.state.statusFilterBy==='sentforfinalapproval'?"plainselect selected-flter":"plainselect"} onClick={event => this.onFilterStatus('sentforfinalapproval')}>Sent For Final Approval</span> : null}


                                {/* <label className="select-label">Status:</label>
                             <select className="select-status-sort ml-2 select-button"
                                onChange={event => this.onFilterStatus(event.target.value)}
                            >
                                <option value="all">Show All</option>
                                <option value="pending">Pending</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="rejected">Rejected</option>
                                <option value="approved">Approved</option>
                                {sessionStorage.getItem('usertype') === '2' ? <option value="sentforfinalapproval">Sent For Final Approval</option> : null}
                            </select> */}
                            </div>
                            <div className="col-md-6 col-6  filter-status-dropdown"
                            >
                                <select className="search-by select-button ml-3" onChange={this.onFilterSearchBy}>
                                    <option value="default">Search by:</option>
                                    <option value="documentNo">Document No.</option>
                                    <option value="approver">Approver ID</option>
                                </select>
                                <CSSTransition
                                    in={this.state.searchby}
                                    timeout={500}
                                    classNames="animate-filter-search-by-input"
                                    unmountOnExit
                                >
                                    <input
                                        className="ml-2"
                                        onChange={this.onInputSearchBy}
                                        value={this.state.searchbyInput}
                                        placeholder=" Search here..." />
                                </CSSTransition>
                            </div>

                        </div>
                    </div>
                    <div className="table-data">
                        <div className={showModal ? "blur-me" : null}>
                            <ReactTable
                                className="-highlight table-style"
                                previousText="<"
                                nextText=">" 
                                SubComponent={this.state.typeModal === 1 ? this.subComponentPo : null}
                                data={this.state.data}
                                filtered={this.state.filtered}
                                onFilteredChange={(filtered, column, value) => {
                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                }}
                                defaultFilterMethod={(filter, row, column) => {
                                    const id = filter.pivotId || filter.id;
                                    if (typeof filter.value === "object") {
                                        return row[id] !== undefined
                                            ? filter.value.indexOf(row[id]) > -1
                                            : true;
                                    } else {
                                        return row[id] !== undefined
                                            ? String(row[id]).indexOf(filter.value) > -1
                                            : true;
                                    }
                                }}
                                showPageJump={false}
                                columns={this.state.columnsHeaders}
                                defaultPageSize={9}
                                resizable={true}

                                // showPagination= {true}
                                // showPaginationBottom={true}
                                // showPageSizeOptions= {true}
                                // minRows={0}
                                getTheadProps={() => {
                                    return {
                                        style: this.state.headerStyle,
                                    }
                                }}
                                getTbodyProps={() => {
                                    return {
                                        style: {
                                            overflowX: "hidden",
                                        }
                                    }
                                }}
                                getTrProps={this.state.typeModal === 1 ? this.trPropsForPo : this.trPropsForOther}
                                defaultSorted={[
                                    {
                                        id: "Status",
                                        asc: true,
                                    }
                                ]}
                            />
                        </div>
                        {showModal
                            ?
                            <div>
                                {
                                    this.state.typeModal === 1
                                        ?
                                        null
                                        :
                                        <Modal data={modalData} closeModalHandler={this.onCloseModal} updateStatus={this.updateStatus} />
                                }
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
TableData.propTypes = {
    /**
        * Data received and to be displayed in the table. Passed from other components.
        */
    Data: PropTypes.array,
    /**
     * Function to refresh data in table. Passed on from other components.
     */
    refreshData: PropTypes.func,
    /**
     * Handles row click in table. Display modal.
     * @param {array} data Data of row clicked.
     */
    rowClicked: PropTypes.func,
    /**
     * Makes api call for status change of row. Approve or Reject.
     * @param {array} data Data of row clicked.<br>
     * @param {Integer} newStatusValue New Value of status.<br>
     * @param {Integer} otherChargesValue Other charges entered by user.<br>
     * @param {function} callback function to show changes in the modal.<br>
     */
    updateStatus: PropTypes.func,
    /**
     * Handles filtering of data.
     * @param {string} value filter based on this value.<br>
     * @param {string} accessor column for which filtering needs to happen.<br>
     */
    onFilteredChangeCustom: PropTypes.func,
    /**
     * handles close of modal.
     */
    onCloseModal: PropTypes.func,
    /**
     * handles search by filter
     * @param {object} event generated event on change in selection of dropdown.
     */
    onFilterSearchBy: PropTypes.func,
    /**
     * handles search by input filter
     * @param {object} event generated event on change of input by user.
     */
    onInputSearchBy: PropTypes.func,
    /**
     * handles status filter
     * @param {string} value selected value of dropdown
     */
    onFilterStatus: PropTypes.func,
    /**
     * handles type of modal to show
     * @param {Integer} typeModal which type of modal to show 1: po modal,2:sbso and so modal
     */
}
export default TableData;