import React, { useState, useContext } from "react";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CustomizedSnackbars from "components/notification/CustomizedSnackbars";
import { EstimateTableContext } from 'quotationSystem/EstimateTableContext';

const useStyles = makeStyles(theme => ({
  appBarButton: {
    backgroundColor: "#9ba3cf",
    "&:hover": {
      backgroundColor: "#5c66a1"
    }
  }
}));

const ExportErrorMsg = "There was some problem exporting. Try again.";
const ExportSuccessMsg = "File exported. Check downloads.";

export const ExportCSV = ({ csvData, fileName, exportHeadersList, editableColumns, setFileInfo }) => {
  const { estimateDataContext } = useContext(EstimateTableContext);
  const [estimateData, setEstimateData] = estimateDataContext;
  const [exportState, setExportState] = useState('');
  const classes = useStyles();

  const fileExtension = ".xlsx";
  
  const exportToCSV = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Data");
      // Adding metadata.
      // estimateData : { uid, version, estimateId, customerName }
      const metaData=[
        // NOTE: Metadata is added in order as mentioned here.
        // i.e. First element -> row 1 , second element -> row 2 and so on.
        {
          title:'ID',
          titleCell:'A1',
          value:estimateData.uid,
          valueCellStart:'B1',
          valueCellEnd:'F1',
        },
        {
          title:'Estimate ID',
          titleCell:'A2',
          value:estimateData.estimateId,
          valueCellStart:'B2',
          valueCellEnd:'F2',
        },
        {
          title:'Customer Name',
          titleCell:'A3',
          value:estimateData.customerName,
          valueCellStart:'B3',
          valueCellEnd:'F3',
        },
        {
          title:'Version',
          titleCell:'A4',
          value:estimateData.version,
          valueCellStart:'B4',
          valueCellEnd:'F4',
        }
      ];
      // NOTE: Make sure this is after the last cell of metadata.
      // i.e. If last element titleCell is A4 then headerStartCell should be A5.
      const headersStartCell='A5';
      var columnHeadersRowIndex=0;
      metaData.forEach((meta, index)=>{
        worksheet.getRow(index+1).values=[meta.title,meta.value];
        worksheet.getCell(meta.titleCell).font = {
          bold: true
        };
        worksheet.mergeCells(meta.valueCellStart, meta.valueCellEnd);
        columnHeadersRowIndex=index+2;
      });
      
      // Adding data to worksheet.
      var exportColumns = [];
      var headersList = [];
      exportHeadersList.forEach(exportHeader=>{
        var pushValue = { key: exportHeader };
        exportColumns.push(pushValue);
        headersList.push(exportHeader);
      });
      // Assigning worksheet columns. 
      // NOTE : for some weird reason if you try pushing directly to worksheet.columns
      // there's an error thrown.
      worksheet.columns = exportColumns;
      worksheet.getRow(columnHeadersRowIndex).values = headersList;
      
      // Add styling to editable column headers.
      var columnHeadersRow = worksheet.getRow(columnHeadersRowIndex);
      editableColumns.forEach(column=>{
        columnHeadersRow.getCell(column).style={
          font: { 
            bold: true
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb:'90EE90'}
          }
        };
      });
      csvData.forEach((dataRow) => {
        worksheet.addRow(dataRow);
        // Allow editable column cells to be editable.
        // editableColumns.forEach(column=>{
        //   worksheet.getRow(index+1).getCell(column).protection={
        //     locked:false,
        //     hidden:false,
        //   };
        // });
      });
      const buffer = await workbook.xlsx.writeBuffer();
      const fileData = new Blob([buffer]);
      FileSaver.saveAs(fileData, fileName + fileExtension);
      setExportState('SUCCESS');
      // NOTE: These are important to set because it's used when importing the file.
      setFileInfo({
        headersStartCell: headersStartCell,
        metaData: metaData
      });
    }
    catch(err) {
      setExportState('ERROR');
      console.log(err);
    }
  };
  const resetExportState = ()=>{
    setExportState('');
  };
  return (
    <>
      {
        exportState==='ERROR'
        ?
        <CustomizedSnackbars 
          Variant="error" 
          Message={ExportErrorMsg} 
          Open={true}
          CloseCallback={resetExportState}
        />
        :null
      }
      {
        exportState==='SUCCESS'
        ?
        <CustomizedSnackbars 
          Variant="success" 
          Message={ExportSuccessMsg} 
          Open={true} 
          EnableTimer 
          Timer={5000} 
          CloseCallback={resetExportState}
        />
        :null
      }
      <Button
        variant="contained"
        size="small"
        className={classes.appBarButton}
        onClick={exportToCSV}
        style={{ marginRight: 10 }}
      >
        Export
      </Button>
    </>
  );
};
