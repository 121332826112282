import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class VendorDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            closeCallback:this.props.CloseCallback,
            data:this.props.Data,
            response:this.props.Response,
            noDataMessage:"There was no information for vendor available.",
        }
    }
    closeModal=()=>{
        this.state.closeCallback();
    }
    render(){
        let data=this.state.data[0];
        return (
                <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={this.closeModal}
                aria-labelledby="vendor-details-modal"
                open={this.props.Open}
                >
                    {
                        this.state.response===404?
                        <div>
                        <DialogTitle id="vendor-details-modal" onClose={this.closeModal}>
                        NO DATA
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography variant="subtitle1">
                                {this.state.noDataMessage}
                            </Typography>
                        </DialogContent>
                        </div>
                        :
                        <div>
                        <DialogTitle id="vendor-details-modal" onClose={this.closeModal}>
                        <div style={{fontWeight:600}}>{data.Name}</div>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {/* Left Side */}
                                    <Typography variant="subtitle1">
                                        <span style={{fontWeight:600}}>
                                            Address
                                            <div style={{fontWeight:400}}>{data.Address===""?'-':data.Address}</div>
                                        </span>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>Contact</div>
                                        {data.Contact===""?'-':data.Contact}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>GST Registration No.</div>
                                        {data["GST Registration No_"]===""?'-':data["GST Registration No_"]}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>Primary Contact No.</div>
                                        {data["Primary Contact No_"]===""?'-':data["Primary Contact No_"]}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    {/* Right Side */}
                                    <Typography variant="subtitle1">
                                        <span style={{fontWeight:600}}>
                                            City
                                            <div style={{fontWeight:400}}>{data.City===""?'-':data.City}</div>
                                        </span>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>Post Code</div>
                                        {data["Post Code"]===""?'-':data["Post Code"]}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>Phone No</div>
                                        {data["Phone No_"]===""?'-':data["Phone No_"]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        <div style={{fontWeight:600}}>E-Mail</div>
                                        {data["E-Mail"]===""?'-':data["E-Mail"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        {/* <Typography variant="subtitle1">

                        */}
                        </DialogContent>
                        </div>
                    }
                </Dialog>
        );
    }
}
export default VendorDetails;

