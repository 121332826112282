import React,{Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactTable from 'react-table-6';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';
import 'react-table-6/react-table.css';
import 'css/tabledata.css';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ExportToExcel from './ExportToExcel';
import ImportExcel from './ImportExcel';
import SampleTemplateImport from './SampleTemplateImport';
// Endpoints
import { CASHFLOW_ENDPOINTS } from 'endpoints';

Number.prototype.toLocaleFixed = function (n) {
  return this.toLocaleString("en-IN", {
    minimumFractionDigits: n,
    maximumFractionDigits: n
  });
}

const BASE_URI="/pro";

const EditModal = (props) => {
  const [userInput,setUserInput] = React.useState('');
  const [selectedDate,setSelectedDate] = React.useState('');
  const [statusInput,setStatusInput] = React.useState('');
  const [invalidDate,setInvalidDate] = React.useState(false);

  const onChangeStatusInput = (e) => {
    setStatusInput(e.target.value);
  }
  const onChangeInputText = (e) => {
    setUserInput(e.target.value);
  }
  const onChangeExpectedDate = (e) => {
    setSelectedDate(e);
    if(e===""){
      setInvalidDate(true);
    }else{
      setInvalidDate(false);
    }
  }
  const onClickConfirm = () => {
    // console.log(selectedDate,userInput);
    var failed=false;
    if(selectedDate===''){
      setInvalidDate(true);
      failed=true;
    }
    if(!failed){
      props.CloseWithEdit(userInput,selectedDate,statusInput);
    }
  }
  return (
    <div>
    <Dialog
      open={props.Open}
      onClose={props.Close}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth='xs'
      disableEnforceFocus
    >
      <DialogTitle id="form-dialog-title">Edit Details</DialogTitle>
      <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="comment"
        label="Enter remarks for sales here."
        type="text"
        fullWidth
        onChange={onChangeInputText}
        inputProps={{
          // Due to database limit of storing characters.
          maxLength:"249"
        }}
      />
      <TextField
        margin="dense"
        id="status"
        label="Enter internal remarks here."
        type="text"
        fullWidth
        onChange={onChangeStatusInput}
        inputProps={{
          // Due to database limit of storing characters.
          maxLength:"249"
        }}
      />
      </DialogContent>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            disablePast
            error={invalidDate}
            label="Select expected date."
            value={selectedDate}
            onChange={(date) => onChangeExpectedDate(date)}
            invalidDateMessage=""
            format="MMMM dd, yyyy"
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
      <Button onClick={props.Close} color="primary">
        Cancel
      </Button>
      <Button onClick={onClickConfirm} color="primary">
        Confirm
      </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};
class SubComponent extends Component{
  constructor(props){
    super(props)
    this.state={
      loadingData:false,
      comment:'',
      expectedDate:'',
      loadingFailed:false,
      openEditModal:false,
      editInProgress:false,
      editFailed:false,
      editSuccess:false,
      status:'',
      modifiedDate:''
    }
  }
  getData=async()=>{
    const response=await fetch(`${BASE_URI}/getCashFlowCommentApi/`,{
      method:"POST",
      headers:{
        Authorization:`Token ${sessionStorage.getItem('token')}`
      },
      body:JSON.stringify({
        customer_id:this.props.RowData["Document No"]
      })
    });
    if(response.status===200){
      const responseJSON=await response.json();
      // console.log(responseJSON[0]);
      if(responseJSON.length<1){
        this.setState({
          loadingData:false,
          comment:'Not found',
          expectedDate:'Not found',
          status:'Not found',
          modifiedDate:'Not found'
        });
      }else{
        let modified_at="";
        if(responseJSON[0]['modified_at']==="Not Found"){
          modified_at="Not found";
        }else{
          modified_at=(new Date(responseJSON[0]["modified_at"]).toDateString());
        }
        this.setState({
          loadingData:false,
          comment:responseJSON[0]["comment"],
          expectedDate:(new Date(responseJSON[0]["expected_date"]).toDateString()),
          status:responseJSON[0]['status'],
          modifiedDate:modified_at
        })
      }
    }else{
      this.setState({loadingFailed:true,loadingData:false});
    }
  }
  postData=async(newComment,newExpectedDate,newStatus)=>{
    // console.log(newComment,newExpectedDate);
    let date=new Date(newExpectedDate);
    date=`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const response=await fetch(`${BASE_URI}/cashFlowCommentApi/`,{
      method:"POST",
      headers:{
        Authorization:`Token ${sessionStorage.getItem('token')}`
      },
      body:JSON.stringify({
        customer_id:this.props.RowData["Document No"],
        comment:newComment,
        expected_date:date,
        status:newStatus
      })
    });
    if(response.status===200){
      this.setState({editInProgress:false,comment:newComment,expectedDate:newExpectedDate.toDateString(),editSuccess:true,status:newStatus,openEditModal:false});
    }else{
      this.setState({editFailed:true,editInProgress:false,openEditModal:false});
    }
  }
  componentDidMount() {
    this.setState({loadingData:true});
    this.getData();
  }
  onClickEdit = () => {
    this.setState({ openEditModal: true });
  }
  closeEditModalWithoutEdit = () => {
    this.setState({ openEditModal: false});
  }
  closeEditModalWithEdit = (newComment, newExpectedDate, newStatus) => {
    this.postData(newComment, newExpectedDate, newStatus);
  }
  render() {
    return (
      <div className="row" style={{backgroundColor:"whitesmoke",padding:"1em"}}>
        {this.state.editFailed?<CustomizedSnackbars Variant="error" Message="Failed. Server Error. Please try again or contact Administrator." Open={true}/>:null}
        {this.state.editSuccess?<CustomizedSnackbars Variant="success" Message="Edit successful." Open={true} EnableTimer={true} Timer={1500}/>:null}
        <div className="col-3">
          {
            this.state.loadingData||this.state.editInProgress
            ?
            <CircularIndeterminate Type="PoComments"/>
            :
            <div>
              <p style={{fontSize:"0.90em"}}><b>Remarks for Sales :</b> {this.state.comment}</p>
              <p style={{fontSize:"0.90em"}}><b>Internal Remarks :</b> {this.state.status}</p>
            </div>
          }
        </div>
        <div className="col-2">
          {
            this.state.loadingData
            ?
            null
            :
            <React.Fragment>
              <p style={{fontSize:"0.90em"}}><b>Expected Date :</b> {this.state.expectedDate}</p>
              <p style={{fontSize:"0.90em"}}><b>Modified Date :</b> {this.state.modifiedDate}</p>
            </React.Fragment>
          }
        </div>
        <div className="col-3">
          <button
            className="btn btn-primary btn-sm" 
            disabled={this.state.loadingData} 
            onClick={this.onClickEdit}
          >
            Edit
          </button>
        </div>
        <EditModal
          Open={this.state.openEditModal}
          Close={this.closeEditModalWithoutEdit}
          CloseWithEdit={this.closeEditModalWithEdit}
        />
      </div>
    );
  }
}
class CashFlowData extends Component{
  constructor(props){
    super(props);
    this.reactTable=React.createRef();
    this.state={
      data:[],
      resolvedData:[],
      loadingData:true,
      allCommentsData:[],
      salesTeamSearchBy:false,
      salesTeamFilterByValue:'',
      token:this.props.token,
      responseStatus:"",
      searchByFilterValue:"",
      searchByFilter:false,
      searchByInput:"",
      importSuccess:false,
      filtered:[],
      headerStyle:{
        background:"#2f2655",
        color:"whitesmoke",
        fontWeight:"600",
      },
      columnsHeaders:[
        {
          expander:true,
          style:{
            border:'0.5px solid black',
            borderRight:0,
            borderBottom:0,
          }
        },
        {
          Header:"Cust. ID",
          accessor:"CustID",
          headerClassName:"po-table-header",
          width:95,
          className:"view-more-table-data",
          getProps:()=>{
            return{
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Master Cust. ID",
          accessor:"Master Customer ID",
          headerClassName:"po-table-header",
          width:95,
          className:"view-more-table-data",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Customer Name",
          accessor:"Cust Name",
          headerClassName:"po-table-header",
          className:"view-more-table-data",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Invoice Date",
          accessor:"Document Date",
          Cell: props => <div className="view-more-table-data">{new Date(props.value).toLocaleString(undefined,{year:'numeric',month:'long',day:'numeric'})}</div>,
          width: 170,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Invoice No.",
          accessor:"Document No",
          Cell:props=><div className="view-more-table-data">{props.value}</div>,
          headerClassName:"po-table-header",
          width:175,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {    Header:"Customer Order No.",
          accessor:"Customer Order No",
          Cell:props=><span className="view-more-table-data">{props.value===""?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Currency Code",
          accessor:"Currency",
          className:"view-more-table-data",
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Invoice Amount",
          accessor:"Invoice Amount",
          Cell: props => <span className="number view-more-table-data">{(Math.round(props.value * 100) / 100).toLocaleFixed(2)}</span>,
          className:"number",
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Payment",
          accessor:"Payment",
          Cell:props=><span className="number view-more-table-data">{
            props.value!=="0E-20"&&props.value!==null?
            (Math.round(props.value*100)/100).toLocaleFixed(2)
            :
            "-"
          }</span>,
          className:"number",
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Balance",
          accessor:"Balance",
          Cell: props => <span className="number view-more-table-data">{(Math.round(props.value * 100) / 100).toLocaleFixed(2)}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Unapplied Payment",
          accessor:"Unapplied Payment",
          Cell: props => <span className="number view-more-table-data">{(Math.round(props.value * 100) / 100).toLocaleFixed(2)}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Payment Terms",
          accessor:"Payment Terms",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Due Date",
          accessor:"Due Date",
          Cell: props => <div className="view-more-table-data">{new Date(props.value).toLocaleString(undefined,{year:'numeric',month:'long',day:'numeric'})}</div>,
          width: 170,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Outstanding Days",
          accessor:"Days outstanding",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:110,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Shipping Location",
          accessor:"Shipping Location",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Project Code",
          accessor:"Project Code",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:170,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Contact",
          accessor:"Contact",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Phone",
          accessor:"Phone No",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"E-Mail",
          accessor:"E-Mail",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:200,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Sales Team",
          accessor:"Sales Team",
          className:"view-more-table-data",
          headerClassName:"po-table-header",
          filterMethod:(filter,row)=>{
            if(filter.value==="ShowAll"){
              return true;
            }
            if(filter.value==="SALE-1"){
              return row['Sales Team']==="SALE-1";
            }
            if(filter.value==="SALE-2"){
              return row['Sales Team']==="SALE-2";
            }
            if(filter.value==="SALE-3"){
              return row['Sales Team']==="SALE-3";
            }
            if(filter.value==="SALE-6"){
              return row['Sales Team']==="SALE-6";
            }
            if(filter.value==="SALE-8"){
              return row['Sales Team']==="SALE-8";
            }
            if(filter.value==="SALE-9"){
              return row['Sales Team']==="SALE-9";
            }
            if(filter.value==="SALE-10"){
              return row['Sales Team']==="SALE-10";
            }
          },
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Name",
          accessor:"Name",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {   Header:"Invoice Type",
          accessor:"Invoice Type",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:120,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {
          Header:'Job No.',
          accessor:"Job No",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:120,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        },
        {
          Header:'SO No.',
          accessor:"SO No",
          Cell:props=><span className="view-more-table-data">{props.value===""||props.value===null?"-":props.value}</span>,
          headerClassName:"po-table-header",
          width:150,
          getProps:()=>{
            return {
              style:{
                border:'0.5px solid black',
                borderRight:0,
                borderBottom:0,
              }
            }
          }
        }
      ]
    }
  };

  getData = async () => { 
    /**
     * GET Cashflow data to display in table.
     */
    const response = await fetch(CASHFLOW_ENDPOINTS.CASHFLOW_DATA,{
      method:"GET",
      headers:{
        Authorization:`Token ${this.state.token}`
      }
    });
    if(response.status===200){
      const responseJSON=await response.json();
      this.setState({
        loadingData:false,
        data:responseJSON,
        responseStatus:200
      });
    }else if(response.status===404||response.status===500){
      this.setState({
        loadingData:false,
        data:[],
        responseStatus:response.status
      })
    }else{
      this.setState({
        loadingData:false,
        data:[],
        responseStatus:404
      })
    }
  };

  getAllCommentsData = async () => {
    /**
     * GET Comments data for all cashflows.
     */
    this.setState({ loadingData:true });
    const response=await fetch(CASHFLOW_ENDPOINTS.ALL_COMMENTS_DATA,{
      method:"GET",
      headers:{
        Authorization:`Token ${this.state.token}`
      }
    });
    if(response.status===200){
      const responseJSON=await response.json();
      this.setState({
        allCommentsData:responseJSON,
        loadingData:false,
        responseStatus:response.status
      });
    }else if(response.status===404||response.status===500){
      this.setState({
        loadingData:false,
        allCommentsData:[],
        responseStatus:response.status
      })
    }else{
      this.setState({
        loadingData:false,
        allCommentsData:[],
        responseStatus:404
      })
    }
  };

  componentDidMount () {
    this.getData();
  };

  filterSearchBy = (event) => {
    /**
     * Handles onChange event for search filter.
     * @param { Object } event  - Event Object generated by onChange.
     */
    let value = event.target.value;
    let searchByFilter = null;
    let salesTeamSearch = false;
    if (value === "CustomerName") {
      searchByFilter = "Cust Name";
    }else if (value === "CustomerID") {
      searchByFilter="Master Customer ID";
    }else if (value === "OrderNo") {
      searchByFilter="Order No";
    }else if (value === "InvoiceNo") {
      searchByFilter="Document No";
    }else if (value === "SalesTeam") {
      salesTeamSearch = true;
      searchByFilter = "Sales Team";
    }
    if (searchByFilter !== null) {
      if (salesTeamSearch){
        this.setState({
          searchByFilterValue: searchByFilter,
          salesTeamSearchBy: true,
          searchByFilter: false,
        });
      } else {
        this.setState({
          searchByFilterValue: searchByFilter,
          searchByFilter: true,
          salesTeamSearchBy: false,
        });
      }
    }else {
      this.setState({
        searchByFilterValue: '',
        searchByFilter: false,
        searchByInput: '',
        filtered:[],
        salesTeamSearchBy:false,
        salesTeamFilterByValue:'',
      });
    }
  };

  inputSearchBy = (event) => {
    this.setState({ searchByInput: event.target.value });
    this.filterChangeCustom(event.target.value, this.state.searchByFilterValue);
  };

  filterChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered.slice();
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;
          insertNewFilter = 0;
        }
      });
    }
    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };

  trPropsCustom = (state, rowInfo, col, instance) => {
    return {
      onClick: e => {
        const { expanded } = state;
        const path = rowInfo.nestingPath[0];
        const diff = { [path]: expanded[path] ? false : true };
        instance.setState({
          expanded: {
            expanded,
            ...diff
          }
        });
      },
      style: {
        cursor: 'pointer',
      }
    };
  };
  
  filterSalesTeam = (event) => {
    this.setState({
      salesTeamFilterByValue:event.target.value
    });
    this.filterChangeCustom(event.target.value,"Sales Team");
  };

  importCallback = (importSuccess) => {
    this.setState({
      importSuccess:importSuccess
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="secndlayer">
        {
          this.state.responseStatus===500
          ?
          <CustomizedSnackbars
            Variant="error" 
            Message="Server Error. Please try refresh." 
            Open={true}
          />
          :null
        }
        {
          this.state.responseStatus===404
          ?
          <CustomizedSnackbars 
            Variant="error"
            Message="Server Error. Please contact administrator."
            Open={true}
          />
          :null
        }
        <BackdropLoader Open={this.state.loadingData} />
        <div className="row mt-1 mb-1">
          <div className="col-md-6 col-4 filter-search-by-dropdown">
            <select className="search-by select-button ml-3" onChange={this.filterSearchBy}>
              <option value="default">Search By:</option>
              <option value="CustomerName">Customer Name</option>
              <option value="CustomerID">Master Customer ID</option>
              <option value="OrderNo">Order No</option>
              <option value="InvoiceNo">Invoice No</option>
              <option value="SalesTeam">Sales Team</option>
            </select>
            {/* Sales Team Search Only */}
            <CSSTransition
              in={this.state.salesTeamSearchBy}
              timeout={500}
              classNames="animate-filter-search-by-input"
              unmountOnExit
            >
              <select className="search-by select-button ml-3" onChange={this.filterSalesTeam}>
                <option value="ShowAll">Show All</option>
                <option value="SALE-1">SALE-1</option>
                <option value="SALE-2">SALE-2</option>
                <option value="SALE-3">SALE-3</option>
                <option value="SALE-6">SALE-6</option>
                <option value="SALE-8">SALE-8</option>
                <option value="SALE-9">SALE-9</option>
                <option value="SALE-10">SALE-10</option>
              </select>
            </CSSTransition>
            {/* Other Search Filters */}
            <CSSTransition
              in={this.state.searchByFilter}
              timeout={500}
              classNames="animate-filter-search-by-input"
              unmountOnExit
            >
              <input
                className="ml-2"
                onChange={this.inputSearchBy}
                value={this.state.searchByInput}
                placeholder=" Search here..." />
            </CSSTransition>
          </div>
          <div className="col" align="right" style={{paddingRight:"1.75em"}}>
            <SampleTemplateImport/>
            &nbsp;
            &nbsp;
            <ImportExcel Callback={this.importCallback}/>
            &nbsp;
            &nbsp;
            <ExportToExcel
            TableRefCurrent={this.reactTable.current}
            GetCommentsDataCallback={this.getAllCommentsData}
            CommentsData={this.state.allCommentsData}
            />
          </div>
        </div>
        <div className="table-data">
          <ReactTable
            ref={this.reactTable}
            className="-highlight table-style"
            previousText="<"
            nextText=">"
            SubComponent={(row)=>(<SubComponent RowData={row.original}/>)}
            data={this.state.data}
            showPageJump={false}
            filtered={this.state.filtered}
            onFilteredChange={(filtered, column, value) => {
              this.filterChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (typeof filter.value === "object") {
                return row[id] !== undefined
                  ? filter.value.indexOf(row[id]) > -1
                  : true;
              } else {
                return row[id] !== undefined
                  ? String(row[id]).indexOf(filter.value) > -1
                  : true;
              }
            }}
            columns={this.state.columnsHeaders}
            defaultPageSize={50}
            resizable={false}
            getTheadProps={() => {
              return {
                style: this.state.headerStyle,
              }
            }}
            getTbodyProps={() => {
              return {
                style: {
                  overflowX: "hidden",
                }
              }
            }}
            defaultSorted={[
              {
                id: "Due Date",
                desc:true
              }
            ]}
            getTrProps={this.trPropsCustom}
            // For (Reset) Scrolling to top on page change in table
            onPageChange={()=>{
              document.getElementsByClassName('rt-tbody')[0].scrollTop=0;
            }}
          />
        </div>
        </div>
      </div>
    );
  }
}

export default CashFlowData;