import React,{Component} from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class ExportToExcel extends Component{
    parseExportData=(exportData)=>{
        let commentsData=this.props.CommentsData;
        for(let i=0;i<exportData.length;i++){
            delete exportData[i]._original;
            delete exportData[i]._index;
            delete exportData[i]._subRows;
            delete exportData[i]._nestingLevel;
            let invoiceNo=exportData[i]["Document No"];
            if(invoiceNo in commentsData){
                let addData=commentsData[invoiceNo];
                let d=new Date(addData.expected_date);
                let mm=d.getMonth()+1;
                if(mm<10){
                    mm=`0${mm}`;
                }
                let dd=d.getDate();
                if(dd<10){
                    dd=`0${dd}`;
                }
                // let fdate=`${d.getFullYear()}-${mm}-${dd}`;
                let fdate=`${dd}-${mm}-${d.getFullYear()}`;
                exportData[i]['Comment']=addData.comment;
                exportData[i]['Status']=addData.status;
                exportData[i]['Expected Date']=fdate;
            }else{
                exportData[i]['Comment']='';
                exportData[i]['Status']='';
                exportData[i]['Expected Date']='';
            }
            let docDate=exportData[i]["Document Date"];
            let dueDate=exportData[i]["Due Date"];
            docDate=docDate.split("-");
            dueDate=dueDate.split("-");
            docDate=`${docDate[2]}/${docDate[1]}/${docDate[0]}`;
            dueDate=`${dueDate[2]}/${dueDate[1]}/${dueDate[0]}`;
            exportData[i]["Document Date"]=docDate;
            exportData[i]["Due Date"]=dueDate;
        }
        return exportData;
    }
    dataExport=(exportData,fileName)=>{
        exportData=this.parseExportData(exportData);
        const ws = XLSX.utils.json_to_sheet(exportData,
            {
                header:[
                    "CustID","Master Customer ID","Cust Name","Document Date",
                    "Document No","Customer Order No",
                    "Currency","Invoice Amount","Payment"
                    ,"Balance","Unapplied Payment","Payment Terms"
                    ,"Due Date","Days outstanding","Shipping Location",
                    "Project Code","Contact","Phone No",
                    "E-Mail","Sales Team","Name","Invoice Type",
                    "Job No","SO No",
                    "Comment","Status","Expected Date"
                ],
            }
        );
        ws.A1.v="Cust. ID";
        ws.B1.v="Master Cust. ID";
        ws.C1.v="Customer Name";
        ws.D1.v="Invoice Date";
        ws.E1.v="Invoice No.";
        ws.F1.v="Customer Order No.";
        ws.G1.v="Currency Code";
        ws.N1.v="Outstanding Days";
        ws.R1.v="Phone";
        ws.Y1.v="Remarks for Sales";
        ws.Z1.v="Internal Remarks";
        const wb= {Sheets:{'data':ws},SheetNames:['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    onClickExport=async()=>{
        await this.props.GetCommentsDataCallback();
        let currDate=new Date();
        let fileName=`cashflowexport_${currDate.getDate()}-${currDate.getMonth()+1}-${currDate.getFullYear()}`;
        this.dataExport(this.props.TableRefCurrent.getResolvedState().sortedData,fileName);
    }
    render(){
        return (
            <button className="btn btn-primary btn-sm" onClick={this.onClickExport}>
                Export
            </button>
        );
    }
}

export default ExportToExcel;