import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class TermsModal extends Component{
    constructor(props){
        super(props);
        this.state={
            closeCallback:this.props.CloseCallback,
            data:this.props.Data,
        }
    }
    closeModal=()=>{
        this.state.closeCallback();
    }
    render(){
        let paymentTermsDesc=this.state.data.paymentTermsDesc;
        let shipmentTermsDesc=this.state.data.shipmentTermsDesc;
        return (
                <Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={this.closeModal}
                aria-labelledby="vendor-details-modal"
                open={this.props.Open}
                >
                    {
                        this.state.response===404?
                        <div>
                        <DialogTitle id="vendor-details-modal" onClose={this.closeModal}>
                        NO DATA
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography variant="subtitle1">
                                {this.state.noDataMessage}
                            </Typography>
                        </DialogContent>
                        </div>
                        :
                        <div>
                        <DialogTitle id="vendor-details-modal" onClose={this.closeModal}>
                        TERMS
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        <span style={{fontWeight:600}}>
                                            Payment Terms Description
                                        </span>
                                        <br/>
                                        <span style={{fontWeight:300}}>
                                            {paymentTermsDesc===undefined||paymentTermsDesc===""?"Not available.":paymentTermsDesc}
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        <span style={{fontWeight:600}}>
                                            Shipment Terms Description
                                        </span>
                                        <br/>
                                        <span style={{fontWeight:300}}>
                                            {shipmentTermsDesc===undefined||shipmentTermsDesc===""?"Not available.":shipmentTermsDesc}
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        </div>
                    }
                </Dialog>
        );
    }
}
export default TermsModal;

