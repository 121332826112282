import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';

const ConfirmDialog = ({ 
    renderButton, 
    dialogTitle, 
    confirmCallback,
    closeCallback=null, 
    confirmLabel="Yes", 
    cancelLabel="No",
    DialogContentComp=null 
}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        if(closeCallback!==null) closeCallback();
    };
    const handleConfirm = () => {
        setOpen(false);
        confirmCallback();
    };
    return (
        <>
        { renderButton(handleOpen) }
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                {DialogContentComp && <DialogContentComp />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" size="small">
                    {cancelLabel}
                </Button>
                <StyledButton variant="contained" onClick={handleConfirm} color="primary" size="small">
                    {confirmLabel}
                </StyledButton>
            </DialogActions>
        </Dialog>
        </>
    );
};

const StyledButton = styled(Button)`
    background-color: #bfc9ff;
    color: black;
    &:hover {
        background-color: #9ba3cf;
    }
    &:focus { 
        outline: none;
    }
`;

ConfirmDialog.propTypes = {
    // open: PropTypes.bool.isRequired,
    // buttonTitle: PropTypes.string.isRequired,
    renderButton: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    confirmCallback: PropTypes.func.isRequired,
    closeCallback: PropTypes.func,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    dialogContentComp: PropTypes.elementType,
};

export default ConfirmDialog;