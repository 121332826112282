import React,{ Component } from 'react';
import 'css/tabledata.css';
import CommentModal from 'components/table/CommentModal';
import TableButton from 'components/buttons/TableButton';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
/**
 * This component handles subcomponent row render for table in approval portal.
 */
const snackbarInfo={
    Approve:{
        variant:'success',
        message:'Approved',
    },
    Reject:{
        variant:'error',
        message:'Rejected',
    },
    SendForFinalApproval:{
        variant:'success',
        message:'Sent for Final Approval',
    },
    DelegateButton:{
        variant:'info',
        message:'Delegated to User',
    }
}

const AdminComponent = (props) => {
    let statusVal = props.RowStatus;
    return (
        <div>
            {
                ( statusVal===1 || statusVal===0 || statusVal===5 )
                &&
                <div>
                    <TableButton
                        buttonText="Approve"
                        CustomClasses="mr-2"
                        onClickHandler={()=>{
                            props.HandleApproveClick();
                        }}
                    />
                    <TableButton
                        buttonText="Reject"
                        onClickHandler={()=>{
                            props.HandleRejectClick();
                        }}
                    />
                </div>
            }
        </div>
    );
}

const BasicComponent = (props) => {
    let statusVal = props.RowStatus;
    return (
        <div>
            {
                ( statusVal===1 || statusVal===0 )
                &&
                <div>
                    <TableButton
                        buttonText="Send for Approval"
                        onClickHandler={()=>{
                            props.HandleApproveClick();
                        }}
                    />
                    <TableButton
                        buttonText="Reject"
                        onClickHandler={()=>{
                            props.HandleRejectClick();
                        }}
                    />
                </div>
            }
        </div>
    );
}
class ButtonComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            userType:0,
            approveValue:0,
            rejectValue:0,
            rowStatus:0,
            openCommentModal:false,
            responseStatus:0,
            commentInput:'',
            buttonTypeClicked:'',
            success:false,
            openDelegateModal:false,
            delegateUserDetails:[],
        }
    }
    componentDidMount(){
        this.setState({
            userType:sessionStorage.getItem("usertype"),
            approveValue:sessionStorage.getItem("approveValue"),
            rejectValue:sessionStorage.getItem("rejectValue"),
            rowStatus:this.props.RowStatus,
            openCommentModal:false,
            responseStatus:0,
            commentInput:'',
            success:false,
        });
    }
    buttonClicked=(action)=>{
        if(action==="Reject"||action==="Approve"||action==="SendForFinalApproval"){
            this.setState({
                openCommentModal:true,
                buttonTypeClicked:action,
            });
        }else{
            this.setState({
                openDelegateModal:true,
                buttonTypeClicked:action,
            })
        }
    }
    buttonClickCallback=(response)=>{
        if(response===200){
            this.setState({responseStatus:200});
        }else{
            this.setState({responseStatus:404});
        }
    }
    closeCommentModal=()=>{
        this.setState({
            openCommentModal:false,
            success:false,
        });
    }
    closeWithCommentModal=(comment)=>{
        this.setState({
            openCommentModal:false,
            commentInput:comment,
            success:true,
        });
        if((this.state.buttonTypeClicked==="Approve"||this.state.buttonTypeClicked==="SendForFinalApproval")&&comment!==''){
            this.props.HandleApproveClick(this.buttonClickCallback,comment);
        }else if(this.state.buttonTypeClicked==="Reject"&&comment!==''){
            this.props.HandleRejectClick(this.buttonClickCallback,comment);
        }
    }
    render(){
        var rowStatus=this.state.rowStatus;
        return (
            <div style={{paddingLeft:"1em"}}>
                {
                    this.state.userType==="1"
                    ?
                    // Admin User
                    <AdminComponent
                    RowStatus={rowStatus}
                    HandleApproveClick={()=>this.buttonClicked('Approve')}
                    HandleRejectClick={()=>this.buttonClicked('Reject')}
                    // HandleDelegateClick={()=>this.buttonClicked('Delegate')}
                    />
                    :
                    // Basic User
                    <BasicComponent
                    RowStatus={rowStatus}
                    HandleApproveClick={()=>this.buttonClicked('SendForFinalApproval')}
                    HandleRejectClick={()=>this.buttonClicked('Reject')}
                    // HandleDelegateClick={()=>this.buttonClicked('Delegate')}
                    />
                }
                <CommentModal
                Open={this.state.openCommentModal}
                Close={this.closeCommentModal}
                CloseWithComment={this.closeWithCommentModal}
                />
                {/* <DelegateModal
                Open={this.state.openDelegateModal}
                Close={this.closeDelegateModal}
                CloseWithDelegateDetails={this.closeWithDelegateDetails}
                /> */}
                {
                    // Server Error.
                    this.state.responseStatus===404
                    ?
                    <CustomizedSnackbars
                    Open={true}
                    Variant="info"
                    Message="There was a server error. Refresh and try again."
                    />
                    :
                    null
                }
                {
                    this.state.responseStatus===200
                    ?
                    <div>
                        <CustomizedSnackbars
                        Open={true}
                        Variant={snackbarInfo[this.state.buttonTypeClicked].variant}
                        Message={snackbarInfo[this.state.buttonTypeClicked].message}
                        />
                    </div>
                    :null
                }
            </div>
        );
    }
}

export default ButtonComponent;