import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Grid, Button, Typography } from '@material-ui/core';
// Endpoints
import { PMC_ENDPOINTS } from 'endpoints';
// Custom Components
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import BackdropLoader from 'components/loaders/BackdropLoader';
import SubComponentDialog from 'pmcSystem/SubComponentDialog';
import { Table, EditableTable } from 'pmcSystem/Table';
import PerfMetricsComp from 'pmcSystem/PerfMetricsComp';
import exportExcel from 'pmcSystem/exportExcel';
// Utility functions
import fetchAPI from 'fetchAPI';
import { dateTimeFormatting } from 'utility/dateTimeFormat';
import prepareColumns from 'pmcSystem/Table/prepareColumns';

// Formats date for table columns.
const tableDateFormatting = (value) => {
  /**
   * Formats date to Indian Locale String.
   */
  const dateObj = new Date(Date.parse(value));
  const options = {weekday: 'short', year: 'numeric', month:'long', day:'numeric'};
  const formattedDate = dateObj.toLocaleDateString('en-IN',options);
  return (
    <span>{formattedDate}</span>
  );
}

const TableDateComp = (value) => {
  const { formattedDate } = dateTimeFormatting(value);
  return (<span>{formattedDate}</span>);
}

const TableDateTimeComp = (value) => {
  /**
   * Returns Date Time Component
   */
  const { formattedDate, formattedTime } = dateTimeFormatting(value);
  return (
    <div>
      <div>{formattedDate}</div>
      <div>{formattedTime}</div>
    </div>
  );
}

// TABLE HEADERS DETAILS
// Main Table Headers Details.
const headersDetails = [
  {
    id: 1,
    title: 'Report Name',
    accessor: 'report_name',
  },
  {
    id: 2,
    title: 'Report Description',
    accessor: 'report_description',
  },
  {
    id: 3,
    title: 'Extract Date',
    accessor: 'extract_date',
    cell: function(props){ return TableDateComp(props.value) },
  },
  {
    id: 4,
    title: 'Extract Year',
    accessor: 'extract_year',
  },
  {
    id: 5,
    title: 'Last Edited',
    accessor: 'modified_at',
    cell: function(props){ return TableDateTimeComp(props.value) },
  }
];

// View All Table Headers Details.
const subComponentHeadersDetails = [
  {
    id: 1,
    title: 'ID',
    accessor: 'id',
    show: false
  },
  {
    title: 'Customer ID',
    accessor: 'customer_id'
  },
  {
    id: 2,
    title: 'D_Bill To ID',
    accessor: 'd_bill_to_id'
  },
  {
    id: 3,
    title: 'D_Install Site End Customer Name',
    // accessor: 'd_install_site_end_customer_number'
    accessor: 'd_install_site_end_customer_name'
  },
  {
    id: 4,
    title: 'D_Install Site End Customer Number',
    accessor: 'd_install_site_site_use_id'
  },
  {
    id: 5,
    title: 'D_Install Site Global Name',
    accessor: 'd_install_site_global_name',
    show: false
  },
  {
    id: 6,
    title: 'D_Install Site Global Id',
    accessor: 'd_install_site_global_id',
    show: false
  },
  {
    id: 7,
    title: 'D_Install Site Name',
    accessor: 'd_install_site_name'
  },
  
  {
    id: 9,
    title: 'D_Opportunity',
    accessor: 'd_opportunity'
  },
  {
    id: 10,
    title: 'Opportunity Quarter',
    accessor: 'opportunity_quarter'
  },
  {
    id:11,
    title: 'Quantity',
    accessor: 'quantity',
    show: false
  },
  {
    id: 11,
    title: 'N_Renewed',
    accessor: 'n_renewed'
  },
  {
    id: 12,
    title: 'Adjusted Renewed',
    accessor: 'adjusted_renewed'
  },
  {
    id: 13,
    title: 'Adjusted Opportunity',
    accessor: 'adjusted_opportunity'
  },
  {
    id: 14,
    title: 'Adjusted Include in Calcs',
    accessor: 'adjusted_include_in_calcs'
  },
  {
    id: 15,
    title: 'Last Edited',
    accessor: 'modified_at',
    cell: function(props){ return tableDateFormatting(props.value) }
  },
];

// Quarterly Table Headers Details.
const quarterHeadersDetails = [
  {
    title: 'ID',
    accessor: 'id',
    show: false 
  },
  {
    title: 'Customer ID',
    accessor: 'customer_id',
    filter: 'fuzzyText',
    width: 100
  },
  {
    title: 'Customer Name',
    accessor: 'customer_name',
    filter: 'fuzzyText',
    width: 400
  },
  {
    title: 'Opportunity ($)',
    accessor: 'd_opportunity',
    alignment: 'center',
    currency: true,
    showTotal: true,
    width: 110
  },
  {
    title: 'Renewed ($)',
    accessor: 'n_renewed',
    alignment: 'center',
    currency: true,
    showTotal: true,
    width: 110
  },
  {
    title: 'Lost ($)',
    accessor: 'lost',
    alignment: 'center',
    currency: true,
    showTotal: true,
    width: 110
    // editable: true
  },
  {
    title: 'Net Amt. ($)',
    accessor: 'net_amount',
    alignment: 'center',
    currency: true,
    showTotal: true,
    width: 110
  },
  {
    title: 'Orders Won Not in PMC ($)',
    accessor: 'orders_won_not_in_pmc',
    alignment: 'center',
    currency: true,
    editable: true,
    width: 110
  },
  {
    title: 'Orders Lost Not in PMC ($)',
    accessor: 'orders_lost_not_in_pmc',
    alignment: 'center',
    currency: true,
    editable: true,
    width: 110
  },
  {
    title: 'Orders Win Forecasted ($)',
    accessor: 'orders_win_forecasted',
    alignment: 'center',
    currency: true,
    editable: true,
    width: 110
  },
  {
    title: 'Orders Loss Forecasted ($)',
    accessor: 'orders_loss_forecasted',
    alignment: 'center',
    currency: true,
    editable: true,
    width: 110
  },
  {
    title: 'Remarks',
    accessor: 'remarks',
    editable: true,
    width: 110
  },
  {
    title: 'Proactive Quotation No.',
    accessor: 'proactive_quotation_number',
    editable: true,
    width: 110
  },
];

const perfMetricsTotalHeadersDetails = [
  {
    title: 'Total Opportunity ($)',
    accessor: 'total_opportunity',
    currency: true,
  },
  {
    title: 'Total Renewed ($)',
    accessor: 'total_renewed',
    currency: true
  },
  {
    title: 'Total Lost ($)',
    accessor: 'total_lost',
    currency: true
  },
  {
    title: 'Orders won not in PMC ($)',
    accessor: 'orders_won_not_in_pmc',
    currency: true
  },
  {
    title: 'Orders lost not in PMC ($)',
    accessor: 'orders_lost_not_in_pmc',
    currency: true
  },
  {
    title: 'Orders win forecasted ($)',
    accessor: 'orders_win_forecasted',
    currency: true
  },
  {
    title: 'Orders loss forecasted ($)',
    accessor: 'orders_loss_forecasted',
    currency: true
  }
];


const ViewList = () => {
  // STATE VARIABLES
  // For opening and closing of dialog.
  const [openDialog, setOpenDialog] = useState(false);
  
  // Details of what row has been opened (SubComponent).
  const [openRowDetails, setOpenRowDetails] = useState({});
  
  // Data for open row table.
  const [openRowData, setOpenRowData] = useState([]);

  // For editable table.
  const [origOpenRowData, setOrigOpenRowData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [openQuarter, setOpenQuarter] = useState('');
  
  // Performance Metrics Dialog
  const [openPerfMetricsDialog, setOpenPerfMetricsDialog] = useState(false);
  const [perfMetricsData, setPerfMetricsData] = useState([]);
  const [perfMetricsLoading, setPerfMetricsLoading] = useState(false);
  
  // Possible values for openRowTableType = 'Quarter' or 'ViewAll'.
  const [openRowTableType, setOpenRowTableType]= useState('');
  
  // Data for viewing in outer most table. Details about file uploads.
  const [list, setList] = useState([]);

  // Loader state.
  const [openLoader, setOpenLoader] = useState(false);

  // State for managing success actions.
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  // State for managing hint actions.
  const [showHint, setShowHint] = useState(false);
  const [hintMsg, setHintMsg] = useState('');

  // Failed to get data. Error states.
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Table columns.
  const tableColumns = useMemo(
    () => headersDetails.map(item => prepareColumns(item)),
    []
  );
  
  const perfMetricsTotalTableColumns = useMemo(
    () => perfMetricsTotalHeadersDetails.map( item => prepareColumns(item)),
    []
  );
  

  // useEffects
  useEffect(()=>{
    getListData();
  },[]);
  useEffect(()=>{
    setSkipPageReset(false);
  },[openRowData]);

  // FETCH SERVICES & API CALLS.
  // Get list data.
  const getListData = async()=>{
    setOpenLoader(true);
    const { responseData, statusCode, error } = await fetchAPI(PMC_ENDPOINTS.LIST, 'GET');
    if(statusCode === 200 && !error){
      setList(responseData);
      setOpenLoader(false);
      resetError();
    } else {
      setList([]);
      setOpenLoader(false);
      setError(true);
      setErrMsg('There was a problem getting the data. Please try again later.');
    }
  };
  // Get particular row entry details for all quarters.
  const getListRowData = async(rowData) => {
    const id = rowData.id;
    const { responseData, statusCode, error } = await fetchAPI(`${PMC_ENDPOINTS.DATA_PER_ROW}${id}`, 'GET');
    if(statusCode === 200 && !error) {
      setOpenRowData(responseData);
      setOpenDialog(true);
      setOpenLoader(false);
      resetError();
    } else {
      setOpenRowData([]);
      setOpenDialog(false);
      setOpenLoader(false);
      setError(true);
      setErrMsg('There was a problem getting the data. Please try again later.');
    }
  };
  // Get particular row entry details for quarter.
  const getListRowDataQuarter = async(rowData, quarter) => {
    const id = rowData.id;
    const bodyData = {
      quarter: quarter
    };
    const { responseData, statusCode, error } = await fetchAPI(`${PMC_ENDPOINTS.DATA_PER_ROW_QUARTER}${id}`, 'POST', bodyData);
    if(statusCode === 200 && !error) {
      setOpenRowData(responseData);
      setOrigOpenRowData(responseData);
      setOpenDialog(true);
      setOpenLoader(false);
      resetError();
    } else {
      setOpenRowData([]);
      setOpenDialog(false);
      setOrigOpenRowData([]);
      setOpenLoader(false);
      setError(true);
      setErrMsg('There was a problem getting the data. Please try again later.');
    }
  };
  // Put edited data for quarter.
  const putEditedData = async(bodyData) => {
    const id = openRowDetails.id;
    const { statusCode, error } = await fetchAPI(`${PMC_ENDPOINTS.UPDATE_METRICS_DATA}${id}`, 'PUT', bodyData);
    if(statusCode === 200 && !error) {
      setOpenLoader(false);
      setError(false);
      setSuccess(true);
      setSuccessMsg('Data submitted.');
    } else {
      setError(true);
      setErrMsg('There was a problem submitting data. Please try again.');
      setOpenLoader(false);
    }
  };
  // Get performance metrics data for quarter.
  const getPerformanceMetrics = async(bodyData, rowId = null) => {
    const id = rowId === null ? openRowDetails.id : rowId;
    const { responseData, statusCode, error } = await fetchAPI(`${PMC_ENDPOINTS.METRICS_DATA}${id}`, 'PUT', bodyData);
    if(statusCode === 200 && !error) {
      if(perfMetricsData.length!==0){
        setShowHint(true);
        setHintMsg('Performance metrics updated.');
      }
      setPerfMetricsLoading(false);
      setOpenPerfMetricsDialog(true);
      setPerfMetricsData(responseData['data']);
      // setOpenLoader(false);
    } else {
      // setOpenLoader(false);
      setPerfMetricsLoading(false);
      setError(true);
      setErrMsg('There was a problem receiving performance metrics data. Please try again.');
    }
  }

  // TABLE ROW SUBCOMPONENT
  // Table row subcomponent to render.
  const renderRowSubComponent = React.useCallback(({row})=>(
    <>
      <SubCompButton 
        onClick={()=>onClickViewAll(row.original)}
      >
        View All
      </SubCompButton>
      <SubCompButton
        onClick={()=>onClickQuarter(row.original,'Q1')}
      >
        Q1
      </SubCompButton>
      <SubCompButton
        onClick={()=>onClickQuarter(row.original,'Q2')}
      >
        Q2
      </SubCompButton>
      <SubCompButton
        onClick={()=>onClickQuarter(row.original,'Q3')}
      >
        Q3
      </SubCompButton>
      <SubCompButton
        onClick={()=>onClickQuarter(row.original,'Q4')}
      >
        Q4
      </SubCompButton>
    </>
    ),
    []
  );

  // Row Subcomponent button click handlers
  const onClickViewAll = (rowDetails)=>{
    setOpenLoader(true);
    getListRowData(rowDetails);
    setOpenRowTableType('ViewAll');
    setOpenRowDetails(rowDetails);
  };
  const onClickQuarter = (rowDetails, quarter) => {
    setOpenLoader(true);
    getListRowDataQuarter(rowDetails, quarter);
    handleView(quarter, rowDetails.id);
    setOpenRowTableType('Quarter');
    setOpenQuarter(quarter);
    setOpenRowDetails(rowDetails);
  }

  // Function for updating edited table data.
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);

    // Copy current data
    const currentData = openRowData;
    
    // Create a edit row duplicate with new values.
    const currentEdit = { ...currentData[rowIndex], [columnId]: value};
    
    // Duplicated current editedRows state.
    var newEditedRows = Object.assign([], editedRows);

    // If edited rows are zero. Add row.
    if(newEditedRows.length===0) {
      newEditedRows.push(currentEdit);
    } else {
      // Check if edited row is already present. If yes, update existing
      // otherwise add new row.
      let addNew = true;
      newEditedRows = newEditedRows.map(row => {
        // Match id (unique for every row).
        if(row.id === currentEdit.id) {
          addNew = false;
          return currentEdit;
        }
        return row;
      });
      if(addNew){
        newEditedRows.push(currentEdit);
      }
    }
    // Set new edited rows state.
    setEditedRows(newEditedRows);

    // Set updated table data.
    setOpenRowData( old => 
      old.map((row, index) => { 
        if(index === rowIndex) return { ...old[rowIndex], [columnId]: value};
        return row;
      })
    );
  };

  // Submit edited data to backend.
  const handleSubmit = async() => {
    setOpenLoader(true);
    if(editedRows.length===0){
      setOpenLoader(false);
      setShowHint(true);
      setHintMsg('No field is changed. Try changing a field and then submitting.');
      return;
    }
    const bodyData = editedRows.map(row=>{
      const orders_won_not_in_pmc = row.orders_won_not_in_pmc===""?"0":row.orders_won_not_in_pmc;
      const orders_lost_not_in_pmc = row.orders_lost_not_in_pmc===""?"0":row.orders_lost_not_in_pmc;
      const orders_win_forecasted = row.orders_win_forecasted===""?"0":row.orders_win_forecasted;
      const orders_loss_forecasted = row.orders_loss_forecasted===""?"0":row.orders_loss_forecasted;
      const remarks = row.remarks;
      const proactive_quotation_number = row.proactive_quotation_number===""?"0":row.proactive_quotation_number;
      return {
        id: row.id,
        orders_won_not_in_pmc,
        orders_lost_not_in_pmc,
        orders_win_forecasted,
        orders_loss_forecasted,
        remarks,
        proactive_quotation_number
      }
    });
    await putEditedData(bodyData);
    await handleView();
  };
  // View after submitted data.
  const handleView = (quarter = null, rowId = null) => {
    // setOpenLoader(true);
    setPerfMetricsLoading(true);
    const bodyData = {
      quarter: quarter===null ? openQuarter : quarter
    };
    getPerformanceMetrics(bodyData, rowId);
  };
  // Export view all table data to excel.
  const handleExport = async() => {
    setOpenLoader(true);
    let exportHeaders = [];
    let reportName = openRowDetails['report_name'] + ` - FY ${openRowDetails['extract_year']}`;
    if(openRowTableType === 'Quarter'){
      exportHeaders = quarterHeadersDetails.filter(item => {
        if(item.show === false) return false;
        return true;
      }).map(item =>({
        exportTitle: item.title,
        exportKey: item.accessor
      }));
      reportName = reportName + ` ${openQuarter}`;
    }
    else {
      exportHeaders = subComponentHeadersDetails.filter(item=>{
        if(item.show === false) return false;
        return true;
      }).map(item=>({
          exportTitle: item.title,
          exportKey: item.accessor 
        })
      );
      reportName = reportName + ` All Quarters`;
    }
    await exportExcel(openRowData, exportHeaders, reportName, exportCallback);
  };
  const exportCallback = ( status ) => {
    if(status === 'ERROR') {
      setError(true);
      setErrMsg('There was some problem while exporting file.');
    }
    setOpenLoader(false);
  };

  // Resetting states.
  const resetError = () => {
    setError(false);
    setErrMsg('');
  };
  const resetSuccess = () => {
    setSuccess(false);
    setSuccessMsg('');
  };
  const resetHint = () => {
    setShowHint(false);
    setHintMsg('');
  }
  const resetSubComponentDialogStates = () => {
    setOpenDialog(false);
    setOpenQuarter('');
  };

  return (
    <WrapperGrid>
      {/* Notifications */}
      <CustomizedSnackbars Open={showHint} Variant="info" Message={hintMsg} CloseCallback={resetHint} EnableTimer Timer={10000}/>
      <CustomizedSnackbars Open={success} Variant="success" Message={successMsg} EnableTimer Timer={3000} CloseCallback={resetSuccess}/>
      <CustomizedSnackbars Open={error} Variant="error" Message={errMsg} CloseCallback={resetError}/>
      {/* Loaders */}
      <BackdropLoader Open={openLoader}/>
      {/* Tables */}
      <div className="secndlayer setcolorspncheader">
      <Table style={{color:'red'}}
        columns={tableColumns}
        data={list}
        renderRowSubComponent={renderRowSubComponent}
      />
      </div>
      <SubComponentDialog 
        open={openDialog} 
        closeCallback={resetSubComponentDialogStates} 
        perfMetricsComponent = {
          <> 
            <BackdropLoader Open={perfMetricsLoading}/> 
            <Table data={perfMetricsData} columns={perfMetricsTotalTableColumns} disablePagination/>
            <PerfMetricsComp data={perfMetricsData}/>
          </>
        }
        renderComponent={
          <SubComponentTable 
            data={openRowData}
            type={openRowTableType}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
          />
        }
        title={
          <div>
            <Typography variant="body1">
              <strong>Report Name:</strong> {openRowDetails['report_name']}
            </Typography>
            <Typography variant="body1">
              <strong>FY:</strong> {openRowDetails['extract_year']}
            </Typography>
            {   
              openQuarter!=='' &&
              <Typography variant="body1">
                <strong>Quarter:</strong> {openQuarter}
              </Typography>
            }
          </div>
        }
        type={openRowTableType}
        // disableSubmit={editedRows.length===0}
        handleSubmit={handleSubmit}
        handleView={handleView}
        handleExport={handleExport}
      />
    </WrapperGrid>
  );
};

const SubComponentTable = ({ 
  data, 
  type, 
  updateMyData, 
  skipPageReset 
}) => {
  const [hiddenColumns, setHiddenColumns] = useState();
  const quarterTableColumns = useMemo(
    () => {
      let tempHiddenCols = [];
      const cols = quarterHeadersDetails.filter(item => !(item.show === false)).map(item => {
        return prepareColumns(item);
      });
      setHiddenColumns(tempHiddenCols);
      return cols;
    },
    []
  );
  // SubComponent table columns.
  const viewAllTableColumns = useMemo(
    () => {
      let tempHiddenCols = [];
      const cols = subComponentHeadersDetails.filter(item => !(item.show === false)).map(item => {
        return prepareColumns(item);
      });
      setHiddenColumns(tempHiddenCols);
      return cols;
    },
    []
  );
  
  useEffect(()=>{
    setHiddenColumns([]);
  }, [type]);
  return (
    <>
    {
      type==='Quarter' &&
      <EditableTable
        columns={quarterTableColumns} 
        data={data} 
        updateMyData={updateMyData} 
        skipPageReset={skipPageReset}
        hiddenColumns={hiddenColumns}
        defaultPageSize={100}
      />
    }
    {
      type==='ViewAll' &&
      <Table columns={viewAllTableColumns} data={data} defaultPageSize={100}/>
    }
    </>
  );
};



const WrapperGrid = styled(Grid)`
  padding: 1rem;
`;

const SubCompButton = styled(otherProps => (
  <Button variant="contained" size="small" {...otherProps}/>
))`
  background-color: #bfc9ff;
  margin-right: 1em;
  &:hover {
    background-color: #9ba3cf;
  }
  &:focus {
    outline: none;
  }
`;

export default ViewList;