const USER_TYPE = {
    ADMIN: '1',
    USER: '2',
    APPROVAL_PORTAL_ADMIN: '1',
    APPROVAL_PORTAL_USER: '2',
    CASHFLOW_SYSTEM: '6',
    QUOTATION_SYSTEM: '11',
    // PMC System
    PMC_ADMIN: '12',
    PMC_USER: '13'
}

export { USER_TYPE };


