// Headers and Accessor Mappings for Screen 2 and Screen 3 for Quotation System.
const headersAccessorMapping = {
    'Billing Type': 'billing_model',
    'CGST (Rs)': 'cgst_amount_line',
    'Cisco Discount (%)': 'discount_per_line',
    'Contract Total Value (Rs)': 'contract_total_value',
    'Duty Amount': 'ccw_duty_slc_net',
    'Discount (%)': 'discount_per_line',
    'Estimate Number': 'est_number',
    'Extended List (Rs)': 'extended_list_amount',
    'End Date': 'endDateActive',
    'Freight Amount': 'ccw_freight_ftwz_net',
    'GST Code': 'gst_code',
    'HSN Code': 'hsn_code',
    'HSN/SAC Code': 'gst_code',
    'Instance Number': 'instanceNumber',
    'Initial Term (months)': 'initial_term',
    'Installment Value (Rs)': 'installment_value',
    'IGST (Rs)': 'igst_amount_line',
    'Line No.': 'line_num',
    'List Price (Rs)': 'list_price',
    'T2:List Price (Rs)': 'pricing_listPrice',
    'Major/Minor Part Category': 'part_category',
    'Mark-up (%)': 'markup_per_line',
    'No. of Installment': 'xaas_installments',
    'Part Number': 'pro_name',
    'Part Code': 'pro_name',
    'Part Description': 'pro_description',
    'Pricing Term (months)': 'xaas_pricing_term',
    'Qty': 'quantity',
    'Qty (T2)': 'pro_quantity',
    'Quoted Pricing Term Rate': 'installment_value',
    'Quoted Pricing Term Unit Price': 'xaas_monthly_unit',
    'Quoted Contract Value (Rs)': 'contract_total_value',
    'Quoted Rate (Rs)': 'quoted_rate',
    'Quoted Total (Rs)': 'quoted_total',
    'SGT (Rs)': 'sgst_amount_line',
    'Service Duration': 'service_duration',
    'Service Type': 'serv_name',
    'Service Part Code': 'sku',
    'Start Date': 'startDateActive',
    'Serial Number': 'serialNumber',
    'Support Part Code': 'sku',
    'Service Level': 'serv_description',
    'UOM': 'uom',
    'Hold Item': 'is_hold_item',
    'Subscription Credit':'subscription_credit',
    /** 
     * NOTE: markup_per_line is a dynamic column.
     * It changes upon based on other parameters.
     * To incorporate that logic we have just given it the title of conditionHeader.
     * The logic for assigning this is handled by the column array builder function.
     */
    'conditionHeader': 'markup_per_line',
}

// Screen 2 = CCW Download Page
const viewProductColumns = [
    {
        title: 'Delete',
        showFor: new Set(['T2'])
    },
    {
        title: 'Estimate Number',
        accessor: headersAccessorMapping['Estimate Number'],
        show: false,
    },
    {
        title: 'Line No.',
        accessor: headersAccessorMapping['Line No.'],
        width: 70,
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Part Number',
        accessor: headersAccessorMapping['Part Number'],
        showFor: new Set(['T2'])
    },
    {
        title: 'Part Number',
        accessor: 'part_number',
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Serial Number',
        accessor: headersAccessorMapping['Serial Number'],
        showFor: new Set(['T2']),
    },
    {
        title: 'Instance Number',
        accessor: headersAccessorMapping['Instance Number'],
        showFor: new Set(['T2']),
    },
    {
        title: 'Initial Term (months)',
        accessor: headersAccessorMapping['Initial Term (months)'],
        width: 80,
        showFor: new Set(['T6']),
    },
    {
        title: 'Billing Type',
        accessor: headersAccessorMapping['Billing Type'],
        showFor: new Set(['T6']),
    },
    {
        title: 'Subscription Credit',
        accessor: headersAccessorMapping['Subscription Credit'],
        showFor: new Set(['T6']),
    },
    {
        title: 'No. of Installment',
        accessor: headersAccessorMapping['No. of Installment'],
        width: 80,
        integer: true,
        showFor: new Set(['T6']),
    },
    {
        title: 'Qty',
        accessor: headersAccessorMapping['Qty'],
        width: 50,
        integer: true,
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Qty',
        accessor: headersAccessorMapping['Qty (T2)'],
        width: 50,
        integer: true,
        showFor: new Set(['T2'])
    },
    {
        title: 'Service Type',
        accessor: headersAccessorMapping['Service Type'],
        showFor: new Set(['T2'])
    },
    {
        title: 'Service Part Code',
        accessor: headersAccessorMapping['Service Part Code'],  
        showFor: new Set(['T2'])
    },
    {
        title: 'UOM',
        accessor: headersAccessorMapping['UOM'],
        width: 70,
        showFor: new Set(['T6'])
    },
    {
        title: 'Service Duration',
        accessor: headersAccessorMapping['Service Duration'],
        dontShowFor: new Set(['T6','T2'])
    },
    {
        title: 'List Price (Rs)',
        accessor: headersAccessorMapping['List Price (Rs)'],
        currency: true,
        showFor: new Set(['T1'])
    },
    {
        title: 'List Price (Rs)',
        accessor: headersAccessorMapping['T2:List Price (Rs)'],
        currency: true,
        showFor: new Set(['T2'])
    },
    
    {
        title: 'Start Date',
        accessor: headersAccessorMapping['Start Date'],
        dateTime: true,
        showFor: new Set(['T2'])
    },
    {
        title: 'End Date',
        accessor: headersAccessorMapping['End Date'],
        dateTime: true,
        showFor: new Set(['T2'])
    },
    {
        title: 'Cisco Discount (%)',
        accessor: headersAccessorMapping['Cisco Discount (%)'],
        editable: true,
        id: 'edit-1',
        width: 100,
    },
    {
        title: 'conditionHeader',
        accessor: headersAccessorMapping['conditionHeader'],
        conditionHeader: true,
        editable: true,
        id: 'edit-2',
        width: 100,
    },  
    {
        title: 'Quoted Contract Value (Rs)',
        accessor: headersAccessorMapping['Quoted Contract Value (Rs)'],
        showFor: new Set(['T6']),
        editable: true,
        width: 100,
        id: 'contract_total_value',
    },
    {
        title: 'Extended List (Rs)',
        accessor: headersAccessorMapping['Extended List (Rs)'],
        currency: true,
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Pricing Term (months)',
        accessor: headersAccessorMapping['Pricing Term (months)'],
        width: 90,
        showFor: new Set(['T6']),
    },
    {
        title: 'Quoted Pricing Term Rate',
        accessor: headersAccessorMapping['Quoted Pricing Term Rate'],
        currency: true,
        showFor: new Set(['T6'])
    },
  
    {
        title: 'Major/Minor Part Category',
        accessor: headersAccessorMapping['Major/Minor Part Category'],
        dontShowFor: new Set(['T6','T2'])
    },
    {
        title: 'Major/Minor Part Category',
        accessor: 'bundle_major_minor',
        showFor: new Set(['T2'])
    },
    {
        title: 'Hold Item',
        accessor: headersAccessorMapping['Hold Item'],
        showFor: new Set(['T2']),
        boolean: true,
    },
    {
        title: 'Freight Amount',
        accessor: headersAccessorMapping['Freight Amount'],
        currency: true,
        dontShowFor: new Set(['T6','T2'])
    },
    {
        title: 'Duty Amount',
        accessor: headersAccessorMapping['Duty Amount'],
        currency: true,
        dontShowFor: new Set(['T6','T2'])
    },
    {
        title: 'HSN Code',
        accessor: headersAccessorMapping['HSN Code'],
        dontShowFor: new Set(['T6','T2'])
    },    
];

// Screen 3 = Estimation Calculation Details
const calculationDetailsColumns = [
    {
        title: 'Estimate Number',
        accessor: headersAccessorMapping['Estimate Number'],
        show: false,
    },
    {
        title: 'Line No.',
        accessor: headersAccessorMapping['Line No.'],
        width: 70,
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Part Number',
        accessor: 'part_number',
        dontShowFor: new Set(['T2'])
    },
    {
        title: 'Part Code',
        accessor: headersAccessorMapping['Part Code'],
        showFor: new Set(['T2'])
    },
    {
        title: 'Part Description',
        accessor: headersAccessorMapping['Part Description'],
        showFor: new Set(['T2']),
        width: 200
    },
    {
        title: 'Serial Number',
        accessor: headersAccessorMapping['Serial Number'],
        showFor: new Set(['T2']),
    },
    {
        title: 'Instance Number',
        accessor: headersAccessorMapping['Instance Number'],
        showFor: new Set(['T2']),
    },
    {
        title: 'HSN/SAC Code',
        accessor: headersAccessorMapping['HSN/SAC Code'],
        showFor: new Set(['T6'])
    },
    {
        title: 'Initial Term (months)',
        accessor: headersAccessorMapping['Initial Term (months)'],
        width: 80,
        showFor: new Set(['T6']),
    },
    {
        title: 'Billing Type',
        accessor: headersAccessorMapping['Billing Type'],
        showFor: new Set(['T6']),
    },
    {
        title: 'Subscription Credit',
        accessor: headersAccessorMapping['Subscription Credit'],
        showFor: new Set(['T6']),
    },
    {
        title: 'Support Part Code',
        accessor: headersAccessorMapping['Support Part Code'],  
        showFor: new Set(['T2'])
    },
    {
        title: 'Service Level',
        accessor: headersAccessorMapping['Service Level'],
        showFor: new Set(['T2'])
    },
    {
        title: 'Qty',
        // accessor: 'pro_quantity',
        accessor: 'quantity',
        width: 50,
        // integer: true,
    },
    {
        title: 'Pricing Term (months)',
        accessor: headersAccessorMapping['Pricing Term (months)'],
        width: 90,
        showFor: new Set(['T6']),
    },
    {
        title: 'Discount (%)',
        accessor: headersAccessorMapping['Discount (%)'],
        dontShowFor: new Set(['T6','T2'])
    },
    {
        title: 'Mark-up (%)',
        accessor: headersAccessorMapping['Mark-up (%)'],
        dontShowFor: new Set(['T6','T2']),
        percentageFixed: true
    },
    {
        title: 'Quoted Pricing Term Unit Price',
        accessor: headersAccessorMapping['Quoted Pricing Term Unit Price'],
        currency: true,
        showFor: new Set(['T6'])
    },
    {
        title: 'Installment Value (Rs)',
        accessor: headersAccessorMapping['Installment Value (Rs)'],
        currency: true,
        showFor: new Set(['T6'])
    },
    {
        title: 'Contract Total Value (Rs)',
        accessor: headersAccessorMapping['Contract Total Value (Rs)'],
        currency: true,
        showFor: new Set(['T6'])
    },
    // {
    //     title: 'Quoted Rate (Rs)',
    //     accessor: headersAccessorMapping['Quoted Rate (Rs)'],
    //     currency: true,
    //     dontShowFor: new Set(['T6'])
    // },
    // {
    //     title: 'Quoted Total (Rs)',
    //     accessor: headersAccessorMapping['Quoted Total (Rs)'],
    //     currency: true,
    //     dontShowFor: new Set(['T6'])
    // },
    {
        title: 'GST Code',
        accessor: headersAccessorMapping['GST Code'],
        dontShowFor:  new Set(['T6']),
        width: 80
    },
    {
        title: 'SGT (Rs)',
        accessor: headersAccessorMapping['SGT (Rs)'],
        currency: true,
    },
    {
        title: 'CGST (Rs)',
        accessor: headersAccessorMapping['CGST (Rs)'],
        currency: true,
    },
    {
        title: 'IGST (Rs)',
        accessor: headersAccessorMapping['IGST (Rs)'],
        currency: true,
    }
]



export { viewProductColumns, calculationDetailsColumns };

