import React,{Component} from 'react';
import SideNavBar from '../SideNavBar';
import CashFlow from '../../containers/CashFlow';

/**
 * This component is used to make a view for purchase order page view.
 * @ignore
 */
class CashFlowView extends Component{
    render(){
        return(
            <div className="purchase-order-view container-fluid">
                <CashFlow/>
            </div>
        );
    }
}

export default CashFlowView;