import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Toolbar
} from '@material-ui/core';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import { USER_TYPE } from 'globalConstants';
// Content to Render
import UploadExcel from 'pmcSystem/UploadExcel';
import ViewList from 'pmcSystem/ViewList';
// Context
import { PMCProvider } from 'pmcSystem/PMCContext';

const drawerWidth = 160;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    // If table too big. Enabling horizontal scroll.
    overflow: 'auto'
  },
}));
  
const PMCView = ({ content }) => {
  let history = useHistory();
  const classes = useStyles();
  // State for managing notification.
  const [notif, setNotif] = useState({
    info: false,
    warning: false,
    error: false,
    success: false,
    message: '',
  });

  // useEffects
  React.useEffect(()=>{
    if(content === 'upload') {
      const access = checkAccess();
      if(!access) {
        onClickNavigation('/pmc/view');
      };
    }
  }, [content]);

  // Utility function for checking access to upload view
  // and sets relevant state for notification to display message.
  const checkAccess = () => {
    if(sessionStorage.getItem('usertype') === USER_TYPE.PMC_USER && content === 'upload'){
      setNotif({
        info: false,
        error: false,
        success: false,
        warning: true,
        message: 'Access denied. Only admin is allowed.'
      }); 
      return false;
    }
    return true;
  };
  const navigationItems = [
    {
      id: 1,
      title: 'Upload Excel',
      redirect: '/pmc/upload',
      // icon: <>,
    },
    {
      id: 2,
      title: 'View',
      redirect: '/pmc/view'
      // icon: <>,
    }
  ];
  const onClickNavigation = (redirect_to) => {
    // If user type is PMC user. Deny access to upload page.
    if(sessionStorage.getItem('usertype')===USER_TYPE.PMC_USER && redirect_to==='pmc/upload'){
      setNotif({
        info: false,
        error: false,
        success: false,
        warning: true,
        message: 'Access denied. Only admin is allowed.'
      });
    } else {
      history.push(redirect_to);
    }
  };
  
  // Resetting notification state.
  const resetNotif = () => {
    setNotif({
      warning: false,
      error: false,
      success: false,
      message: ''
    });
  };
  return (
    <PMCProvider>
    <CustomizedSnackbars 
      Open={notif.warning} 
      Variant="warning" 
      Message={notif.message} 
      CloseCallback={resetNotif} 
    />
    <Header />
    <div className={classes.root}>
      <CssBaseline/>
             
      <main className={classes.content}>
        { content==="upload" && sessionStorage.getItem('usertype')===USER_TYPE.PMC_ADMIN && <UploadExcel/> }
        { content==="view" && <ViewList/> }
      </main>
    </div>
    </PMCProvider>
  );
};

const StyledListItem = styled(({ active, ...other }) => <ListItem {...other} />)({
  backgroundColor: (props) => props.active ? '#bfc9ff' : '',
  '&:hover': {
    backgroundColor: '#9ba3cf'
  }
});

PMCView.propTypes = {
  content: PropTypes.oneOf(["upload", "view"]).isRequired
};

export default PMCView;