import React,{Component} from 'react';
import {CircularProgressbarWithChildren,buildStyles} from 'react-circular-progressbar';
import AnimatedProgressProvider from "assets/AnimatedProgressProvider/AnimatedProgressProvider";
import {easeQuadInOut} from "d3-ease";
import "react-circular-progressbar/dist/styles.css";
import 'css/pages/dashboard.css';
import {withRouter} from 'react-router';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';

import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
const BASE_URI='/pro';
class CountBar extends Component{
    constructor(props){
        super(props);
        this.state={
            count:0,
            text:'',
        };
    }
    componentDidUpdate(prevProps){
        if(this.props.Count!==prevProps.Count){
            this.setState({
                count:this.props.Count,
                text:this.props.Text,
            });
        }
    }
    render(){
        return (
            <div className="count-bar">
                <h4 style={{textAlign:"center",color:"#c2bd32"}}>{this.state.text}</h4>
                {
                    this.props.InProgress
                    ?
                    <CircularIndeterminate Type="Dashboard"/>
                    :
                    <AnimatedProgressProvider
                        valueStart={0}
                        valueEnd={this.state.count}
                        duration={1.5}
                        easingFunction={easeQuadInOut}
                    >
                        {value=>{
                            const roundedValue=Math.round(value);
                            return (
                                <CircularProgressbarWithChildren
                                    value={value}
                                    text={`${roundedValue}`}
                                    // text={'<div>Hello</div>'}
                                    styles={buildStyles({
                                        pathTransition:"none",
                                        pathColor: "#ede615",
                                        textColor: "#c2bd32",
                                        // textSize:"0.6em",
                                    })}
                                >
                                <button
                                type="button"
                                className="pending-button"
                                onClick={this.props.handleClick}
                                />
                                </CircularProgressbarWithChildren>
                            );
                        }}
                    </AnimatedProgressProvider>
                }
            </div>
        );
    }
}

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state={
            username:sessionStorage.getItem('username'),
            token:sessionStorage.getItem('token'),
            data:[],
            updatedDashboard:false,
            getCountInProgress:false,
            responseStatus:0,
        }
        this.getData=this.getData.bind(this);
        this.updateDashboard=this.updateDashboard.bind(this);
    }
    updateDashboard(){
        this.setState({
            updatedDashboard:true,
        });
    }
    getData(){
        let responseStatus=0;
        this.setState({getCountInProgress:true});
        fetch(`${BASE_URI}/pendingCountApi/`,{
            method:"GET",
            headers:{
                Authorization:`Token ${this.state.token}`,
            }
        })
        .then(response=>{
            responseStatus=response.status;
            if(responseStatus===204||responseStatus===404||responseStatus===500){
                return [{}];
            }
            return response.json();
        })
        .then(responseJSON=>{
            if(responseStatus===204||responseStatus===404||responseStatus===500){
                this.setState({data:[],responseStatus,getCountInProgress:false});
            }
            this.setState({data:responseJSON,responseStatus,getCountInProgress:false});
        });
    }
    componentDidMount(){
        this.getData();
    }
    render(){
        console.log(this.state.data);
        
        return (
            <div className="dashboard container-fluid margindashbord">
                {this.state.responseStatus===500?<CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true}/>:null}
                {this.state.responseStatus===404?<CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true}/>:null}
                <div className="row info-text">
                    <h3 className="info-text">Welcome {this.state.username}!</h3>
                </div>
                <hr/>
                <div className="row button-row">
                    <div className="col-md-4">
                        <CountBar
                        // handleClick={()=>{this.props.history.push("/BSO")}}
                        Text="Pending BSO"
                        Count={this.state.data.pendingBso}
                        InProgress={this.state.getCountInProgress}
                        // Update={this.updateDashboard}
                        />
                    </div>
                    <div className="col-md-4">
                        <CountBar
                        // handleClick={()=>{
                        //     this.props.history.push("/SBSO");
                        // }}
                        Text="Pending SBSO"
                        Count={this.state.data.pendingSbso}
                        InProgress={this.state.getCountInProgress}
                        // Update={this.updateDashboard}
                        />
                    </div>
                    <div className="col-md-4">
                        <CountBar
                        handleClick={()=>{
                            this.props.history.push("/PO");
                        }}
                        Text="Pending PO"
                        Count={this.state.data.pendingPo}
                        InProgress={this.state.getCountInProgress}
                        // Update={this.updateDashboard}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);
