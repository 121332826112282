import * as FileSaver from "file-saver";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";

const templateColumnsMap = {
    'Product Number': 'pro_name',
    // 'Product Description': 'pro_description',
    'PAK/Serial Number': 'serialNumber',
    'Instance Number (Major/Minor Line Id)' : 'instanceNumber',
    'Major/Minor (MA/MI)': 'bundle_major_minor',
    'Parent Instance Number': 'parent_instance_id',
    'Service Level': 'serv_name',
    // 'Service SKU': 'sku',
    'Site Id': 'tar_location',
    'Part Category (L/S)': 'part_category',
    'Currency': 'pricing_currencyCode',
    // 'Unit List Price': 'pricing_listPrice',
    'Start Date': 'startDateActive',
    'End Date': 'endDateActive',
    // 'EOS': 'pro_last_date_of_support',
    'Quantity EOM': 'quantity_eom',
    'Quantity': 'pro_quantity',
    'Mark-up (%)': 'markup_per_line',
    'Other Costs (%)': 'othercosts_per_line',
    'Cisco Discount (%)': 'discount_per_line'
}


const defaultRowsBeforeData = ['Template for adding Coverage Restriction Items to CRM Quotation (Renewal Type = R)'];

const exportFile = async(
    rowsBeforeData=defaultRowsBeforeData, 
    skipRows=2,
    columnsHeaders=templateColumnsMap,
    data=[],
    fileName='template',
    fileExtension='.xlsx'
) => {
    try {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet("Data");

        // Add rows before data rows. (Any metadata etc. rows are added here)
        rowsBeforeData.forEach(r=>ws.addRow([r]).commit());
        
        // Add empty rows if any gaps between metadata rows and data rows.
        for(let i=0; i<skipRows; i++) {
            ws.addRow({}).commit();
        }
        var exportColumns = [];
        var headersList = [];

        for (const [key, value] of Object.entries(templateColumnsMap)) {
            headersList.push(key);
            let newVal = {
                Header: key,
                key: value
            };
            exportColumns.push(newVal);
        }
console.log(headersList)
console.log(exportColumns)
        ws.columns = exportColumns;
        let headersRow = ws.addRow(headersList);
        headersRow._cells.forEach(cell=>{
            cell.style={
                font: {
                    bold: true
                },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'B4C7E7' }
                },
                border: {
                    top: { style: 'thin'},
                    left: { style: 'thin'},
                    bottom: { style: 'thin'},
                    right: { style: 'thin'}
                }
            }
        });

        const buffer = await wb.xlsx.writeBuffer();
        const fileData = new Blob([buffer]);
        FileSaver.saveAs(fileData, fileName + fileExtension);
    } catch(err) {
        console.log(err);
    }
}
export { templateColumnsMap };
export default exportFile;