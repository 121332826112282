import React from 'react';

const Comments=(props)=>{
    // var commentCount = 0;
    // useEffect(()=>{
    // },[props]);
    // const commentCount = props.CommentCount;
    return (
        <div>
            {
                props.Data!==null
                ?
                props.Data.length===0?<div>No comments found.</div>:
                <div>
                {
                    props.HideExtraComments
                    ?
                    <div>
                        {
                            props.Data.slice(0,5).map((comment,index)=>{
                                // commentCount=commentCount+1;
                                return (
                                    <div key={index+1}>
                                        {
                                            comment.Comment+" - "+comment["Employee Name"]
                                        }
                                    </div>
                                );
                            })
                        }
                        {
                            props.Data.length>5
                            ?
                            <button type="button" style={{paddingLeft:0}} onClick={()=>props.ExtraCommentsHandler(props.HideExtraComments)} className="btn btn-link">view more.</button>
                            :null
                        }
                    </div>
                    :
                    <div>
                    {
                        props.Data.map((comment, index)=>{
                        // commentCount=commentCount+1;
                        return (
                            <div key={index+1}>
                                {
                                    comment.Comment+" - "+comment["Employee Name"]
                                }
                                </div>
                            );
                        })
                    }
                    <button type="button" style={{paddingLeft:0}} onClick={()=>this.extraComments(props.HideExtraComments)} className="btn btn-link">view less.</button>
                    </div>
                }
                </div>
                :
                null
            }
        </div>
    );
}

export default Comments;