import React from 'react';
import EditableCell from 'pmcSystem/Table/EditableCell';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';
// Formats currency columns to INR based commas.
var indianCurrencyFormatter = new Intl.NumberFormat('en-IN');


// Utility for preparing columns for table. (React-Table v7)
const prepareColumns = (columnDetails)=> {
    // Takes in column details given by user and converts it to 
    // column structure required by React-Table v7.
    let retVal = {
        Header: <span>{columnDetails.title}</span>,
        accessor: columnDetails.accessor
    };
    // If custom cell component provided use it.
    if(columnDetails?.cell) retVal.Cell = columnDetails.cell;

    // Use custom width if provided.
    if(columnDetails?.width) retVal.width = columnDetails.width;

    // Use currency non-editable cell if column has currency values & is not editable.
    if(columnDetails?.currency) 
        retVal.Cell = ({ value }) => (
            <CellSpan alignment={columnDetails?.alignment}>
                {indianCurrencyFormatter.format(parseFloat(value))}
            </CellSpan>
        )

    // If column editable. (Optional: if editable and also currency).
    if(columnDetails?.editable) {
        retVal.Cell = props => <EditableCell type={columnDetails?.currency?'number':''} {...props}/>;
        retVal.Header = 
        <span>
            {columnDetails.title}
            <div>
                <StyledTooltip arrow title="Editable field">
                    <StyledEditIcon />
                </StyledTooltip>
            </div>
        </span>;
    }

    // Add total in column header if showTotal = true.
    if(columnDetails?.showTotal) {
        retVal.Header = info => {
            const total = info.rows.reduce((sum,row)=> Number(row.values[columnDetails.accessor])+sum,0);
            return (
                <>
                    <div>
                       {columnDetails.title}
                    </div>
                    <div>
                        <TotalSpan><em>(Total: {indianCurrencyFormatter.format(total)})</em></TotalSpan>
                    </div>
                </>
            );
        }
    }
    // Add default filter (search) for column if filter has value.
    if(columnDetails?.filter) retVal.filter = columnDetails.filter;
    if(columnDetails?.width) retVal.width = columnDetails.width;
    return retVal;
};

const CellSpan = styled.span`
    text-align: ${props=>props?.alignment?props.alignment:''};
`;

const TotalSpan = styled.span`
    font-size: 0.6rem;
    color: grey;
`;

const StyledEditIcon = styled(EditIcon)`
    color: grey;
    vertical-align: middle;
    font-size: 0.9rem;
    margin: 0.5rem;
`;

const StyledTooltip = styled(Tooltip)`
    & .MuiTooltip-tooltip {
        font-size: 1rem;
        color: pink;
        background-color: 'red';
    }
    & .MuiTooltip-arrow { 
        color: pink;
        background-color: pink;
    }
`;



export default prepareColumns;