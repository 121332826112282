import React, { Component } from 'react';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import * as XLSX from 'xlsx';

const BASE_URI="/pro";
class ImportExcel extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      processingFile: false,
      importSuccess:false,
      importFailed: false,
      responseStatus: 0,
      errorMessage: '',
    }
  }
  componentDidUpdate(prevProps,prevState){
    if(prevState.importFailed||prevState.importSuccess){
      setTimeout(()=>{window.location.reload();},3000);
    }
  }
  postImportData = async (parsedData) => {
    const response = await fetch(`${BASE_URI}/bulkUploadCashFlowApi/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(parsedData)
    });
    if (response.status === 200) {
      this.setState({ processingFile: false, importSuccess: true });
    } else if (response.status === 500) {
      const responseJSON = await response.json();
      let message = responseJSON['message'];
      message = message.replace('%d/%m/%Y', 'dd/mm/yyyy');
      this.setState({ processingFile: false, importFailed: true, responseStatus: response.status, errorMessage: message });
      this.props.Callback(false);
    } else {
      this.setState({ processingFile: false, importFailed: true, responseStatus: response.status });
    }
  }
  onClickImport = (event) => {
    event.preventDefault();
    var files = event.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const wb = XLSX.read(data, { type: 'array' ,cellDates:true,dateNF:'dd/mm/yyyy'});
      // const wb= XLSX.read(data,{type:'array'});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // const parsedData=XLSX.utils.sheet_to_json(ws);
      var parsedData = XLSX.utils.sheet_to_json(ws, { header: ["customer_id", "expected_date", "status", "comment"], raw: false });
      parsedData.splice(0, 1);
      this.setState({ processingFile: true });
      this.postImportData(parsedData);
    }.bind(this);
    reader.readAsArrayBuffer(f);
  }
  render() {
    return (
      <div style={{ display: "inline-flex" }}>
        <input style={{ display: "none" }} type="file" onChange={(e) => { this.onClickImport(e) }} ref={this.fileInputRef} />
        <button className="btn btn-primary btn-sm" disabled={this.state.importFailed} onClick={() => { this.fileInputRef.current.click() }}>
          Import
        </button>
        <BackdropLoader Open={this.state.processingFile} />
        {this.state.responseStatus === 500 ?
          <CustomizedSnackbars Variant="error" Message={"Check your input file. " + this.state.errorMessage} Open={true} /> : null}
        {this.state.responseStatus === 404 || this.state.responseStatus === 400 ||this.state.responseStatus===403
          ?
          <CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator or refresh and try again." Open={true} />
          : null
        }
        {this.state.success?
          <CustomizedSnackbars Variant="success" Message="Import successful." Open={true} EnableTimer={true} Timer={1500} />
          : null
        }
      </div>
    );
  }
}

export default ImportExcel;