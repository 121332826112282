import React,{Component} from 'react';
import createHistory from 'history/createBrowserHistory';
import {Link} from 'react-router-dom';
import '../css/sidenavbar.css'
import Button from '@material-ui/core/Button';


/**
 * This component is used for side navigation bar.
 */
class SideNavBar extends Component{ 
    state={selected:'dashboard'};
    componentDidMount(){
        const history=createHistory();
        const location=history.location;
        if(location.pathname==='/dashboard'){
            this.setState({selected:'dashboard'});
        }
        // else if(location.pathname==='/SBSO'){
        //     this.setState({selected:'SBSO'});
        // }else if(location.pathname==='/BSO'){
        //     this.setState({selected:'BSO'});
        // }
        else if(location.pathname==='/PO'){
            this.setState({selected:'PO'});
        }
    }
    setSelected=(selected)=>{
        this.setState({selected});
    }
    render(){
        return (
<>
   
            <Button >
                <span className={this.state.selected==="dashboard"?"sidenav-button selected":"sidenav-button"} onClick={()=>{
                    this.setSelected("dashboard");
                }}>
                    <div>
                        <Link className="menuLabel" to='/dashboard'>DASHBOARD</Link>
                    </div>
                </span>
            </Button >
            {/* <li>
                <span className={this.state.selected==="BSO"?"sidenav-button selected":"sidenav-button"}
                    onClick={()=>{
                        this.setSelected("BSO");
                    }}>
                    <div>
                        <Link className="menuLabel" to='/BSO'>BSO</Link>
                    </div>
                </span>
            </li>
            <li>
                <span className={this.state.selected==="SBSO"?"sidenav-button selected":"sidenav-button"}
                    onClick={()=>{
                        this.setSelected("SO");
                    }}>
                    <div>
                        <Link className="menuLabel" to="/SBSO">SBSO</Link>
                    </div>
                </span>
            </li> */}
            <Button >
                <span className={this.state.selected==="PO"?"sidenav-button selected":"sidenav-button"}
                    onClick={()=>{
                        this.setSelected("PO");
                    }}>
                    <div>
                        <Link className="menuLabel" to="/PO">PO</Link>
                    </div>
                </span>
            </Button >
    
</>
        );
    }
}
export default SideNavBar;