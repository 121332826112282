import React,{Component} from 'react';
import PurchaseOrderData from '../components/data/PurchaseOrderData.js';
import '../css/pages/purchaseorder.css';

class PurchaseOrder extends Component{
    render(){
        return(
            <div className="purchase-order">
                <PurchaseOrderData token={sessionStorage.getItem('token')}/>
            </div>
        );
    }
}

export default PurchaseOrder;