const dateTimeFormatting = (value) => {
    /**
    * Formats date to Indian Locale Date String, Indian Locale Time String.
    */
    if (value===undefined) return "-";
        
    const dateObj = new Date(value);

    const dateOptions = { year: 'numeric', month:'short', day:'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    const formattedDate = dateObj.toLocaleDateString('en-IN', dateOptions);
    const formattedTime = dateObj.toLocaleTimeString('en-IN', timeOptions);

    return { formattedDate, formattedTime };
};

export { dateTimeFormatting };