import React,{Component} from 'react';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';
import {withRouter} from 'react-router';
import loginRightIcon from 'assets/login-right-arrow.png';
import 'css/pages/login.css';

const BASE_URI='/pro';
class ForgotPassword extends Component{
  state={
    email:'',
    newPassword:'',
    confirmNewPassword:'',
    passwordChanged:false,
    otpProcessing:false,
    otpSent:false,
    otpVerified:false,
    otp:'',
    errorMessage:'',
    error:false,
    serverError:false,
  }

  componentDidUpdate(){
    if(this.state.passwordChanged){
      setTimeout(() => { 
        this.props.history.push("/");
      }, 3000);
    }
  };

  sendOTP = async() => {
    const response=await fetch(`${BASE_URI}/forget_password/`,{
      method:"POST",
      body:JSON.stringify({ email:this.state.email})
    });
    if(response.status!==200){
      this.setState({
        errorMessage: 'Account with email address does not exist. Please check.',
        error: true,
        serverError: false,
        otpProcessing: false
      });
    }else if(response.status===500){
      this.setState({ 
        serverError: true 
      });
    }else{
      this.setState({
        otpSent:true,
        otpProcessing:false,
        error:false,
        serverError:false,
      });
    }
  };

  verifyOTP = async() => {
    let emailId = this.state.email;
    const response = await fetch(`${BASE_URI}/otp_verification/`,{
      method:'POST',
      body:JSON.stringify({
        email: emailId,
        otp: this.state.otp
      })
    });
    if (response.status!==200) {
      this.setState({
        errorMessage: 'Incorrect OTP',
        error: true,
        serverError: false,
        otpSent: true,
        email: emailId
      });
    } else if (response.status===500) {
      this.setState({ serverError: true });
    } else {
      this.setState({ 
        otpVerified: true,
        otpProcessing: false,
        error: false,
        serverError: false,
        otpSent: false 
      });
    }
  };

  changePassword = async () => {
    const response = await fetch(`${BASE_URI}/change_password/`,{
      method:'POST',
      body:JSON.stringify({
        email: this.state.email,
        password: this.state.newPassword
      })
    });
    if (response.status !== 200) {
      this.setState({
        errorMessage: 'There was an internal error. Please try again.',
        error: true,
        serverError: false,
        otpVerified: true
      });
    } else if(response.status === 500) {
      this.setState({ serverError: true });
    } else {
      this.setState({
        passwordChanged: true, 
        serverError: false, 
        error: false
      });
    }
  };

  handleSendOTP = () => {
    this.setState({ 
      otpProcessing: true
    });
    this.sendOTP();
  };
  
  handleVerifyOTP = () => {
    this.verifyOTP();
  };

  handleChangePassword = () => {
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        error: true,
        errorMessage: "Passwords do not match."
      });
    } else {
      this.changePassword();
    }
  };

  onChangeEmail = (event) => {
    this.setState({email:event.target.value});
  };
  onChangeOTP = (event) => {
    this.setState({otp:event.target.value});
  };
  onChangeNewPassword = (event) => {
    this.setState({newPassword:event.target.value});
  };
  onChangeConfirmNewPassword = (event) => {
    this.setState({confirmNewPassword:event.target.value});
  };
  render () {
    return (
      <div>
      {this.state.serverError?<CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true} />:null}
      {this.state.passwordChanged?<CustomizedSnackbars Variant="info" Message="Password Changed." Open={true}/>:null}
      {this.state.otpSent?<CustomizedSnackbars Variant="info" Message="OTP Sent" Open={true} EnableTimer={true} Timer={1500}/>:null}
      {
        this.state.passwordChanged
        ?
        <div style={{paddingTop:"30vh"}}>
          <div className="justify-content-center mb-3">
            <p>Redirecting to Login</p>
          </div>
          <div className="form-row justify-content-center mb-3">
            <CircularIndeterminate Type="Login"/>
          </div>
        </div>
        :
        <div className="login-form">
          <div className="form-row justify-content-center mb-3">
            <h4 className="login-text">Reset Password</h4>
          </div>
          {
            this.state.error
            ?
            <div className="form-row justify-content-center">
              <p className="error-message">{this.state.errorMessage}</p>
            </div>
            :
            null
          }
          {
            this.state.otpSent
            ?
            <div className="form-row justify-content-center">
              <p style={{color:"#3f51b5"}}>Check email for OTP</p>
            </div>
            :
            null
          }
          <div className="form-row justify-content-center">
            <div className="form-group">
              {
                this.state.otpVerified
                ?
                <div>
                  <input name="newpassword" type="password" className="form-control" id="newpassword" placeholder="Password" value={this.state.newPassword} onChange={this.onChangeNewPassword} maxLength="20" required/>
                  <br/>
                  <input name="newpasswordconfirm" type="password" className="form-control" id="newpasswordconfirm" placeholder="Confirm Password" value={this.state.confirmNewPassword} onChange={this.onChangeConfirmNewPassword} maxLength="20" required/>
                </div>
                :
                <div>
                  {
                    this.state.otpSent
                    ?
                    <input name="otp" type="text" className="form-control" id="otp" placeholder="OTP" value={this.state.otp} onChange={this.onChangeOTP} maxLength="20" required/>
                     :
                    <input name="email" type="email" className="form-control" id="userEmailId" placeholder="Email Address" value={this.state.email} onChange={this.onChangeEmail} maxLength="30" required/>
                  }
                </div>
              }
            </div>
          </div>
          {
            this.state.otpVerified
            ?
            <div>
              {this.state.changePasswordProcessing
              ?
              <CircularIndeterminate Type="Login"/>
              :
              <button className="form-button btn btn-primary" onClick={this.handleChangePassword}>Change Password</button>
              }
            </div>
            :
            <div>
              {
                this.state.otpProcessing
                ?
                <CircularIndeterminate Type="Login"/>
                :
                <div className="form-row justify-content-center">
                  {
                  this.state.otpSent?
                  <div>
                    <div style={{paddingBottom:5}}>
                      <button className="form-button btn btn-primary" onClick={this.handleVerifyOTP}>Verify OTP</button>
                    </div>
                    <button className="form-button btn btn-primary" onClick={this.handleSendOTP}>Resend OTP</button>
                  </div>
                  :
                  <button className="form-button btn btn-primary" onClick={this.handleSendOTP}>Send OTP<img className="login-arrow ml-2" src={loginRightIcon} alt="right-icon"/></button>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    );
  }
}

export default withRouter(ForgotPassword);