import React,{Component} from 'react';
import SideNavBar from '../SideNavBar';
import Dashboard from '../../containers/Dashboard';


/**
 * This component is used to make a view for Dashboard page view.
 * @ignore
 */
class DashboardView extends Component{
    render(){
        return(
            <div className="container-fluid">
                <div className="row">
                    {/* <div className="col-md-2 col-4">
                        <SideNavBar/>
                    </div> */}
                    <div className="col-md-12 col-12">
                        <Dashboard/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardView;