import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const CommentModal=(props)=>{
  const [userInput,setUserInput]=React.useState('');
  const onChangeInputText=(e)=>{
    setUserInput(e.target.value);
  }
  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.Close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To approve, send for final approval, reject the purchase order comment is
            required.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter comment here"
            type="text"
            fullWidth
            onChange={onChangeInputText}
            inputProps={{
              // Due to database limit of storing characters.
              maxLength:"249"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.Close} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{props.CloseWithComment(userInput)}} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default CommentModal;