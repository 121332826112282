import React,{Component} from 'react';
import EstimateTable from 'quotationSystem/Table/EstimateTable/EstimateTable';
import 'css/views/loginview.css';
import SideNavBarQuotationSystem from 'quotationSystem/SideNavBarQuotationSystem';
import { EstimateTableProvider } from 'quotationSystem/EstimateTableContext';
/**
 * This component is used to make a view for Login page view.
 * @ignore
 */

class EstimateTableView extends Component{
    render(){
        return(
            <EstimateTableProvider>
                <div className="container-fluid">
                    {/* <SideNavBarQuotationSystem RenderComponent={<EstimateTable/>}/> */}
                    <EstimateTable/>
                    
                </div>
            </EstimateTableProvider>
        );
    }
}

export default EstimateTableView;