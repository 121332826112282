import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide,
  Grid,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#bfc9ff'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'black'
  },
  divider: {
    height: '1.5px',
    borderRadius: '100px',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubComponentDialog = ({ 
  open, 
  closeCallback, 
  perfMetricsComponent,
  renderComponent, 
  title,
  disableSubmit=false, 
  type=null, 
  handleSubmit=null, 
  handleView=null,
  handleExport=null
}) => {
  const classes = useStyles();
  return (
    <Dialog fullScreen open={open} onClose={closeCallback} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" onClick={closeCallback} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {
            // type==='ViewAll' &&
            <>  
              <ConfirmDialog 
                renderButton={(clickHandler) => 
                  <AppBarButton variant="contained" onClick={clickHandler}>
                    Export
                  </AppBarButton>
                }
                dialogTitle="Are you sure you want to export this data ?"
                confirmCallback={handleExport}
              />
            </>
          }
          {
            type==='Quarter' &&
            <>
              {/* <AppBarButton variant="contained" onClick={handleView}>
                View
              </AppBarButton> */}
              <AppBarButton variant="contained" onClick={handleSubmit} disabled={disableSubmit}>
                Submit
              </AppBarButton>
            </>
          }
        </Toolbar>
      </AppBar>
      <Grid container>
        {   
          type === 'Quarter' &&
          <>
            <GridWrapper item xs={12}>
              { perfMetricsComponent }
            </GridWrapper>
            <GridWrapper item xs={12}>
              <Divider variant="middle" className={classes.divider}/>
            </GridWrapper>
          </>
        }
        <GridWrapper item xs={12}>
          { renderComponent }
        </GridWrapper>
      </Grid>
    </Dialog>
  );
};

SubComponentDialog.propTypes = {
  /** This handles displaying of Dialog Component */
  open: PropTypes.bool.isRequired
  /**  */
};

const AppBarButton = styled(Button)`
  margin-right: 1rem;
  &:focus {
    outline: none;
  }
`;

const GridWrapper = styled(Grid)`
  width: 100%;
  margin-bottom: 1rem;
`;

export default SubComponentDialog;
