import React, { useEffect, useState, useContext } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "css/tabledata.css";
import BackdropLoader from "components/loaders/BackdropLoader";
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from "styled-components";
import {
  EstimateTableContext
} from "quotationSystem/EstimateTableContext";
import SubComponent from 'quotationSystem/Table/EstimateTable/SubComponent';
import fetchAPI from "fetchAPI";
import { QUOTATION_SYSTEM_ENDPOINTS } from "endpoints";

const BASE_URI = "/pro";
// const BASE_URI="";

const columnHeaders = [
  {
    Header: "Estimate ID",
    accessor: "estimate_id",
    headerClassName: "po-table-header",
    width: 150
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
    headerClassName: "po-table-header",
    width: 400
  },
  {
    Header: "Exported",
    accessor: 'is_estimate_exported',
    headerClassName: 'po-table-header',
    width: 100,
    Cell: props => (
      <div>
        {props.value ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <CloseIcon style={{ color: "red" }} />
        )}
      </div>
    )
  },
  {
    Header: "Quotation No.",
    accessor: 'version_number_new',
    headerClassName: 'po-table-header',
    width: 200
  },
  {
    Header: "Option Type",
    accessor: "option_type",
    headerClassName: "po-table-header",
    filterMethod: (filter, row) => {
      switch (filter.value) {
        case "1":
          return row["option_type"] === "1";
        case "2":
          return row["option_type"] === "2";
        case "3":
          return row["option_type"] === "3";
        case "4":
          return row["option_type"] === "4";
        case "5":
          return row["option_type"] === "5";
        default:
          return true;
      }
    }
  },
  {
    Header: "Estimate Type",
    accessor: "estimate_type",
    headerClassName: "po-table-header",
    filterMethod: (filter, row) => {
      if (filter.value === "default") return true;
      if (filter.value === "") return true;
      if (filter.value === "T1") return row["estimate_type"] === "T1";
      if (filter.value === "T2") return row["estimate_type"] === "T2";
      if (filter.value === "T3") return row["estimate_type"] === "T3";
      if (filter.value === "T4") return row["estimate_type"] === "T4";
      if (filter.value === "T5") return row["estimate_type"] === "T5";
      if (filter.value === "T6") return row["estimate_type"] === "T6";
    }
  },
  {
    Header: "Quotation No. (Old)",
    accessor: "version_number",
    headerClassName: "po-table-header",
    width: 100
  },
  {
    Header: "Billing Branch",
    accessor: "billing_branch",
    headerClassName: "po-table-header"
  },
  {
    Header: "Customer Type",
    accessor: "cust_type",
    headerClassName: "po-table-header"
    // Cell:props=><div>{props.value.toString().toLowerCase()==="y"?<CheckIcon style={{color:'green'}} />:<CloseIcon style={{color:'red'}}/>}</div>
  },
  {
    Header: "Goods FTWZ",
    accessor: "goods_ftwz",
    headerClassName: "po-table-header"
  },
  {
    Header: "Goods Zero Rated GST",
    accessor: "goods_zero_rated_gst",
    headerClassName: "po-table-header"
  },
  {
    Header: "Services Zero Rate",
    accessor: "services_zero_rate",
    headerClassName: "po-table-header"
    // Cell:props=><div>{props.value.toString().toLowerCase()==="y"?<CheckIcon style={{color:'green'}} />:<CloseIcon style={{color:'red'}}/>}</div>
  },
  {
    Header: "Interstate",
    accessor: "inter_state",
    headerClassName: "po-table-header",
    Cell: props => (
      <div>
        {props.value.toString().toLowerCase() === "y" ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <CloseIcon style={{ color: "red" }} />
        )}
      </div>
    )
  },
  {
    Header: "Product/Software Discount",
    accessor: "pro_soft_disc",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "Ser. Discount",
    accessor: "ser_disc",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "XAAS Discount",
    accessor: "xaas_disc",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "Product/Software Markup",
    accessor: "pro_soft_disc",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "Ser. Markup",
    accessor: "ser_markup",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "XAAS Markup",
    accessor: "xaas_disc",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "Other",
    accessor: "other",
    headerClassName: "po-table-header",
    Cell: props => (
      <span className="number">
        {(Math.round(props.value * 100) / 100).toLocaleFixed(2)} %
      </span>
    )
  },
  {
    Header: "Creation Date",
    accessor: "created_at",
    headerClassName: "po-table-header",
    Cell: props => (
      <div>
        {Intl.DateTimeFormat("en-IN", { dateStyle: "medium" }).format(
          new Date(props.value)
        )}
      </div>
    ),
    width: 170
  },
  {
    Header: "Last Modified",
    accessor: "modified_at",
    headerClassName: "po-table-header",
    Cell: props => (
      <div>
        {Intl.DateTimeFormat("en-IN", { dateStyle: "medium" }).format(
          new Date(props.value)
        )}
      </div>
    ),
    width: 170
  }
];

const EstimateTable = props => {
  const { estimateDataContext } = useContext(EstimateTableContext);
  const [estimateData, setEstimateData] = estimateDataContext;
  const [state, setState] = useState({
    tableData: [],
    filtered: [],
    filteredData: [],
    tableDataResponseCode: 0,
    loadingData: false,
  });
  // Table filters.
  const UniqueEstimationType = [...new Set(state.tableData.map(item => item.estimate_type))]
  UniqueEstimationType.sort((a, b) => (a > b) ? 1 : -1)
  const UniqueOptionType = [...new Set(state.tableData.map(item => item.option_type))]
  // console.log(UniqueOptionType)
  UniqueOptionType.sort((a, b) => (a > b) ? 1 : -1)
  // console.log(UniqueOptionType)

  const [filters, setFilters] = useState({
    search: '',
    optionType: '',
    estimateType: '',
  });
  // Error state.
  const [error, setError] = useState({
    status: false,
    statusCode: 0,
    msg: ''
  });

  // useEffects
  useEffect(() => {
    setState({ ...state, loadingData: true });
    getData();
  }, []);

  // Get table data.
  const getData = async () => {
    const response = await fetch(`${BASE_URI}/getEstimateDetails/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`
      }
    });
    if (response.status === 200) {
      const responseJSON = await response.json();
      setState({
        ...state,
        loadingData: false,
        tableData: responseJSON,
        tableDataResponseCode: response.status
      });
      setEstimateData({
        ...estimateData,
        refreshRequired: false
      });
    } else if (response.status === 404 || response.status === 500) {
      setState({
        ...state,
        loadingData: false,
        tableData: [],
        tableDataResponseCode: response.status
      });
    } else {
      setState({
        ...state,
        loadingData: false,
        tableData: [],
        tableDataResponseCode: 404
      });
    }
  }
  // Handles creation of a new version for estimate.
  const createNewVersion = async (id) => {
    setState({ ...state, loadingData: true });
    // API Request for backend to generate a new version and get new version id.
    const { responseData, statusCode, error } = await fetchAPI(`${QUOTATION_SYSTEM_ENDPOINTS.CREATE_NEW_ESTIMATE_VERSION}${id}`, 'GET');
    if (statusCode === 200 && !error) {
      setState({ ...state, loadingData: false });
      return responseData;
    } else {
      // Error handling here. 
      setError({
        status: true,
        statusCode,
        msg: 'There was a problem creating new version. Please try again.'
      });
    }
  }
  const refreshData = async () => {
    console.log("refreshData");
    console.log("Estimate Data Values: ", estimateData.refreshRequired);
    if (estimateData.refreshRequired) {
      setState({ ...state, loadingData: true });
      getData();
    }
  }
  // Filter Handlers
  // Select filters handler.
  const onSelectFilter = (filterType, value) => {
    console.log(filterType);
    console.log(value);
    if (filterType === 'optionType') {
      setFilters({
        ...filters,
        optionType: value
      });
      onFilteredChangeCustom(value, "option_type");
    }
    if (filterType === 'estimateType') {
      setFilters({
        ...filters,
        estimateType: value
      });
      onFilteredChangeCustom(value, "estimate_type");
    }
  }
  // Search filter handler.
  const onSearchFilter = (e) => {
    const value = e.target?.value;
    if (value === "" || value === null || value === undefined) {
      setState({ ...state, filteredData: [] });
      setFilters({ ...filters, search: "" });
    } else {
      let filteredData = state.tableData.filter(val => ((
        val["customer_name"].toLowerCase().includes(value.toLowerCase()) ||
        val["estimate_id"].toLowerCase().includes(value.toLowerCase())
      )
      ));
      setFilters({ ...filters, search: value });
      setState({ ...state, filteredData: filteredData });
    }
  }

  // Apply filters to table.
  const onFilteredChangeCustom = (value, accessor) => {
    let filtered = state.filtered.slice();
    console.log(filtered);
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;
          insertNewFilter = 0;
        }
      });
    }
    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    setState({
      ...state,
      filtered: filtered
    });
  }

  // Reset functions.
  // Reset filters.
  const resetFilters = () => {
    setFilters({
      search: "",
      estimateType: "",
      optionType: ""
    });
    setState({ ...state, filteredData: [], filtered: [] });
  }
  // Reset Error State.
  const resetErrorState = () => {
    setError({
      status: false,
      statusCode: 0,
      msg: ''
    });
  }

  return (
    <div className="estimationfirstlayer">
      <div className="estimationsecndlayer" style={{ padding: '10px', backgroundColor: ' #f0f2f5', margin: '2vh' }}>
        <React.Fragment>

          {state.tableDataResponseCode === 500 ? (
            <CustomizedSnackbars
              Variant="error"
              Message="Server Error. Please try refresh."
              Open={true}
            />
          ) : null}
          {state.tableDataResponseCode === 404 ? (
            <CustomizedSnackbars
              Variant="error"
              Message="Server Error. Please contact administrator."
              Open={true}
            />
          ) : null}
          {state.tableDataResponseCode === 204 ? (
            <CustomizedSnackbars
              Variant="info"
              Message="No data. There is no quotation data for your account."
              Open={true}
            />
          ) : null}
          <CustomizedSnackbars
            Variant="error"
            Message={error.msg}
            Open={error.status}
            CloseCallback={resetErrorState}
          />
          <BackdropLoader Open={state.loadingData} />
          {/* Table Filters */}
          <Grid container>
            {/* Search Filter */}
            <Grid container item xs={3} justifyContent="flex-start" alignItems="center">
              <SearchInput
                type="text"
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={filters.search}
                onChange={onSearchFilter}
                InputProps={{
                  // classes: {{
                  //   backgroundColor: 'red'
                  // }},
                  margin: 'dense',
                  startAdornment: (
                    <InputAdornment position="start" >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="clear search filter"
                      onClick={() => onSearchFilter("")}
                      style={{ height: "0.2em", width: "0.2em", margin: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            {/* Estimate Type Filter */}

            <Grid container item xs={1} justifyContent="flex-start" alignItems="center">
              <Select value={filters.estimateType} onChange={(e) => onSelectFilter("estimateType", e.target.value)} >
                <option value="">Estimate</option>
                {console.log(UniqueEstimationType)}
                {UniqueEstimationType.map((listItem) => (

                  <option value={listItem}>{listItem}</option>
                ))}
                {/* <option value="T1">T1</option>
                <option value="T5">T5</option>
                <option value="T6">T6</option> */}
              </Select>
            </Grid>
            <Grid container item xs={1} justifyContent="flex-start" alignItems="center">
              {/* Option Type Filter */}
              <Select value={filters.optionType} onChange={(e) => onSelectFilter("optionType", e.target.value)}>
                <option value="">Option</option>
                {UniqueOptionType.map((listItem) => (
                  <option value={listItem}>{listItem}</option>
                ))}
                {/* <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option> */}
              </Select>
            </Grid>
            <Grid container item xs={6} />
            <Grid container item xs={1} justifyContent="flex-start" alignItems="center">
              <StyledButton type="button" onClick={resetFilters}>Clear All</StyledButton>
            </Grid>
          </Grid>
          <ReactTable
            className="-highlight table-style"
            style={{ overflow: "wrap", width: "100%", height: "76vh", backgroundColor: 'white' }}
            previousText="<"
            nextText=">"
            defaultPageSize={10}
            resizable={false}
            data={filters.search === "" ? state.tableData : state.filteredData}
            showPageJump={false}
            columns={columnHeaders}
            SubComponent={row =>
              <SubComponent
                RowData={row.original}
                onCreateVersion={createNewVersion}
                refreshData={refreshData}
              />
            }
            filtered={state.filtered}
            onFilteredChange={(filtered, column, value) => {
              onFilteredChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (typeof filter.value === "object") {
                return row[id] !== undefined
                  ? filter.value.indexOf(row[id]) > -1
                  : true;
              } else {
                return row[id] !== undefined
                  ? String(row[id]).indexOf(filter.value) > -1
                  : true;
              }
            }}
            getTdProps={() => {
              return {
                style: { textAlign: "center", paddingTop: '3px', paddingBottom: '2px', }
              };
            }}
            getTheadProps={() => {
              return {
                style: { background: "#2f2655", color: "whitesmoke" }
              };
            }}
            getTbodyProps={() => {
              return {
                style: {
                  overflowX: "hidden",
                }
              };
            }}
            getTrProps={(state, rowInfo, col, instance) => {
              return {
                onClick: e => {
                  const { expanded } = state;
                  const path = rowInfo.nestingPath[0];
                  const diff = { [path]: expanded[path] ? false : true };
                  instance.setState({
                    expanded: {
                      expanded,
                      ...diff
                    }
                  });
                },
                style: {
                  cursor: "pointer"
                }
              };
            }}
          />
        </React.Fragment>
      </div>
    </div>
  );
};


const Select = styled.select`
  background-color: white;
  margin-right: 5px;
  border-color: #D3D3D3;
  border-width: 0.5px;
  border-radius: 10px;
  height: 75%;
  width: 100%;
`;

const StyledButton = styled.button`
  width: 90%;
  border-width: 0.5px;
  border-radius: 10px;
  height:75%;
  background-color: white;
  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

const SearchInput = withStyles({
  root: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    '& .MuiOutlinedInput-root': {
      height: "75%",
      marginLeft: '5px',
      marginTop: '1px',
      marginBottom: '1px',
      marginRight: '5px',
      borderRadius: '10px',
      '&. Mui-focused fieldset': {
        borderWidth: '0.5px',
        borderColor: 'black',
      },
    },
  },
})(TextField);

export default EstimateTable;
