import React, { useMemo, useState, useContext } from 'react';
import styled from 'styled-components';
import { Grid, Button, Box } from '@material-ui/core';
import { Table } from 'pmcSystem/Table';
import UploadButton from 'pmcSystem/UploadButton';
import { PMCContext } from 'pmcSystem/PMCContext';

import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import ConfirmDialog from 'components/ConfirmDialog';
import BackdropLoader from 'components/loaders/BackdropLoader';
import fetchAPI from 'fetchAPI';
import { PMC_ENDPOINTS } from 'endpoints';

const headersDetails = [
  {
    id: 1,
    fileTitle: 'D_Bill-To ID',
    tableTitle: 'D_Bill-To ID',
    accessor: 'd_bill_to_id',
  },
  {
    id: 4,
    fileTitle: 'D_Install-Site Site Use ID',
    tableTitle: 'D_Install-Site Site Use ID',
    accessor: 'd_install_site_site_use_id',
  },
  // {
  //     fileTitle: 'D_Item Name',
  //     tableTitle: 'D_Item Name',
  //     accessor: 'd_item_name'
  // },
  // {
  //     fileTitle: 'D_Serial Number',
  //     tableTitle: 'D_Serial Number',
  //     accessor: 'd_serial_number'
  // },
  // {
  //     fileTitle: 'D_Instance ID',
  //     tableTitle: 'D_Instance ID',
  //     accessor: 'd_instance_id'
  // },
  // {
  //     fileTitle: 'D_End of Support Date',
  //     tableTitle: 'D_End of Support Date',
  //     accessor: 'd_end_of_support_date'
  // },
  // {
  //     fileTitle: 'D_Service Level',
  //     tableTitle: 'D_Service Level',
  //     accessor: 'd_service_level'
  // },
  // {
  //     fileTitle: 'D_Item Begin Date',
  //     tableTitle: 'D_Item Begin Date',
  //     accessor: 'd_item_begin_date'
  // },
  // {
  //     fileTitle: 'D_Item End Date',
  //     tableTitle: 'D_Item End Date',
  //     accessor: 'd_item_end_date'
  // },
  // {
  //     fileTitle: 'Normalized Service Level',
  //     tableTitle: 'Normalized Service Level',
  //     accessor: 'normalized_service_level'
  // },
  {
    fileTitle: 'Renewal Status',
    tableTitle: 'Renewal Status',
    accessor: 'renewal_status'
  },
  // {
  //     fileTitle: 'Opportunity Month',
  //     tableTitle: 'Opportunity Month',
  //     accessor: 'opportunity_month'
  // },
  {
    id: 8,
    fileTitle: 'Opportunity Quarter',
    tableTitle: 'Opportunity Quarter',
    accessor: 'opportunity_quarter',
  },
  {
    id: 9,
    fileTitle: 'Quantity',
    tableTitle: 'Quantity',
    accessor: 'quantity',
  },
  {
    id: 10,
    fileTitle: 'D_Opportunity',
    tableTitle: 'D_Opportunity',
    accessor: 'd_opportunity',
  },
  {
    id: 11,
    fileTitle: 'N_Renewed',
    tableTitle: 'N_Renewed',
    accessor: 'n_renewed',
  },
  {
    id: 12,
    fileTitle: 'Adjusted Opportunity',
    tableTitle: 'Adjusted Opportunity',
    accessor: 'adjusted_opportunity',
  },
  {
    id: 13,
    fileTitle: 'Adjusted Renewed',
    tableTitle: 'Adjusted Renewed',
    accessor: 'adjusted_renewed',
  },
  {
    id: 5,
    fileTitle: 'D_Install-Site Name',
    tableTitle: 'D_Install-Site Name',
    accessor: 'd_install_site_name',
  },
  {
    id: 2,
    fileTitle: 'D_Install-Site Global ID',
    tableTitle: 'D_Install-Site Global ID',
    accessor: 'd_install_site_global_id',
  },
  {
    id: 3,
    fileTitle: 'D_Install-Site Global Name',
    tableTitle: 'D_Install-Site Global Name',
    accessor: 'd_install_site_global_name',
  },
  {
    id: 6,
    fileTitle: 'D_Install-Site End Customer Number',
    tableTitle: 'D_Install-Site End Customer Number',
    accessor: 'd_install_site_end_customer_number',
  },
  {
    id: 7,
    fileTitle: 'D_Install-Site End Customer Name',
    tableTitle: 'D_Install-Site End Customer Name',
    accessor: 'd_install_site_end_customer_name',
  },
  {
    id: 14,
    fileTitle: 'Adjusted Include in Calcs',
    tableTitle: 'Adjusted Include in Calcs',
    accessor: 'adjusted_include_in_calcs',
  }
];

const metadataHeadersMapping = {
  'data_extract_date': 'Data Extract Date',
  'report_name': 'Report Name',
  'report_description': 'Report Description'
};

const UploadExcel = () => {
  // Get context & destructure it for use.
  const { uploadExcel } = useContext(PMCContext);
  const [uploadData, setUploadData] = uploadExcel;
  // const [readData, setReadData] = useState({
  //     fileData:[],
  //     metadata:[]
  // });
  const [uploadingFile, setUploadingFile] = useState({
    uploading: false,
    error: false,
    success: false
  });

  // Loader state.
  const [openLoader, setOpenLoader] = useState(false);

  // Error states.
  const [error, setError] = useState({
    status: false,
    message: ''
  });

  // Constants.
  const tableColumns = useMemo(
    () => headersDetails.map(item=>({
      Header: item.tableTitle,
      accessor: item.accessor
    })),
    []
  );

  // File information.
  const fileInfo = {
    // From where the headers start.
    headersStartRow: 14,
    // This is starting from 1 to metadataRows value.
    metadataRows: 3,
  };

  // Process uploaded excel data.
  const processUploadData = async(data)=>{
    const fileData = data.fileData;
    const metadata = data.metadata;
    let processedData = [];
    fileData.forEach(row=>{
      let temp={};
      headersDetails.forEach(header=>{
        temp[header.accessor] = row[header.fileTitle]
      });
      processedData.push(temp);
    });
    setOpenLoader(false);
    setUploadData({
      fileData: processedData,
      metadata: metadata
    });
  };

  // Send uploaded file data to backend.
  const submitUploadData = async()=>{
    setOpenLoader(true);
    const currentDate = new Date(Date.now());
    // Format is : MM/DD/YYYY 00:00:00.
    const date = `${currentDate.getMonth()+1}/${currentDate.getDate()}/${currentDate.getFullYear()} 00:00:00`;
    const bodyData = {
      'header_data': {
        // 'data_extract_date': uploadData.metadata[metadataHeadersMapping['data_extract_date']],
        // 'data_extract_date': '07/19/2021 00:00:00',
        'data_extract_date': date,
        'report_name': uploadData.metadata[metadataHeadersMapping['report_name']],
        'report_description': uploadData.metadata[metadataHeadersMapping['report_description']]
      },
      'data': uploadData.fileData
    };
    const { statusCode, error } = await fetchAPI(PMC_ENDPOINTS.UPLOAD_EXCEL, 'POST', bodyData);
    if (statusCode === 200 && !error) {
      setUploadingFile({
        uploadingFile:false,
        error: false,
        success: true
      });
      setOpenLoader(false);
    } else if (statusCode === 404 || statusCode === 500) {
      setUploadingFile({
        uploadingFile: false,
        error: true,
        success: false
      });
      setOpenLoader(false);
    } else {
      setUploadingFile({
        uploadingFile: false,
        error: true,
        success: false
      });
      setOpenLoader(false);
    }
  };

  // Error Handler.
  const handleFileReadError = (e) => {
    // If e is error object.
    if(e instanceof Error){
      setError({
        status: true,
        message: 'Incorrect file uploaded. Please check the formatting in file and try again.'
      });
    } else {
      setError({
        status: true,
        message: 'There was an error while uploading the file. Please try again.'
      });
    }
    setOpenLoader(false);
  };

  // Reset error state.
  const resetError = () => {
    setError({
      status: false,
      message: ''
    });
  };

  // returns a submit button component.
  const renderSubmitButton = (clickHandler) => (
    <StyledButton variant="contained" onClick={clickHandler}>
      Submit
    </StyledButton>
  );
  return (
    <>
      <BackdropLoader Open={openLoader}/>
      <CustomizedSnackbars 
        Open={uploadingFile.success} 
        Variant="success" 
        Message="File submitted." 
        EnableTimer 
        Timer={3000} 
        CloseCallback={()=>setUploadingFile({...uploadingFile, success:false})}
      />
      <CustomizedSnackbars
        Open={error.status}
        Variant="error"
        Message={error.message}
        CloseCallback={resetError}
      />
      <WrapperGrid container>
        <ButtonGrid item xs={12}>
          <Box component="span">
            <UploadButton 
              fileInfo={fileInfo} 
              onClickCallback={()=>setOpenLoader(true)}
              setDataCallback={processUploadData}
              fileReadErrorCallback={handleFileReadError}
            />
          </Box>
          <Box component="span" m={1}>
            <ConfirmDialog 
              renderButton={renderSubmitButton}
              dialogTitle="Are you sure you want to submit ?"
              confirmCallback={submitUploadData}
            />
          </Box>
        </ButtonGrid>
        <Grid item xs={12}>
          <Table 
            columns={tableColumns}
            data={uploadData.fileData}
            defaultPageSize={100}
          />
        </Grid>
      </WrapperGrid>
    </>
  );
};

const WrapperGrid = styled(Grid)`
  padding: 1rem;
`;

const StyledButton = styled(Button)`
  background-color: #bfc9ff;
  &:hover { 
    background-color: #9ba3cf;
  }
  &:focus { 
    outline: none;
  }
`;

const ButtonGrid = styled(Grid)`
  margin-bottom: 1rem;
`;

export default UploadExcel;
