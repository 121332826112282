import * as FileSaver from "file-saver";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";

const fileExtension = '.xlsx';
const exportExcel = async ( exportData, exportHeaders, fileName, callbackFunc ) => {
    try {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet("Data");
        // Adding data to worksheet.
        var exportColumns = [];
        var headersList = [];
        exportHeaders.forEach(exportHeader=>{
            // var pushValue = { header: exportHeader, key: exportHeader };
            var pushValue = { key: exportHeader.exportKey };
            exportColumns.push(pushValue);
            headersList.push(exportHeader.exportTitle);
        });
        // Assigning worksheet columns. 
        // NOTE : for some weird reason if you try pushing directly to worksheet.columns
        // there's an error thrown.
        ws.columns = exportColumns;
        ws.getRow(1).values = headersList;

        // Bold headers.
        ws.getRow(1).font = { bold: true};
        // ws.getRow(1).eachCell(cell=>{cell.font = { bold: true}});

        // Add data to excel.
        exportData.forEach(row=>{
            ws.addRow(row);
        });
        const buffer = await wb.xlsx.writeBuffer();
        const fileData = new Blob([buffer]);
        FileSaver.saveAs(fileData, fileName + fileExtension);
        callbackFunc('SUCCESS');
    } catch (err) {
        callbackFunc('ERROR');
        // console.log(err);
    }
};

export default exportExcel;