import React,{Component} from 'react';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';
import {withRouter} from 'react-router';
import 'css/pages/dashboard.css';

const BASE_URI='/pro';
class ChangeUserPassword extends Component{
    state={
        email:sessionStorage.getItem('emailId'),
        newPwd:'',
        confirmNewPwd:'',
        currentPwd:'',
        error:false,
        errorMessage:'',
        invalidInput:false,
        disableSubmit:true,
        pwdChanged:false,
        loading:false,
    }
    changePassword=async()=>{
        let token=sessionStorage.getItem('token');
        const response=await fetch(`${BASE_URI}/reset_user_password/`,{
            method:'PUT',
            headers:{
                Authorization:`Token ${token}`
            },
            body:JSON.stringify({
                password:this.state.newPwd
            })
        });
        if(response.status!==200){
            this.setState({loading:false,error:true,errorMessage:"There was an internal error while processing your request. Refresh and try again."});
        }else{
            this.setState({pwdChanged:true,error:false,loading:false,});
            setTimeout(()=>{
                sessionStorage.clear();
                this.props.history.push("/");
            },2000);
        }
    }
    onChangeCurrentPwd=(event)=>{
        this.setState({currentPwd:event.target.value});
    }
    onChangeNewPwd=(event)=>{
        this.setState({newPwd:event.target.value,disableSubmit:true});
    }
    onChangeConfirmNewPwd=(event)=>{
        if(this.state.newPwd!==event.target.value){
            this.setState({confirmNewPwd:event.target.value,invalidInput:true,disableSubmit:true});
        }else{
            this.setState({confirmNewPwd:event.target.value,invalidInput:false,disableSubmit:false});
        }
    }
    onClickSubmit=()=>{
        this.setState({
            loading:true
        });
        this.changePassword();
    }
    render(){
        return (
            <div className="container-fluid">
                <BackdropLoader Open={this.state.loading}/>
                {this.state.pwdChanged?<CustomizedSnackbars Variant="success" Message="Password Changed Successful" Open={true} EnableTimer={true} Timer={1500}/>:null}
                {this.state.error?<CustomizedSnackbars Variant="error" Message={this.state.errorMessage} Open={true}/>:null}
                <div className="row info-text">
                    <h3 className="info-text">Change Password</h3>
                </div>
                <hr/>
                <div className="is-invalid">
                    <div className="row">
                        <div className="col-2">
                            <label>Email:</label>
                        </div>
                        <div className="col-6">
                            <label>{this.state.email}</label>
                        </div>
                    </div>
                    <br/>
                    {/* <div className="row">
                        <div className="col-2">
                            <label>Current Password: </label>
                        </div>
                        <div className="col-4">
                            <input type="password" className="form-control" placeholder="Current Password" onChange={this.onChangeCurrentPwd} value={this.state.currentPwd}/>
                        </div>
                    </div>
                    <br/> */}
                    <div className="row">
                        <div className="col-2">
                            <label>New Password:</label>
                        </div>
                        <div className="col-4">
                            <input type="password" className="form-control" placeholder="New Password" onChange={this.onChangeNewPwd} value={this.state.newPwd} maxLength="20"/>
                            <div className="invalid-feedback">
                                Passwords don't match.
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-2">
                            <label>Confirm Password:</label>
                        </div>
                        <div className="col-4">
                            <input type="password" className={this.state.invalidInput?"form-control is-invalid":"form-control"} placeholder="Confirm Password" onChange={this.onChangeConfirmNewPwd} value={this.state.confirmNewPwd} maxLength="20"/>
                            <div className="invalid-feedback">
                                Passwords don't match.
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="offset-5">
                            {
                                this.state.loading
                                ?
                                <CircularIndeterminate Type="Login"/>
                                :
                                <button className="btn btn-primary" onClick={this.onClickSubmit} disabled={this.state.disableSubmit}>Submit</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ChangeUserPassword);