import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const DefaultCircularProgress = withStyles({
  root:{
    color:'#007bff'
  },
})(CircularProgress);
const LoginCircularProgress=withStyles({
    root:{
        color:'#007bff'
    },
})(CircularProgress);

const DashboardCircularProgress=withStyles({
  root:{
    color:'#c2bd32',
  }
})(CircularProgress);

const PoCircularProgress=withStyles({
  root:{
    color:'white'
  }
})(CircularProgress);

const PoViewMoreCircularProgress=withStyles({
  root:{
    color:'white'
  }
})(CircularProgress);

const PoCommentsCircularProgress=withStyles({
  root:{
    color:'grey'
  }
})(CircularProgress);

const PoVendorDetailsCircularProgress=withStyles({
  root:{
    color:'grey'
  }
})(CircularProgress);

const PoTermsCircularProgress=withStyles({
  root:{
    color:'black'
  }
})(CircularProgress);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign:'center',
  },
}));

const CircularIndeterminate=({Type})=>{
  const classes = useStyles();
  const getComponent=(type)=>{
    switch(type){
      case 'Login':
        return <LoginCircularProgress/>
      case 'Dashboard':
        return <DashboardCircularProgress size={75}/>
      case 'PoTable':
        return <PoCircularProgress size={75}/>
      case 'PoViewMore':
        return <PoViewMoreCircularProgress size={75}/>
      case 'PoComments':
        return <PoCommentsCircularProgress size={30}/>
      case 'PoVendorDetails':
        return <PoVendorDetailsCircularProgress/>
      case 'PoTerms':
        return <PoTermsCircularProgress/>
      default:
        return <DefaultCircularProgress/>
    }
  }

  return (
    <div className={classes.root}>
      {getComponent(Type)}
    </div>
  );
}

CircularIndeterminate.propTypes={
  Type:PropTypes.string
};

export default CircularIndeterminate;