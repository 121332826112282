import React,{Component} from 'react';
import EstimateForm from 'quotationSystem/Forms/EstimateForm';
import Paper from '@material-ui/core/Paper';
import 'css/views/loginview.css';
import SideNavBarQuotationSystem from 'quotationSystem/SideNavBarQuotationSystem';
import {EstimateTableProvider} from 'quotationSystem/EstimateTableContext';
/**
 * This component is used to make a view for Login page view.
 * @ignore
 */
const EstimateFormComp=()=>{
    const formValues={
        dealId:sessionStorage.getItem('dealId'),
        customerName:sessionStorage.getItem('customerName')
    };
    return(
        <Paper elevation={10}>
            <br/>
            <EstimateForm formValues={formValues}/>
            <br/>
        </Paper>
    );
}
class EstimateFormView extends Component{
    render(){
        return(
            <EstimateTableProvider>
                <div className="container-fluid">
                    {/* <SideNavBarQuotationSystem RenderComponent={<EstimateFormComp/>}/> */}
                    <EstimateFormComp/>
                </div>
            </EstimateTableProvider>
        );
    }
}

export default EstimateFormView;