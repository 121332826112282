import React from 'react';
import { 
  Grid, 
  TextField, 
  FormLabel, 
  FormHelperText,  
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const branches = [
  { value: "Delhi", label: "Delhi" },
  { value: "Pune", label: "Pune" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "Mumbai", label: "Mumbai" }
];

const CustomTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
    }
  }
})(TextField);

const QuotationInfo = ({ 
  classes,
  formValues,
  onChangeEstimate,
  onChangeBillingBranch,
  onChangeCustomerName,
  onChangeDealId,
  errorEstimate,
  errorBillingBranch,
  errorCustomerName,
  errorDealId,
}) => {
  console.log(sessionStorage.getItem('ccw_estimate'))
  console.log(typeof(sessionStorage.getItem('ccw_estimate')))
  return (
    <>
      <Grid container item className={classes.gridNestedContainerComponent}>
        <Grid item xs={2}>CCW Estimate ID</Grid>
        <Grid item xs={4}>
          <TextField
            required
            id="ccw_estimate"
            defaultValue={(sessionStorage.getItem('ccw_estimate')==='null' || sessionStorage.getItem('ccw_estimate')===undefined)?'':sessionStorage.getItem('ccw_estimate')}
            onChange={onChangeEstimate}
            error={errorEstimate} 
            disabled={sessionStorage.getItem('ccw_estimate')}
            helperText={errorEstimate?"Estimate ID is required":null}
          />
        </Grid>
        <Grid item xs={2}>
          <FormLabel
            component="label"
            className={classes.formLabelText}
            style={{marginTop:"2vh"}}
          >
            Proactive Billing Branch:
          </FormLabel>
          <FormHelperText>{errorBillingBranch?"Please select a billing branch.":null}</FormHelperText>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            variant="outlined"
            size="small"
            required
            id="billing_branch"
            onChange={onChangeBillingBranch}
            SelectProps={{
              native: true
            }}
          >
            <option key="None" value="" Disabled={sessionStorage.getItem('estimate_type_by_login')=="T2"?'Disabled':false}>
              None
            </option>
            {
              branches.map(option => (
                <option key={option.value} 
                  value={option.value} 
                  selected={sessionStorage.getItem('billing_branch')==option.value} 
                  Disabled={sessionStorage.getItem('billing_branch')?'Disabled':false}>
                  {option.label}
                </option>
              ))
            }
          </TextField>
        </Grid>
      </Grid>
      <Grid container item className={classes.gridNestedContainerComponent}>
        <Grid item xs={2}>Customer Name</Grid>
        <Grid item xs={2}>
            <CustomTextField
            required
            id="customer_name"
            fullWidth
            // defaultValue={sessionStorage.getItem('estimate_type_by_login')=="T2"?sessionStorage.getItem('customer_name'):formValues.customerName}
            defaultValue={sessionStorage.getItem('customer_name')}
            onChange={onChangeCustomerName}
            error={errorCustomerName}
            disabled={formValues.dealId!==undefined}
            helperText={errorCustomerName?"Please enter customer name.":formValues.customerName?"Read Only.":null}
            InputProps={{
              readOnly: formValues.customerName!==undefined
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>Zoho Deal Id</Grid>
        <Grid item xs={2}>  
          <CustomTextField
            required
            id="deal_id"
            fullWidth
            defaultValue={formValues.dealId}
            onChange={onChangeDealId}
            disabled={formValues.dealId!==undefined}
            error={errorDealId}
            helperText={errorDealId?"Please enter deal id.":formValues.dealId?"Read Only.":null}
            InputProps={{
              readOnly: formValues.dealId!==undefined
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

QuotationInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChangeEstimate: PropTypes.func.isRequired,
  onChangeBillingBranch: PropTypes.func.isRequired,
  onChangeCustomerName: PropTypes.func.isRequired,
  onChangeDealId: PropTypes.func.isRequired,
  errorEstimate: PropTypes.bool.isRequired,
  errorBillingBranch: PropTypes.bool.isRequired,
  errorCustomerName: PropTypes.bool.isRequired,
  errorDealId: PropTypes.bool.isRequired,
};

export default QuotationInfo;