import React,{Component} from 'react';
import SideNavBar from '../SideNavBar';
import PurchaseOrder from '../../containers/PurchaseOrder';

/**
 * This component is used to make a view for purchase order page view.
 * @ignore
 */
class PurchaseOrderView extends Component{
    render(){
        return(
            <div className="purchase-order-view container-fluid">
                <div className="row grow">
                    {/* <div className="col-md-1 col-2 side-nav">
                        <SideNavBar/>
                    </div> */}
                    <div className="col-md-12 col-12 po-data">
                        <PurchaseOrder/>
                    </div>
                </div>
            </div>
        );
    }
}

export default PurchaseOrderView;