import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import companyLogo from '../assets/proactive_logo.png';
import '../css/header.css';
import styled from 'styled-components';
import Navbar from './SideNavBar';
import QuotationNavbar from '../quotationSystem/SideNavBarQuotationSystem';
import PMCNavbar from '../pmcSystem/pmcSidemenu';
import { Link } from 'react-router-dom';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// Material UI imports
import {
    Avatar,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';

import { USER_TYPE } from 'globalConstants';





/**
 * This component is used for Header.
 */
const Header = props => {
    let history = useHistory();
    const [anchorEl, setAnchorEl] = useState(false);
    const user = sessionStorage.getItem('emailId') || sessionStorage.getItem('username') || '';
    const userTypes = sessionStorage.getItem('usertype');

    // Button handlers.
    const redirectChangePassword = () => {
        if (sessionStorage.getItem("usertype") !== "6") {
            history.push("/user/changePassword");
        } else {
            history.push("/user/CF/changePassword");
        }
    };
    const logoutUser = () => {
        history.push("/");
        sessionStorage.clear();
    };
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 500,
            border: '1px solid #dadde9',
        },
    }));


    // List of options available on username dropdown.
    const userMenuItems = [
        {
            id: 'pwd',
            title: 'Password',
            show: sessionStorage.getItem('usertype') !== USER_TYPE.QUOTATION_SYSTEM,
            icon: <LockIcon fontSize="small" />,
            onClick: redirectChangePassword,
        },
        {
            id: 'logout',
            title: 'Logout',
            show: true,
            icon: <ExitToAppIcon fontSize="small" />,
            onClick: logoutUser,
        }
    ];

    return (
        <div className="header container-fluid" style={{ zIndex: "1" }}>
            <div className="row">
                <div className="col-md-4 col-4">
                    <img className="header-image float-left" src={companyLogo} alt="Company Logo" />
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">What's New!</Typography>
                                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
                                <b> {"- Improve user UI experience."}<br />
                                    {"- Professional Charges in Product Added."}<br />
                                </b>
                            </React.Fragment>
                        }
                    >
                        <span style={{
                            position: 'absolute',
                            fontSize: '9px',
                            bottom: '0',
                        }}>Version 1.1.1.0.8</span>
                    </HtmlTooltip>
                </div>
                {/* <div className="col-md-6 col-6 text-center">
                    <h4 className="header-text">{props.pageName}</h4>
                </div> */}
                <div className="col-md-8 col-8 align-self-center">
                    {/* <h4 className="header-text">{props.pageName}</h4> */}
                    <div className="dropdown float-right">
                        {userTypes == 1 || userTypes == 2 ? <Navbar /> : ''}
                        {userTypes == 11 ? <QuotationNavbar /> : ''}

                        {userTypes == 12 ?
                            <>
                                <Button >
                                    <span >
                                        <div>
                                            <Link className="menuLabel" to="/pmc/view">View</Link>
                                        </div>
                                    </span>
                                </Button >
                                <Button >
                                    <span >
                                        <div>
                                            <Link className="menuLabel" to="/pmc/upload">Upload</Link>
                                        </div>
                                    </span>
                                </Button >
                            </>
                            : ''}



                        <StyledDropdownButton
                            disableFocusRipple
                            disableElevation
                            disableRipple
                            aria-controls="profile-menu"
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            {/* {user}&nbsp;&nbsp;&nbsp; */}
                            <StyledAvatar>{user.charAt(0)}</StyledAvatar>
                            {/* {sessionStorage.getItem('emailId')||sessionStorage.getItem('username')} */}
                        </StyledDropdownButton>
                        <StyledMenu
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            {user}&nbsp;&nbsp;&nbsp;
                            {
                                userMenuItems.map((item, index) => ((
                                    item.show &&
                                    <StyledItem key={index} onClick={() => item.onClick()}>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </StyledItem>
                                )))
                            }
                        </StyledMenu>
                    </div>
                </div>
            </div>
        </div>
    );
}

Header.propTypes = {
    /**
     * Heading to be display on header.
     */
    pageName: PropTypes.string,
};

const StyledDropdownButton = styled(Button)`
    background: none;
    border: none;
    outline: none;
    text-transform: none;
    font-size: 1em;
    margin-right: 10px;
    &:active {
        background: none;
    }
    &:focus{
        outline:none;
    }
`;

const StyledAvatar = styled(Avatar)`
    /* Font Color */
    color: black;
    /* Background Color */
    background-image: linear-gradient(#c5d2fc, #ECECEC, #fcedc5);
`;

const StyledMenu = styled(Menu)`
    & .MuiMenu-paper {
        background-color: whitesmoke;
    }
`;

const StyledItem = styled(MenuItem)`
    &:hover {
        background-image: linear-gradient(#c5d2fc, #ECECEC, #fcedc5);
    }
`;

export default Header;