import XLSX from 'xlsx';

const readExcel = async ( 
    file, 
    fileInfo, 
    setReadData, 
    fileReadErrorCallback,
    ignoreMetaData=false
) => {
    /**
     * @param {Object} file - file object read upon upload.
     * @param {Object} fileInfo - info related to formatting of uploaded file. 
     *  Format = { headersStartRow: number , metadataRows: number }
     *   headersStartRow : line number where headers start from.
     *   metadataRows : no of rows containing any metadata.
     * @param {Function} setReadData - updating function for handling read data from excel.
     * @param {Function} fileReadErrorCallback - callback called if any error occurs while reading the excel file.
     * @param {Boolean} ignoreMetaData - (optional) if you want to ignore metadata set True.
     */

    const fileReader = new FileReader();
    
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
        try {
            // ** NOTE: This way it supports both .xlsx and .csv uploads.
            const readData = new Uint8Array(e.target.result);
            // console.log(bufferArray);
            const wb = XLSX.read(readData, { type: "array"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            
            // Get the range we are reading data from.
            var dataRange = XLSX.utils.decode_range(ws['!ref']);

            if(!ignoreMetaData) {
                var metadataRows = 3;
                var metadata = {};
                for(let R = 0; R < metadataRows; R++) {
                    metadata[ws[`A${R+1}`].v]=ws[`B${R+1}`].v;
                }
            }
            
            // Now we skip metadata rows.
            dataRange.s.r = fileInfo.headersStartRow-1;
            // Assign new range.
            ws['!ref'] = XLSX.utils.encode_range(dataRange);
            
            // Convert data to [{},{}....{}] format.
            const data = XLSX.utils.sheet_to_json(ws);
            if(!ignoreMetaData) {
                setReadData({ metadata: metadata, fileData: data});
            } else {
                setReadData(data);
            }
        } catch (err) {
            console.log(err);
            fileReadErrorCallback(err);
        }
    };
    fileReader.onerror = event => {
        fileReadErrorCallback(event);

        // Stop file reading operation.
        fileReader.abort();
    };
};


export default readExcel;