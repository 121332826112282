import React,{Component} from 'react';
import CircleLoader from 'react-spinners/CircleLoader';
import loginRightIcon from '../assets/login-right-arrow.png';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import '../css/pages/signup.css';

const BASE_URI='/pro';
class SignUp extends Component{
    state={
        verifyAdmin:false,
        adminName:'',
        token:'',
        adminType:-1,
        errorMessage:'',
        adminEmail:'',
    }
    getAccessToken=async(loginData)=>{
        const response=await fetch(`${BASE_URI}/loginApi/`,{
            method:'POST',
            body:JSON.stringify({
                username:loginData.emailId,
                password:loginData.password
            })
        });
        const responseJSON=await response.json();
        if(responseJSON.status==="200"){
            if(responseJSON.User_Type===2){
                this.setState({
                    errorMessage:'You do not have administrator rights to perform this.',
                })
            }else{
                this.setState({
                    token:responseJSON.token,
                    adminType:responseJSON.User_Type,
                    adminName:responseJSON.Name,
                    verifyAdmin:true,
                    adminEmail:loginData.emailId,
                });
            }
        }else{
            this.setState({
                errorMessage:responseJSON.message
            });
        }
    }
    componentDidUpdate(){
        if(this.state.verifyAdmin){
            sessionStorage.setItem('adminVerified',true);
            sessionStorage.setItem('adminName',this.state.adminName);
            sessionStorage.setItem('token',this.state.token);
            sessionStorage.setItem('emailId',this.state.adminEmail);
            this.props.history.push("/signup/addUser");
        }
    }
    handleLoginSubmit=(event)=>{
        event.preventDefault();
        const data=new FormData(event.target);
        let loginData={
            emailId:data.getAll('email')[0],
            password:data.getAll('password')[0],
        }
        this.getAccessToken(loginData);
    }
    render(){
        return (
           <div>
                {this.state.verifyAdmin?<div className="loader">
                    <CircleLoader/>
                    <h1>Hello</h1>
                    </div>:
                    <form className="login-form" method="post" onSubmit={this.handleLoginSubmit}>
                        <div className="form-row justify-content-center mb-3">
                            <h3 className="admin-login-text">Admin Login</h3>
                        </div>
                        <div className="form-row justify-content-center mb-3">
                            <p className="admin-login-description">This login is for admins only. For existing user click below link.</p>
                        </div>
                        {this.state.errorMessage===''
                            ?null
                            :<div className="form-row justify-content-center">
                            <p className="error-message">Failed to login. {this.state.errorMessage}</p>
                            </div>
                        }
                        <div className="form-row justify-content-center">
                            <div className="form-group">
                                <input name="email" type="email" className="form-control form-user-input" id="userEmailId" placeholder="Email Address" required/>
                            </div>
                        </div>
                        <div className="form-row justify-content-center">
                            <div className="form-group">
                                <input name="password" type="password" className="form-control form-user-input" id="userPassword" placeholder="Password" required/>
                            </div>
                        </div>
                        <div className="form-row justify-content-center">
                            <button type="submit" className="form-button btn btn-primary">Continue<img className="admin-login-arrow ml-2" src={loginRightIcon} alt="right-icon"/></button>
                        </div>
                        <div className="form-row justify-content-center admin-login-here-block">
                            <Link className="create-account-text" to="/">Login Here! (Existing User)</Link>
                        </div>
                    </form>
                }
            </div>
        );
    }
}

export default withRouter(SignUp);