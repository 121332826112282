import React from 'react';
// import styled from 'styled-components';

// Formats currency columns to INR based commas.
var indianCurrencyFormatter = new Intl.NumberFormat('en-IN');

const EditableCell = ({ 
    value: initialValue, 
    row: { index }, 
    column: { id },
    updateMyData,
    type='text'
}) => {
    const [value, setValue] = React.useState(initialValue);

    const onChange = e => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        updateMyData(index, id, value);
    };

    React.useEffect(()=>{
        let val = indianCurrencyFormatter.format(initialValue);
        if(isNaN(val)){ 
            setValue(initialValue);
        } else {
            setValue(val);
        }
    },[initialValue]);
    
    return <input type={type} value={value} onChange={onChange} onBlur={onBlur}/>;
};

export default EditableCell;