/**
 * NOTE: This file contains all the API endpoints that are required
 * to interact with the backend of the application.
 * 
 * Q. How does it work ? 
 * A. The endpoints are divided by the as per the systems implemented on the portal.
 * i.e. All pmc endpoints are in PMC_ENDPOINTS object. Now each object has multiple
 * key-value pairs, these store each individual endpoint in required by the application.
 * 
 * For eg. say PMC_ENDPOINTS has a key-value pair LIST: <some endpoint URI>
 * i.e for LIST endpoint requirements the URI is the value. 
 * 
 * For a more detailed view refer to the comments inside these objects which describe what 
 * that endpoint is required for in more detail.
 * 
 * Q. How is it formatted ? 
 * A. Each endpoint is formatted as: 
 * BASE_URI = Described in the common useAPI custom hook.
 * ENDPOINT_URI = Described here per system.
 * 
 * Combined URI : BASE_URI/ENDPOINT_URI/
 * 
 * So for mentioning the URI here we don't need to add '/' in the start but we do need to
 * add '/' at the end.
 * 
 * Q. What happens with URI which have dynamic values after / ?
 * A. For those also we have to follow the same rule as above the values after the '/' are handled
 * by the useAPI custom hook. No need to mention them here.
 * 
 */

const BASE_URI = '/pro';

const COMMON_ENDPOINTS = {
    LOGIN: `${BASE_URI}/loginApi/`
};

const CASHFLOW_ENDPOINTS = {
    CASHFLOW_DATA: `${BASE_URI}/cashFlowApi`,
    ALL_COMMENTS_DATA: `${BASE_URI}/getAllCashFlowCommentApi`
};

const APPROVAL_PORTAL_ENDPOINTS = {
};

const PMC_ENDPOINTS = {
    // For getting list of all files uploaded and submitted.
    LIST: 'getPmcUploadDetails/',
    // All data for particular row. id is added after '/'.
    DATA_PER_ROW: 'getPmcUploadDataDetails/',
    // Quarter data for particular row. id is added after '/'.
    DATA_PER_ROW_QUARTER: 'getPMCServiceMetricData/',
    // To update edited data.
    UPDATE_METRICS_DATA: 'updatePMCServiceMetricData/',
    // Data for performance meterics.
    METRICS_DATA: 'pmc_performance_metrics/',
    // For submitting uploaded excel file.
    UPLOAD_EXCEL: 'PmcUpload/'
};

const QUOTATION_SYSTEM_ENDPOINTS = {
    // Endpoint for sending updated data.
    SEND_CHANGED_TABLE_DATA: 'getEstCalcDetails/',
    // Endpoint for estimate product data.
    ESTIMATE_PRODUCT_DETAILS: 'getEstProductDetails/',
    // Endpoint for creating a new estimate version.
    CREATE_NEW_ESTIMATE_VERSION: 'changeEstimateVersion/',
    // Delete Rows for view products table. NOTE: need to add id of estimate in the end.
    DELETE_VIEW_PRODUCTS_ROWS: 'delete_t2_lines/',
    // Uploading hold items and sending to backend. NOTE: this is only for T2.
    // NOTE: Also add estimate id in the end of URL.
    UPLOAD_HOLD_ITEMS: 'upload_hold_items/',
};

export { 
    COMMON_ENDPOINTS, 
    CASHFLOW_ENDPOINTS,
    APPROVAL_PORTAL_ENDPOINTS, 
    PMC_ENDPOINTS, 
    QUOTATION_SYSTEM_ENDPOINTS 
};