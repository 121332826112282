import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';
import CircularIndeterminate from './CircularIndeterminate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const BackdropLoader=({ Open })=>{
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={Open}>
        <CircularIndeterminate Type="PoTable"/>
      </Backdrop>
    </div>
  );
}

Backdrop.propTypes = {
  Open: PropTypes.bool
};

export default BackdropLoader;
