import React from "react";
import { Tooltip, Zoom, Typography} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import 'css/tabledata.css';


const CustomToolTip = withStyles(theme => ({
  tooltip: {
    // backgroundColor: "#f5f5f9",
    backgroundColor:"black",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const PurchaseHover=(props)=>{
  return (
      <CustomToolTip
        title={
          <React.Fragment>
            <Typography style={{color:"white"}}>Invoice No. - {props.InvoiceNo===""||props.InvoiceNo===undefined?"Not available":props.InvoiceNo}</Typography>
          </React.Fragment>
        }
        arrow
        TransitionComponent={Zoom}
      >
        <div className={props.TextClasses}>{props.VendorName}</div>
      </CustomToolTip>
  );
}

export default PurchaseHover;
