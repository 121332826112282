import React,{useEffect,useState} from 'react';
import {withRouter} from 'react-router';
import AnimatedCheckMark from '../components/AnimatedCheckMark';
import AnimatedCrossMark from '../components/AnimatedCrossMark';
import LoadingEllipsis from '../components/LoadingEllipsis';
import ClipLoader from 'react-spinners/ClipLoader';

const BASE_URI='/pro';

/**
 * This component is for rendering Redirect Login page.
 * Redirection happens from Zoho CRM.
 */

const RedirectLogin=props=>{
    console.log(props)
    const {queryParams}=props;
    const successMsgComp=<p>Verified. Redirecting<LoadingEllipsis/></p>;
    const unauthorizedMsgComp=<p>Incorrect credentials. Authorization failed, redirecting<LoadingEllipsis/></p>;
    const serverErrorMsg=<p>Unable to reach server at this moment. Try again after sometime.</p>;
    const [verifySuccess,setVerifySuccess]=useState(false);
    const [errorVerifying,setErrorVerifying]=useState(false);
    const [serverError,setServerError]=useState(false);
    useEffect(()=>{
        verifyUser();
    },[]);
    const verifyUser=async()=>{
        const response=await fetch(`${BASE_URI}/estimate_user_login/`,{
            method:'POST',
            body:JSON.stringify({
                owner:queryParams.owner,
                deal_id:queryParams.dealId,
                token:queryParams.token,
            })
        });
        if(response.status===200){
            const responseJSON=await response.json();
            console.log(responseJSON)
            // User verified.
            sessionStorage.setItem('loggedIn',true);
            sessionStorage.setItem('username',responseJSON.Name);
            sessionStorage.setItem('token',responseJSON.token);
            sessionStorage.setItem('usertype',responseJSON.User_Type);
            // # TODO : Implement Context or Redux store for storing these values.
            // These session storage variables are only set in redirect login logic.
            sessionStorage.setItem('customerName',queryParams.customerName);
            sessionStorage.setItem('dealId',queryParams.dealId);
            sessionStorage.setItem('estimate_type_by_login',responseJSON.estimate_type);
            sessionStorage.setItem('data_by_login',responseJSON.data);

            sessionStorage.setItem('billing_branch',responseJSON.data.billing_branch);
            sessionStorage.setItem('ccw_estimate',responseJSON.data.ccw_estimate);
            sessionStorage.setItem('cust_billing_location',responseJSON.data.cust_billing_location);
            sessionStorage.setItem('cust_type',responseJSON.data.cust_type);
            sessionStorage.setItem('customer_name',responseJSON.data.customer_name);
            sessionStorage.setItem('goods_FTWZ',responseJSON.data.goods_FTWZ);
            sessionStorage.setItem('goods_zero_rated_GST',responseJSON.data.goods_zero_rated_GST);
            sessionStorage.setItem('option_type',responseJSON.data.option_type);
            sessionStorage.setItem('other',responseJSON.data.other);
            sessionStorage.setItem('ser_disc',responseJSON.data.ser_disc);
            sessionStorage.setItem('ser_markup',responseJSON.data.ser_markup);
            sessionStorage.setItem('services_zero_rate',responseJSON.data.services_zero_rate);
            sessionStorage.setItem('ser_cust_disc_line',responseJSON.data.ser_cust_disc_line);

            setVerifySuccess(true);
            setTimeout(()=>{
                props.history.push("/estimateForm");
            },3000);



            // const responseJSON=await response.json();
            // console.log(responseJSON)
            
            // // User verified.
            // sessionStorage.setItem('loggedIn',true);
            // sessionStorage.setItem('username',responseJSON.Name);
            // sessionStorage.setItem('token',responseJSON.token);
            // sessionStorage.setItem('usertype',responseJSON.User_Type);
            // // # TODO : Implement Context or Redux store for storing these values.
            // // These session storage variables are only set in redirect login logic.
            // sessionStorage.setItem('customerName',queryParams.customerName);
            // sessionStorage.setItem('dealId',queryParams.dealId);
            // setVerifySuccess(true);
            // setTimeout(()=>{
            //     props.history.push("/estimateForm");
            // },3000);

        }else if(response.status===401 || response.status===400){
            // Unverified user. Incorrect data/query params in url.
            setErrorVerifying(true);
            setTimeout(()=>{
                props.history.push("/");
            },5000);
        }else if(response.status===500 || response.status===404){
            // Server Error. Backend Error.
            setServerError(true);
        }
    };
    return (
        <>  
        <div className="d-flex align-items-center justify-content-center" style={{height:"100vh"}}>
            <div className="d-block">
                {(!verifySuccess && !serverError && !errorVerifying) &&
                <>
                    <div style={{display:"block",textAlign:"center"}}>
                        <ClipLoader color={"#0275d8"} loading={true} size={75} />
                    </div>
                    <h6>Please wait verifying user<LoadingEllipsis/></h6>
                </>
                }
                {verifySuccess && <AnimatedCheckMark message={successMsgComp}/>}
                {errorVerifying && <AnimatedCrossMark message={unauthorizedMsgComp}/>}
                {serverError && <AnimatedCrossMark message={serverErrorMsg}/>}
            </div>
        </div>
        </>
    );
}

export default withRouter(RedirectLogin);