import React,{useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const variantIcon = {
  success: CheckCircleOutlineOutlinedIcon,
  warning: ReportProblemOutlinedIcon,
  error: ErrorOutlineOutlinedIcon,
  info: InfoOutlinedIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

const MySnackbarContentWrapper = props => {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["info", "error", "success", "warning"]).isRequired
};


const CustomizedSnackbars=({ 
  Open, 
  Variant, 
  Message, 
  EnableTimer=false, 
  Timer=0, 
  CloseCallback=null, 
  OnExit=null 
}) => {
  const [open, setOpen] = React.useState(false);
  const [variant,setVariant]=React.useState(Variant);
  const [message,setMessage]=React.useState(Message);
  const [timer,setTimer]=React.useState(0);
  const [enableTimer,setEnableTimer]=React.useState(false);
  useEffect(()=>{
    setOpen(Open);
    setVariant(Variant);
    setMessage(Message);
    if(EnableTimer===true){
        setTimer(Timer);
        setEnableTimer(EnableTimer);
    }
    // console.log(Variant);
  },[Open,Variant,Message,EnableTimer,Timer]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // if(CloseCallback){
      // If CloseCallback provided used.
      CloseCallback();
    // }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        TransitionProps={{
          onEnter:()=>{
            setVariant(Variant);
            if(enableTimer===true){
                setTimeout(()=>{setOpen(false)},timer);
            }
          },
          onExit: OnExit
        }}
        open={open}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
}


CustomizedSnackbars.propTypes={
  /**
   * Show notification or not.
   */
  Open: PropTypes.bool.isRequired,
  /**
   * Type of notification to show.
   */
  Variant: PropTypes.oneOf(["info", "error", "success", "warning"]).isRequired,
  /**
   * Message to show in notification.
   */
  Message: PropTypes.string.isRequired,
  /**
   * Enable timer for auto close.
   */
  EnableTimer: PropTypes.bool,
  /**
   * Time (in milliseconds) after which auto close the notification.
   */
  Timer: PropTypes.number,
  /**
   * Function to be called when the notification is closed (manually).
   */
  CloseCallback: PropTypes.func,
  /**
   * Function to be called when the notification is exiting.
   */
  OnExit: PropTypes.func
};

export default CustomizedSnackbars;
