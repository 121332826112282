import React, { useState, useEffect, useContext } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'css/tabledata.css';
import BackdropLoader from 'components/loaders/BackdropLoader';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputAdornment,
    Slide,
    Button,
    TextField
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import { Close as CloseIcon } from '@material-ui/icons';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from "@material-ui/core/TextField";
// import { useHistory } from "react-router-dom";

import { EstimateTableContext } from 'quotationSystem/EstimateTableContext';
// Custom Components Import
import InstallationChargesModal from 'quotationSystem/Modals/InstallationChargesModal';
// import EstimateForm from './EstimateForm';
// import QuotationEstimateForm from 'quotationSystem/QuotationEstimateForm';
import { dateTimeFormatting } from 'utility/dateTimeFormat';
import { calculationDetailsColumns } from 'quotationSystem/Table/tableColumns';

const BASE_URI = process.env.NODE_ENV === 'development' ? '' : '/pro';
const PAGE_HEADER = "CCW Calculation Page";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: "rgb(47, 38, 85)",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: 'white',
        fontWeight: 600
    },
    appBarButton: {
        backgroundColor: "#9ba3cf",
        '&:hover': {
            backgroundColor: "#5c66a1",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EstCalcDetailsModal = props => {
    var nf = new Intl.NumberFormat()
    // props.Data.length > 0 && settotalQuotedPrice(nf.format(sumProperty(props.Data, 'quoted_rate').toFixed(2)))
    // props.Data.length > 0 && settotalQuotedTotal(nf.format(sumProperty(props.Data, 'quoted_total').toFixed(2)))
    const classes = useStyles();
    // let history = useHistory();
    const { viewEstCalcContext, estimateTypeContext, estimateDataContext } = useContext(EstimateTableContext);
    const [, setViewEstCalcModal] = viewEstCalcContext;
    const [estimateType] = estimateTypeContext;
    const [estimateData, setEstimateData] = estimateDataContext;
    const [open, setOpen] = useState(false);
    const [totalQuotedPrice, settotalQuotedPrice] = useState(props.Data.quoted_rate && nf.format(sumProperty(props.Data, 'quoted_rate').toFixed(2)));
    const [totalQuotedTotal, settotalQuotedTotal] = useState(props.Data.quoted_total && nf.format(sumProperty(props.Data, 'quoted_total').toFixed(2)));
    const [totalQuotedmarginvalue, settotalmarginvalue] = useState(props.Data.quoted_margin_value && nf.format(sumProperty(props.Data, 'quoted_margin_value').toFixed(2)));

    const [QuotedPricingTermUnitPrice, setQuotedPricingTermUnitPrice] = useState(props.Data.xaas_monthly_unit && nf.format(sumProperty(props.Data, 'xaas_monthly_unit').toFixed(2)));
    const [InstallmentValueSum, setInstallmentValueSum] = useState(props.Data.installment_value && nf.format(sumProperty(props.Data, 'installment_value').toFixed(2)));
    const [ContractTotalValue, setContractTotalValue] = useState(props.Data.contract_total_value && nf.format(sumProperty(props.Data, 'contract_total_value').toFixed(2)));
    const [SubscriptionCreditTotalValue, setSubscriptionCreditTotalValue] = useState(props.Data.subscription_credit && nf.format(sumProperty(props.Data, 'subscription_credit').toFixed(2)));
    const [openkp, setOpenkp] = useState(false);
    const [markUpPerLineKP, setmarkUpPerLineKP] = useState();
    const [discountPerLineKP, setdiscountPerLineKP] = useState();
    const [ListPriseKP, setListPriseKP] = useState();
    const [instalationchargesreal, setinstalationchargesreal] = useState(0);


    const [inspectionglobalerror, setinspectionglobalerror] = useState(false);
    const [markUpPerLineKPerror, setmarkUpPerLineKPerror] = useState(false);
    const [discountPerLineKPerror, setdiscountPerLineKPerror] = useState(false);
    const [ListPriseKPerror, setListPriseKPerror] = useState(false);

    const [Marginvaluereal, setmarginvaluereal] = useState(0);
    const [InstalationChargesGlobal, setInstalationChargesGlobal] = useState(0);
    const [profCharhesInProduct, setprofCharhesInProduct] = useState(0);


    const [statusProfChargebutton, setstatusProfChargebutton] = useState(true);
    const [statusAuditSheetBitton, setstatusAuditSheetBitton] = useState(false);
    const [statusEmailStateButton, setstatusEmailStateButton] = useState(false);


    const [loaderstate, setloaderstate] = useState(false);


    // prof_charges_in_product
    const onOpenModalkp = () => setOpenkp(true);
    const onCloseModalkp = () => setOpenkp(false);


    // useEffect() functions.
    useEffect(() => {
        if (estimateType !== "T2") {
            getInstallCharg()
        }
    }, []);


    const getInstallCharg = async () => {
        setloaderstate(true)
        const response = await fetch(`${BASE_URI}/ProjectEstimateAPI/${props.Id}`, {
            method: "GET",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            },
            // body: JSON.stringify({
            //     'installation_charges': '0'
            // })
        });
        const responseJSON = await response.json();
        console.log('kapil', responseJSON)

        if (response.status === 200) {
            setInstalationChargesGlobal(responseJSON['installation_charges'] + (responseJSON['installation_charges'] * responseJSON['margin_value'] / 100))
            setinstalationchargesreal(responseJSON['installation_charges'])
            setmarginvaluereal(responseJSON['margin_value'])
            setprofCharhesInProduct(responseJSON['prof_charges_in_product'])
            // setOpenInstallationChargesModal(true);
        }
        setloaderstate(false)
    }
    // console.log(props.data)
    // const [openQuotationForm,setOpenQuotationForm]=useState(false);
    // const [quotationLoader,setQuotationLoader]=useState(false)
    const [openInstallationChargesModal, setOpenInstallationChargesModal] = useState(false);
    const [auditButton, setAuditButton] = useState({
        success: false,
        error: false,
        submitInProgress: false,
    });

    const [emailButton, setEmailButton] = useState({
        success: false,
        error: false,
        inProgress: false,
        // message:''
    });
    const [state, setState] = useState({
        loadingData: false,
        data: [],
        filteredData: [],
        dataResponseCode: 0
    });
    const [filter, setFilter] = useState({
        search: ''
    });
    const handleClosekp = () => {
        setOpenkp(false);
    };
    // const handleInput = event => {
    //     console.log(event.target.value)
    // }
    const handleSubmit = async () => {
        // props.CallbackSubmit(userInputValue);
        const response = await fetch(`${BASE_URI}/inspectionChargesT2API/${props.Id}`, {
            // const response = await fetch(`${BASE_URI}/EmailEstimateAPI/${props.Id}`, {

            method: 'POST',
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                markup_per_line: markUpPerLineKP,
                discount_per_line: discountPerLineKP,
                list_price: ListPriseKP,
            })
        });
        if (response.status === 200) {
            handleClosekp();
        } else {
            setinspectionglobalerror(true)
        }
    }

    const kpmarkupperline = (e) => {
        // alert(e)
        if (e < 0 || e > 100) {
            setmarkUpPerLineKP(markUpPerLineKP)
        } else {
            setmarkUpPerLineKP(e)
        }
    }
    const kpdiscountperline = (e) => {
        // setdiscountPerLineKP(e)
        if (e < 0 || e > 100) {
            setdiscountPerLineKP(discountPerLineKP)
        } else {
            setdiscountPerLineKP(e)
        }
    }
    const kplistprice = (e) => {
        // setListPriseKP(e)
        if (e < 0) {
            setListPriseKP(ListPriseKP)
        } else {
            setListPriseKP(e)
        }
    }



    function sumProperty(arr, type) {
        if (arr.length > 0) {
            return arr.reduce((total, obj) => {
                if (typeof obj[type] === 'string') {

                    return total + Number(obj[type]);
                }
                return total + obj[type];
            }, 0);
        }
    }
    // For building columnHeaders array for this table.
    const buildTableHeadersArray = (item) => {
        var retVal = {
            Header: item.title,
            accessor: item.accessor,
            headerClassName: 'view-more-table-header'
        };
        if (item?.id) retVal.id = item.id;
        if (item.show === false) retVal.show = item.show;
        if (item?.width) retVal.width = item.width;
        if (item?.currency) {
            retVal.Cell = props => (
                <span className="number">
                    {(Math.round(props.value * 100) / 100).toLocaleFixed(2)}
                </span>
            );
        }

        // if(item?.accessor=='quantity'){
        //     console.log(props.value)
        // }
        if (item?.integer) {
            retVal.Cell = props => (
                <span>{isNaN(parseInt(props.value)) ? "-" : parseInt(props.value)}</span>
            );
        }
        if (item?.showFor) {
            // Only show for T6.
            if (item.showFor.has("T6")) retVal.show = (estimateType === "T6" || estimateType === "T6R");
            if (item.showFor.has("T2")) retVal.show = estimateType === "T2";

        }
        if (item?.dontShowFor) {
            // Don't show for T6 but show for rest.
            if (item.dontShowFor.has("T6")) retVal.show = (estimateType !== "T6" || estimateType !== "T6R");
            if (item.dontShowFor.has("T2")) retVal.show = estimateType !== "T2";
            if (item.dontShowFor.has("T2") && item.dontShowFor.has("T6")) {
                retVal.show = (estimateType !== "T6" || estimateType !== "T6R") && estimateType !== "T2";
            }
        }
        if (item?.dateTime) {
            retVal.Cell = props => {
                if (props.value === "") return <span>{"-"}</span>;
                const { formattedDate, formattedTime } = dateTimeFormatting(props.value);
                return <span>{formattedDate}</span>;
            };
        }
        if (item?.percentageFixed) {
            retVal.Cell = props => (
                <span>
                    {(parseFloat(props.value)).toFixed(4)}
                    {/* {(parseFloat(props.value) * 100).toFixed(4)} */}
                </span>
            );
        }
        return retVal;
    }

    const columnHeaders = calculationDetailsColumns.map(header => buildTableHeadersArray(header));

    // Effects
    useEffect(() => {
        setState({ ...state, data: props.Data });
        console.log(props)
        settotalQuotedPrice(nf.format(sumProperty(props.Data, 'quoted_rate').toFixed(2)))
        settotalQuotedTotal(nf.format(sumProperty(props.Data, 'quoted_total').toFixed(2)))
        settotalmarginvalue(nf.format(sumProperty(props.Data, 'quoted_margin_value').toFixed(2)))

        setQuotedPricingTermUnitPrice(nf.format(sumProperty(props.Data, 'xaas_monthly_unit').toFixed(2)))
        setInstallmentValueSum(nf.format(sumProperty(props.Data, 'installment_value').toFixed(2)))
        setContractTotalValue(nf.format(sumProperty(props.Data, 'contract_total_value').toFixed(2)))
        setSubscriptionCreditTotalValue(nf.format(sumProperty(props.Data, 'subscription_credit').toFixed(2)))



        // const [QuotedPricingTermUnitPrice, setQuotedPricingTermUnitPrice] = useState(props.Data.xaas_monthly_unit && nf.format(sumProperty(props.Data, 'xaas_monthly_unit').toFixed(2)));
        // const [InstallmentValueSum, setInstallmentValueSum] = useState(props.Data.installment_value && nf.format(sumProperty(props.Data, 'installment_value').toFixed(2)));
        // const [ContractTotalValue, setContractTotalValue] = useState(props.Data.contract_total_value && nf.format(sumProperty(props.Data, 'contract_total_value').toFixed(2)));


        setOpen(props.Open);
    }, []);
    // calculationDetailsColumns.map(header=>buildTableHeadersArray(header))

    const handleClose = () => {
        setOpen(false);
        setViewEstCalcModal(false);
    };

    const onClickAudit = async () => {
        setAuditButton({ ...auditButton, submitInProgress: true });
        // const response=await fetch(`${BASE_URI}/AuditSheetAPI/${props.EstimateId}`,{
        const response = await fetch(`${BASE_URI}/AuditSheetAPI/${props.Id}`, {
            method: "POST",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            }
        });
        const responseJSON = await response.json();
        console.log('kapil', responseJSON)
        if (response.status === 200) {
            setAuditButton({
                success: true,
                error: false,
                submitInProgress: false
            });

        }else if(response.status === 406){
                console.log(responseJSON.message);
                setAuditButton({
                    success: false,
                    error: true,
                    submitInProgress: false,
                    message : responseJSON.message
                });
        } else {
            if (response.status === 204 || response.status === 500 || response.status === 404) {
                setAuditButton({
                    success: false,
                    error: true,
                    submitInProgress: false
                });
            } else {
                setAuditButton({
                    success: false,
                    error: true,
                    submitInProgress: false
                })
            }
        }
    }

    // if (estimateType === 'T2') {
    //     onClickSubmitCallback(0)
    // } else {}

    const submitNewInstallCharge = async () => {
        setloaderstate(true)
        const response = await fetch(`${BASE_URI}/SendProjectEstimateAPI1/${props.Id}`, {
            method: "POST",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            },
            // InstalationChargesGlobal/Marginvaluereal/instalationchargesreal

            body: JSON.stringify({
                'margin_value': Marginvaluereal.toString(),
                'installation_charges': instalationchargesreal.toString(),
                'total_charges': InstalationChargesGlobal.toString(),
                'prof_charges_in_product': profCharhesInProduct.toString()
            })
        });
        console.log("kpsp", response.status)
        setstatusProfChargebutton(true)
        setstatusAuditSheetBitton(false)
        setstatusEmailStateButton(false)
        setloaderstate(false)

    }


    const handleInput = event => {
        if (event.target.value >= -100) {
            setmarginvaluereal(parseFloat(event.target.value));
            const TotalCalAmount = instalationchargesreal + instalationchargesreal * event.target.value / 100;
            setInstalationChargesGlobal(parseFloat(TotalCalAmount));
            setprofCharhesInProduct(0)
            setstatusProfChargebutton(false)
            setstatusAuditSheetBitton(true)
            setstatusEmailStateButton(true)

        }
    }
    const handleInputtotal = event => {
        if (event.target.value >= 0) {
            setInstalationChargesGlobal(parseFloat(event.target.value));
            const TotalcalMargin = 100 * (event.target.value - instalationchargesreal) / instalationchargesreal
            // console.log("sandeep",TotalcalMargin)
            setmarginvaluereal(parseFloat(TotalcalMargin.toFixed(2)));
            setprofCharhesInProduct(0)

            setstatusProfChargebutton(false)
            setstatusAuditSheetBitton(true)
            setstatusEmailStateButton(true)
        }
    }


    const handleInputprof = event => {
        console.log(InstalationChargesGlobal)
        if (event.target.value >= 0 && event.target.value <= InstalationChargesGlobal) {
            console.log("hello");
            setprofCharhesInProduct(event.target.value)
            setstatusProfChargebutton(false)
            setstatusAuditSheetBitton(true)
            setstatusEmailStateButton(true)
        }
    }





    const onClickSubmitCallback = async (userInputValue) => {
        console.log(userInputValue)
        console.log(props.Id)
        // if (estimateType === 'T2'){
        //     userInputValue=1;   
        // }
        setOpenInstallationChargesModal(false);
        setEmailButton({ ...emailButton, inProgress: true });
        // const response=await fetch(`${BASE_URI}/EmailEstimateAPI/${props.EstimateId}`,{
        const response = await fetch(`${BASE_URI}/EmailEstimateAPI/${props.Id}`, {
            method: "POST",
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify({
                // 'margin_value': userInputValue['margin'].toString(),
                // 'installation_charges': userInputValue['installation'].toString(),
                // 'total_charges': userInputValue['totalvalue'].toString()
            })
        });

        const responseJSON = await response.json();
        console.log(responseJSON)


        if (response.status === 200) {
            setEmailButton({
                success: true,
                error: false,
                inProgress: false
            });
            // Make editable columns non-editable.
            setEstimateData({
                ...estimateData,
                isExported: true,
                refreshRequired: true,
            }); 
            window.location.replace(responseJSON.message);
            // window.open(responseJSON.message,"_blank");
            
        } else if(response.status === 406){
            console.log(responseJSON.message);
            setEmailButton({
                success: false,
                error: true,
                submitInProgress: false,
                message : responseJSON.message
            });   
        }else {
            if (response.status === 204 || response.status === 500 || response.status === 404) {
                setEmailButton({
                    success: false,
                    error: true,
                    inProgress: false
                });
            } else {
                setEmailButton({
                    success: false,
                    error: true,
                    inProgress: false
                });
            }
        }
    }
    const onClickEmail = async () => {
        if (estimateType === 'T2') {
            setOpenInstallationChargesModal(false);
            setEmailButton({ ...emailButton, inProgress: true });
            // const response=await fetch(`${BASE_URI}/EmailEstimateAPI/${props.EstimateId}`,{
            console.log(props.Id)
            console.log(sessionStorage.getItem('token'))
            const response = await fetch(`${BASE_URI}/EmailEstimateAPI/${props.Id}`, {
                method: "POST",
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    'installation_charges': '0'
                })
            });
            const responseJSON = await response.json();
            console.log(responseJSON)
            // console.log(response)
            if (response.status === 200) {
                setEmailButton({
                    success: true,
                    error: false,
                    inProgress: false
                });
                // window.open(responseJSON.message);
                
                window.location.replace(responseJSON.message);
                // Make editable columns non-editable.
                setEstimateData({
                    ...estimateData,
                    isExported: true,
                    refreshRequired: true,
                });
            }else if(response.status === 406){
                console.log(responseJSON.message);
                setEmailButton({
                    success: false,
                    error: true,
                    submitInProgress: false,
                    message : responseJSON.message
                });
            } else {
                if (response.status === 204 || response.status === 500 || response.status === 404) {
                    setEmailButton({
                        success: false,
                        error: true,
                        inProgress: false
                    });
                } else {
                    setEmailButton({
                        success: false,
                        error: true,
                        inProgress: false
                    });
                }
            }

        } else {
            onClickSubmitCallback(0)
        }

    }
    // Filter Handlers
    const onSearchFilter = (value) => {
        if (value === '' || value === null || value === undefined) {
            setState({ ...state, filteredData: [] });
            setFilter({ ...filter, search: '' });
            return;
        }
        let newFilteredData = state.data.filter(val => {
            if (!val['instanceNumber'] && !val['serialNumber'] && !val['pro_name']) return false;
            // Instance Number [PRESENT] & Serial Number [NOT PRESENT].
            if (val['instanceNumber'] && !val['serialNumber'] && !val['pro_name']) {
                return val['instanceNumber'].toLowerCase().includes(value.toLowerCase());
            }
            // Serial Number [PRESENT] & Instance Number [NOT PRESENT].
            if (val['serialNumber'] && !val['instanceNumber'] && !val['pro_name']) {
                return val['serialNumber'].toLowerCase().includes(value.toLowerCase());
            }
            // Serial Number [PRESENT] & Instance Number [NOT PRESENT].
            if (val['pro_name'] && !val['instanceNumber'] && !val['serialNumber']) {
                return val['pro_name'].toLowerCase().includes(value.toLowerCase());
            }
            // BOTH PRESENT.
            return ((
                val['instanceNumber'].toLowerCase().includes(value.toLowerCase())
                ||
                val['serialNumber'].toLowerCase().includes(value.toLowerCase())
                ||
                val['pro_name'].toLowerCase().includes(value.toLowerCase())
            ))
        });
        setState({ ...state, filteredData: newFilteredData });
        setFilter({ ...filter, search: value });
    }

    const CloseCallback1 = () => {
        setEmailButton({
            success: false,
            error: false,
            inProgress: false,
        });
        setAuditButton({
            success: false,
            error: false,
            inProgress: false,
        });
       
    }
    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <InstallationChargesModal Instalationchargesreal={instalationchargesreal} Open={openInstallationChargesModal} CallbackSubmit={onClickSubmitCallback} OnExit={() => setOpenInstallationChargesModal(false)} />
            <BackdropLoader Open={state.loadingData} />
            <BackdropLoader Open={loaderstate} />


            <BackdropLoader Open={auditButton.submitInProgress} />
            <BackdropLoader Open={emailButton.inProgress} />
            {emailButton.error ? <CustomizedSnackbars Variant="error" Message={emailButton.message?emailButton.message:"Server Error. Please try again."} Open={true}  CloseCallback = {CloseCallback1} /> : null}
            {emailButton.success ? <CustomizedSnackbars Variant="success" Message="Email sent successfully." Open={true} /> : null}
            {auditButton.error ? <CustomizedSnackbars Variant="error" Message={auditButton.message?auditButton.message:"Server Error. Please try again."} Open={true}  CloseCallback = {CloseCallback1}/> : null}
            {auditButton.success ? <CustomizedSnackbars Variant="success" Message={auditButton.message?auditButton.message:"Audit sent on Email successfully."} Open={true} /> : null}
            {state.dataResponseCode === 500 ? <CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true} /> : null}
            {state.dataResponseCode === 404 ? <CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true} /> : null}
            {state.dataResponseCode === 204 ? <CustomizedSnackbars Variant="info" Message="No product data. There is no product data for this." Open={true} /> : null}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{ color: "white" }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {PAGE_HEADER}
                    </Typography>
                    {/* <Button variant="contained" size="small" className={classes.appBarButton} onClick={onClickQuotation} style={{marginRight:10}}>
                            Quotation Form
                        </Button> */}
                    {/* {console.log(estimateType)} */}
                    {
                        estimateType === 'T2' &&
                        <Button
                            variant="contained"
                            size="small"
                            component="label"
                            className={classes.appBarButton}
                            style={{ marginRight: "10px" }}
                            onClick={onOpenModalkp}
                        >
                            Inspection Charges{" "}
                        </Button>
                    }



                    {/*                     
    const [statusProfChargebutton, setstatusProfChargebutton] = useState(true);
    const [statusAuditSheetBitton, setstatusAuditSheetBitton] = useState(true);
    const [statusEmailStateButton, setstatusEmailStateButton] = useState(true); */}


                    {!statusProfChargebutton &&
                        <Button variant="contained" size="small" disabled={statusProfChargebutton} className={classes.appBarButton} onClick={submitNewInstallCharge} style={{ marginRight: 10 }}>
                            Submit Profesional Charges
                        </Button>
                    }
                    {!statusAuditSheetBitton &&
                        <Button variant="contained" size="small" disabled={statusAuditSheetBitton} className={classes.appBarButton} onClick={onClickAudit} style={{ marginRight: 10 }}>
                            Audit Sheet
                        </Button>
                    }
                    {!statusEmailStateButton &&
                        <Button variant="contained" size="small" disabled={statusEmailStateButton} className={classes.appBarButton} onClick={onClickEmail}>
                             Push To Crm
                        </Button>
                    }
                    {/* <Button
                        variant="contained"
                        size="small"
                        component="label"
                        className={classes.appBarButton}
                        style={{ marginRight: "10px" }}
                        onClick={onOpenModalkp}
                    >
                        Inspection Charges{" "}
                    </Button> */}
                </Toolbar>
            </AppBar>
            {
                estimateType === 'T2'
                &&
                <>
                    <SearchInput
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Search serial number / instance number / Part Code..."
                        value={filter.search}
                        onChange={(e) => onSearchFilter(e.target.value)}
                        InputProps={{
                            margin: 'dense',
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    aria-label="clear search filter"
                                    onClick={() => onSearchFilter("")}
                                    style={{ height: "0.2em", width: "0.2em", margin: 0 }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />


                </>
            }



            {
                estimateType !== 'T2' &&
                <>
                    <div style={{ border: "solid #bab4b4 1px", margin: "10px", padding: "1px 20px" }}>
                        <h6>Profesional Charges</h6>
                        <DialogContent>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                label="Margin (%)"
                                size='small'
                                name='input'
                                type='number'
                                variant="outlined"
                                disabled={instalationchargesreal === 0}
                                value={Marginvaluereal}
                                onChange={handleInput}
                            />
                            {/* <br /><br /> */}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                label="Installation Charges"
                                size='small'
                                name='input'
                                type='number'
                                disabled='true'
                                variant="outlined"
                                value={instalationchargesreal}
                            />
                            {/* <br /><br /> */}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                label="Final Amount"
                                size='small'
                                name='input'
                                type='number'
                                variant="outlined"
                                disabled={instalationchargesreal === 0}
                                value={InstalationChargesGlobal}
                                onChange={handleInputtotal}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                label="Professional Charges in Product"
                                size='small'
                                name='input'
                                type='number'
                                variant="outlined"
                                // disabled={profCharhesInProduct === 0}
                                value={profCharhesInProduct}
                                onChange={handleInputprof}
                            // prof_charges_in_product
                            />

                        </DialogContent>


                        {
                            estimateType === 'T1' || estimateType === 'T2'
                                ?
                                <>
                                    <hr></hr>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '4%'
                                    }}
                                    >
                                        Quoted Price Sum (RS): <b>{totalQuotedPrice}</b>
                                    </span>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '4%'
                                    }}
                                    >
                                        Total Quoted Price Sum (RS): <b>{totalQuotedTotal}</b>
                                    </span>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '4%'
                                    }}
                                    >
                                        Quoted Margin Value sum(RS): <b>{totalQuotedmarginvalue}</b>
                                    </span>
                                </>
                                : ''}
                        {
                            estimateType === 'T6' || estimateType === 'T6R' ?
                                <>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '2%'
                                    }}
                                    >
                                        Quoted Pricing Term Unit Price Sum (RS): <b>{QuotedPricingTermUnitPrice}</b>
                                    </span>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '2%'
                                    }}
                                    >
                                        Installment Value Sum (RS): <b>{InstallmentValueSum}</b>
                                    </span>
                                    <span style={{
                                        fontSize: '14px',
                                        margin: '2%'
                                    }}
                                    >
                                        Contract Total Value sum(RS): <b>{ContractTotalValue}</b>
                                    </span>

                                    <span style={{
                                        fontSize: '14px',
                                        margin: '2%'
                                    }}
                                    >
                                        Sum Subscription Credit(RS): <b>{SubscriptionCreditTotalValue}</b>
                                    </span>
                                </> : ''

                        }
                    </div></>
            }


            <React.Fragment>
                <Dialog
                    open={openkp}
                    onClose={handleClosekp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Inspection Charges</DialogTitle>
                    <DialogContent>
                        <table>
                            <tr>
                                <td>
                                    Markup Per Line
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Markup Per Line "
                                        value={markUpPerLineKP}
                                        onChange={(e) => kpmarkupperline(e.target.value)}
                                        helperText={markUpPerLineKP == '' || markUpPerLineKP == null ? "you need to set some value" : ""}
                                        error={markUpPerLineKP == '' || markUpPerLineKP == null}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Discount Per Line
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Discount Per Line "
                                        value={discountPerLineKP}
                                        onChange={(e) => kpdiscountperline(e.target.value)}
                                        helperText={discountPerLineKP == '' || discountPerLineKP == null ? "you need to set some value" : ""}
                                        error={discountPerLineKP == '' || discountPerLineKP == null}
                                    />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    List Price
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        placeholder="List Price "
                                        value={ListPriseKP}
                                        onChange={(e) => kplistprice(e.target.value)}
                                        helperText={ListPriseKP == '' || ListPriseKP == null ? "you need to set some value" : ""}
                                        error={ListPriseKP == '' || ListPriseKP == null}
                                    />
                                </td>
                            </tr>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                        <Button onClick={handleClosekp} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                    <div style={{ color: "red", margin: "10px 28px" }}>{inspectionglobalerror == true ? "some error from server" : null}</div>
                </Dialog>
            </React.Fragment>





            {console.log(columnHeaders)}
            <ReactTable
                className="-striped table-style"
                style={{ overflow: "wrap", width: "100vw", height: "90vh" }}
                previousText="<"
                nextText=">"
                defaultPageSize={18}
                resizable={false}
                data={Boolean(filter.search) ? state.filteredData : state.data}
                showPageJump={false}
                columns={columnHeaders}
                getTheadProps={() => {
                    return {
                        style: { background: 'black', color: 'whitesmoke' }
                    }
                }}
                getTbodyProps={() => {
                    return {
                        style: {
                            overflowX: "hidden",
                        }
                    }
                }}
                getTdProps={() => {
                    return {
                        style: {
                            textAlign: 'center',
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                            fontSize: '0.75em',
                            padding: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                        }
                    }
                }}
            />
            {/* <QuotationEstimateForm 
                  handleCloseQuotationForm={handleCloseQuotationForm} 
                  dialogOpen={openQuotationForm}
                /> */}
        </Dialog>
    );
}

const SearchInput = withStyles({
    root: {
        width: "40%",
        justifyContent: "center",
        '& .MuiOutlinedInput-root': {
            height: "75%",
            marginLeft: '5px',
            marginTop: '1px',
            marginBottom: '1px',
            marginRight: '5px',
            borderRadius: '10px',
            '&. Mui-focused fieldset': {
                borderWidth: '0.5px',
                borderColor: 'black',
            },
        },
    },
})(TextField);

export default EstCalcDetailsModal;