import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  InputAdornment,
  Typography,
  Slide,
  Button,
  TextField
} from "@material-ui/core";
// Material UI Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// Custom components and modules.
import CustomizedSnackbars from "components/notification/CustomizedSnackbars";
import BackdropLoader from "components/loaders/BackdropLoader";
import { ExportCSV } from "quotationSystem/ExportCSVData/ExportCSV";
import { EstimateTableContext } from "quotationSystem/EstimateTableContext";
import EstCalcDetailsModal from "quotationSystem/Modals/EstCalcDetailsModal";
import EstimateViewProductTable from "quotationSystem/Table/EstimateViewProductTable/EstimateViewProductTable";
import ConfirmDialog from "components/ConfirmDialog";
import ConfirmDialognew from "components/ConfirmDialognew";
import * as XLSX from "xlsx";
import fetchAPI from "fetchAPI";
import { QUOTATION_SYSTEM_ENDPOINTS } from "endpoints";
import exportFile from 'quotationSystem/exportTemplateCoverageRestriction';
import { templateColumnsMap } from "quotationSystem/exportTemplateCoverageRestriction";
import readExcel from 'pmcSystem/readExcel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// Utilities
import excelDateNumber from "utility/excelDateNumber";
import { ContactSupportOutlined } from "@material-ui/icons";
const BASE_URI = process.env.NODE_ENV === 'development' ? '' : '/pro';
const PAGE_HEADER = "CCW Download Page";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    // backgroundImage:
    //   " linear-gradient(to left, #c8c8c8, #c4c3cd, #bfbfd2, #b8bbd7, #b0b7dc, #b0b7dc, #b0b7dc, #b0b7dc, #b8bbd7, #bfbfd2, #c4c3cd, #c8c8c8)",
    background: "rgb(47, 38, 85)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
    fontWeight: 600,
  },
  appBarButton: {
    backgroundColor: "#9ba3cf",
    "&:hover": {
      backgroundColor: "#5c66a1",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CCWDownloadPageModal = (props) => {
  console.log(props)
  const classes = useStyles();
  const {
    viewProductContext,
    emailButtonClickedContext,
    viewEstCalcContext,
    optionTypeContext,
    estimateTypeContext,
    estimateDataContext,
  } = useContext(EstimateTableContext);
  const [, setViewProductsActive] = viewProductContext;
  const [emailButtonClicked] = emailButtonClickedContext;
  const [viewEstCalcModal, setViewEstCalcModal] = viewEstCalcContext;
  const [optionType] = optionTypeContext;
  const [estimateType] = estimateTypeContext;
  const [estimateData] = estimateDataContext;

  const [open, setOpen] = useState(false);
  const [openinspection, setOpeninspection] = useState(false);
  const [userInputValue, setUserInputValue] = useState(0);
  const [InstalationChargesGlobal, setInstalationChargesGlobal] = useState(0);
  const [Marginvaluereal, setmarginvaluereal] = useState(0);
  const [instalationchargesreal, setinstalationchargesreal] = useState(0);
  const [state, setState] = useState({
    loadingData: false,
    data: [],
    filteredData: [],
    estCalcData: [],
    dataResponseCode: 0,
    getallquatationprice: 0,
  });
  const [deleteRowStates, setDeleteRowStates] = useState({
    loading: false,
    error: false,
    success: false,
    msg: '',
  });
  const [errorStates, setErrorStates] = useState({
    err: false,
    msg: ''
  });
  // console.log(estimateType)
  // console.log(optionType)
  const [changedDataDetails, setChangedDataDetails] = useState(null);
  const [CsvDATA, setCsvDATA] = useState([]);
  const [importCsvData, setImportCsvData] = useState([]);
  const [exportHeadersList, setExportHeadersList] = useState([]);
  const [editableColumns, setEditableColumns] = useState([]);
  const [uploadFileName, setUploadFileName] = useState('');


  const [markUpPerLineKP, setmarkUpPerLineKP] = useState(0);
  const [discountPerLineKP, setdiscountPerLineKP] = useState(0);
  const [ListPriseKP, setListPriseKP] = useState(0);


  const [udateendCustomerPrice, setudateendCustomerPrice] = useState(state.getallquatationprice);
  const [udatesubscription_credit_sum, setudatesubscription_credit_sum] = useState(state.getallquatationprice);
  const [totalListPrice, settotalListPrice] = useState(null);
  const [filter, setFilter] = useState({
    search: ''
  });

  const [openkp, setOpenkp] = useState(false);

  const onOpenModalkp = () => setOpenkp(true);
  const onCloseModalkp = () => setOpenkp(false);


  // For handling information related to file I/O of xlsx.
  const [fileInfo, setFileInfo] = useState({
    headersStartCell: "",
    metaData: 0,
  });
  // console.log(state.data)
  // console.log(state.data.length >0 ? state.data.reduce((accumulator, current) => accumulator + current.quoted_rate) : 'fghfghfhfghfgh') 
  const stylekp = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    Zindex: 90000,
  };




  const handleInput = event => {
    if (event.target.value >= -100) {
      setmarginvaluereal(parseFloat(event.target.value));
      const TotalCalAmount = instalationchargesreal + instalationchargesreal * event.target.value / 100;
      setInstalationChargesGlobal(parseFloat(TotalCalAmount));
    }
  }
  const handleInputtotal = event => {
    if (event.target.value >= 0) {
      setInstalationChargesGlobal(parseFloat(event.target.value));
      const TotalcalMargin = 100 * (event.target.value - instalationchargesreal) / instalationchargesreal
      // console.log("sandeep",TotalcalMargin)
      setmarginvaluereal(parseFloat(TotalcalMargin.toFixed(2)));
    }
  }

  function sumProperty(arr, type) {
    if (arr.length > 0) {
      return arr.reduce((total, obj) => {
        if (typeof obj[type] === 'string') {

          return total + Number(obj[type]);
        }
        return total + obj[type];
      }, 0);
    }
  }



  // For error handling. Possible values : 'SUCCESS', 'ERROR'.
  const [importErrorMsg, setImportErrorMsg] = useState(
    "There was some problem importing the file. Try again."
  );
  const ImportSuccessMsg = "File imported. Table is updated.";
  const [importingFile, setImportingFile] = useState("");

  // useEffect() functions.
  useEffect(() => {
    setState({ ...state, loadingData: true });
    setOpen(props.Open);
    if (estimateType !== "T2") {
      getInstallCharg()
    }
  }, []);

  useEffect(() => {
    if (props.Open) {
      getData();
    }
  }, [props.Open]);


  const getInstallCharg = async () => {
    const response = await fetch(`${BASE_URI}/ProjectEstimateAPI/${props.Id}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${sessionStorage.getItem('token')}`
      },
      // body: JSON.stringify({
      //     'installation_charges': '0'
      // })
    });
    const responseJSON = await response.json();
    console.log('kapil', responseJSON)

    if (response.status === 200) {
      setInstalationChargesGlobal(responseJSON['installation_charges'] + (responseJSON['installation_charges'] * responseJSON['margin_value'] / 100))
      setinstalationchargesreal(responseJSON['installation_charges'])
      setmarginvaluereal(responseJSON['margin_value'])
      // setOpenInstallationChargesModal(true);
    }
  }
  // Data handling functions.
  // Gets data for CCWDownloadPageModal table.
  const getData = async () => {
    const { responseData, statusCode, error } = await fetchAPI(
      `${QUOTATION_SYSTEM_ENDPOINTS.ESTIMATE_PRODUCT_DETAILS}${props.Id}`,
      "GET"
    );
    // console.log(responseData)
    if (statusCode === 200 && !error) {
      setState({
        ...state,
        loadingData: false,
        data: responseData,
        dummydata: responseData,
        dataResponseCode: statusCode,
      });
      // .toFixed(2)

      // money.toFixed(2)
      var nf = new Intl.NumberFormat()
      // console.log(responseData)
      if (responseData.length > 0 && responseData[0]['list_price']) {

        responseData.length > 0 && settotalListPrice(nf.format(sumProperty(responseData, 'list_price').toFixed(2)))
      } else {

        responseData.length > 0 && settotalListPrice(nf.format(sumProperty(responseData, 'pricing_listPrice').toFixed(2)))
      }
      responseData.length > 0 && setudateendCustomerPrice(sumProperty(responseData, 'quoted_total').toFixed(2))
      responseData.length > 0 && setudatesubscription_credit_sum(sumProperty(responseData, 'subscription_credit').toFixed(2))
      // responseData.length > 0 && settotalListPrice(nf.format(sumProperty(responseData, 'pricing_listPrice').toFixed(2)))
    } else if (statusCode === 404 || statusCode === 500) {
      setState({
        ...state,
        loadingData: false,
        data: [],
        dataResponseCode: statusCode,
      });
    } else {
      setState({
        ...state,
        loadingData: false,
        data: [],
        dataResponseCode: 404,
      });
    }
  }
  // Sends changed table data.
  const postChangedData = async (bodyData) => {
    // console.log(estimateData)
    // console.log(bodyData)
    const { responseData, statusCode, error } = await fetchAPI(
      `${QUOTATION_SYSTEM_ENDPOINTS.SEND_CHANGED_TABLE_DATA}${props.Id}`,
      "POST",
      bodyData
    );
    // console.log(responseData)

    if (statusCode === 200 && !error) {
      setState({
        ...state,
        loadingData: false,
        estCalcData: responseData,
        dataResponseCode: statusCode,
      });
      if (estimateType === "T2" && optionType === "5") {
        getData()
      }

      setViewEstCalcModal(true);
    } else if (statusCode === 404 || statusCode === 500) {
      setState({
        ...state,
        loadingData: false,
        data: [],
        dataResponseCode: statusCode,
      });
      setViewEstCalcModal(false);
    } else {
      setState({
        ...state,
        loadingData: false,
        data: [],
        dataResponseCode: 404,
      });
      setViewEstCalcModal(false);
    }
  }
  // Post Uploaded Coverage Restriction Excel.
  const postHoldItems = async (bodyData) => {

    const { responseData, statusCode, error } = await fetchAPI(
      `${QUOTATION_SYSTEM_ENDPOINTS.UPLOAD_HOLD_ITEMS}${estimateData.uid}`,
      "POST",
      bodyData
    );
    if (statusCode === 200 && !error) {
      getData();
    } else {
      setState({
        ...state,
        loadingData: false
      });
      setErrorStates({
        err: true,
        msg: 'There was some problem submitting the uploaded data. Try again.'
      });
    }

  }
  // Delete selected rows API.
  const deleteRowReq = async (deleteRowsDetails) => {
    setDeleteRowStates({
      ...deleteRowStates,
      loading: true
    });
    const bodyData = [];
    deleteRowsDetails.forEach(value => {
      bodyData.push({ 'id': value });
    });
    const { responseData, statusCode, error } = await fetchAPI(
      `${QUOTATION_SYSTEM_ENDPOINTS.DELETE_VIEW_PRODUCTS_ROWS}${props.Id}`,
      'DELETE',
      bodyData
    );
    if (statusCode === 200 && !error) {
      setDeleteRowStates({
        ...deleteRowStates,
        loading: false,
        success: true,
        error: false,
        msg: 'Rows deleted. Success.',
      });
      // Refresh table data.
      setState({ ...state, loadingData: true });
      await getData();
    } else {
      setDeleteRowStates({
        ...deleteRowStates,
        loading: false,
        error: true,
        success: false,
        msg: 'Unable to delete rows. Please try again.'
      });
    }
  }


  // Button Handlers.
  // Handles closing of CCWDownloadPageModal.
  const handleClose = () => {
    setOpen(false);
    setViewProductsActive(false);
    if (props?.refreshData) {
      props.refreshData();
    }
  };



  const onClickInspection = () => {
    setOpeninspection(true)
  }


  const subscription_credit_sumPriceAdd = (e) => {
    setudatesubscription_credit_sum(e)
  }

  const endCustomerPriceAdd = (e) => {
    setudateendCustomerPrice(e)
  }
  // const ref = useRef(null);
  const kpmarkupperline = (e) => {
    // alert(e)
    if (e < 0 || e > 100) {
      setmarkUpPerLineKP(markUpPerLineKP)
    } else {
      setmarkUpPerLineKP(e)
    }
  }
  const kpdiscountperline = (e) => {
    // setdiscountPerLineKP(e)
    if (e < 0 || e > 100) {
      setdiscountPerLineKP(discountPerLineKP)
    } else {
      setdiscountPerLineKP(e)
    }
  }
  const kplistprice = (e) => {
    // setListPriseKP(e)
    if (e < 0) {
      setListPriseKP(ListPriseKP)
    } else {
      setListPriseKP(e)
    }
  }
  // Handles Submit button.
  const onClickSubmit = async () => {
    // let bodyData;
    if (estimateType !== "T2") {
      await submitNewInstallCharge();
    }
    if (estimateType === "T2" && optionType === "5") {
      if (udateendCustomerPrice === 0 || udateendCustomerPrice === '') {

      } else {
        setState({ ...state, loadingData: true });
        let bodyData = { 'lines': [], 'quoted_rate': udateendCustomerPrice };
        if (changedDataDetails != null) {
          for (const [, value] of Object.entries(changedDataDetails)) {
            bodyData.lines.push(value);
          }
        }
        // console.log(bodyData)
        // console.log(changedDataDetails)

        await postChangedData(bodyData);
      }
    } else if (estimateType === "T6" || estimateType === "T6R") {
      if (udatesubscription_credit_sum === 0 || udatesubscription_credit_sum === '') {

      } else {
        setState({ ...state, loadingData: true });
        let bodyData = { 'lines': [], 'subscription_credit_sum': udatesubscription_credit_sum };
        if (changedDataDetails != null) {
          for (const [, value] of Object.entries(changedDataDetails)) {
            bodyData.lines.push(value);
          }
        }
        // console.log(bodyData)
        // console.log(changedDataDetails)

        await postChangedData(bodyData);

      }
    } else {
      setState({ ...state, loadingData: true });
      let bodyData = []
      if (changedDataDetails != null) {
        for (const [, value] of Object.entries(changedDataDetails)) {
          // console.log(value)
          bodyData.push(value);
        }
      }
      // console.log(bodyData)
      // console.log(changedDataDetails)

      await postChangedData(bodyData);

    }

    // {
    //   "lines":[{},{}],
    //   "quoted_rate":"55454"
    //   }

  };

  const submitNewInstallCharge = async () => {
    const response = await fetch(`${BASE_URI}/SendProjectEstimateAPI1/${props.Id}`, {
      method: "POST",
      headers: {
        Authorization: `Token ${sessionStorage.getItem('token')}`
      },
      // InstalationChargesGlobal/Marginvaluereal/instalationchargesreal

      body: JSON.stringify({
        'margin_value': Marginvaluereal.toString(),
        'installation_charges': instalationchargesreal.toString(),
        'total_charges': InstalationChargesGlobal.toString()
      })
    });
    console.log("kpsp", response.status)
  }
  // TODO: Implement read excel file using exceljs instead of xlsx. 
  // **NOTE: Not valid for T2 Coverage Restriction Excel Upload Functionality. **
  const importReadExcel = async (file) => {
    try {
      const fileReader = new FileReader();
      var metaDataCheck = true;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        try {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          // Get the range we are reading data from. Eg. cell A1:L9 etc.
          var currentRange = ws["!ref"].split(":");
          // Now we skip metadata rows.
          currentRange[0] = fileInfo.headersStartCell;
          currentRange = currentRange.join(":");
          ws["!ref"] = currentRange;
          // Get metadata that was set when exporting file.
          const metaData = fileInfo.metaData;
          // console.log(metaData)
          // Match metadata from imported file.
          metaData.every((meta) => {
            if (meta.value !== ws[meta.valueCellStart].v) {
              setImportErrorMsg(
                `${meta.title} not matching. Import not allowed.`
              );
              metaDataCheck = false;
              return false;
            }
            return true;
          });
          if (!metaDataCheck) throw new Error("Metadata");

          const data = XLSX.utils.sheet_to_json(ws);
          // console.log(data)
          setImportCsvData(data);
          setImportingFile("SUCCESS");
        } catch (err) {
          setImportingFile("ERROR");
        }
      };
    } catch (err) {
      fileReadError();
    }
  };
  const updateChangedDataDetails = (details) => {
    setChangedDataDetails(details);
  };
  const fileNameCsvData = `${estimateData.estimateId} Quotation Form ${estimateData.version}`;

  const processUploadComplete = (readData) => {
    setImportCsvData(readData);
    setState({
      ...state,
      loadingData: false
    });
  }
  // Handle file upload for coverage restrictions.
  const handleFileUploadCoverageRestriction = async (e) => {
    setUploadFileName(e.target.files[0].name);
    setState({
      ...state,
      loadingData: true
    });
    readExcel(
      e.target.files[0],
      {
        headersStartRow: 4,
        metaDataRows: 1
      },
      processUploadComplete,
      fileReadError,
      true
    );
  }
  const handleSubmitUploadCoverageRestriction = async () => {
    // Prepare data from read excel file. Stored in importCsvData state variable.
    setState({
      ...state,
      loadingData: true
    });
    var bodyData = [];
    importCsvData.forEach((row) => {
      var pushVal = {};
      for (const [key, value] of Object.entries(templateColumnsMap)) {
        pushVal[value] = row[key];
        // if (value === 'startDateActive' || value === 'endDateActive') {
        //   pushVal[value] = excelDateNumber(row[key]);
        // }


        if (value === "startDateActive" || value === "endDateActive") {
          // console.log(row[key])
          var utc_days = Math.floor(row[key] - 25569);
          var utc_value = utc_days * 86400;
          var date_info = new Date(utc_value * 1000);

          var fractional_day = row[key] - Math.floor(row[key]) + 0.0000001;
          var total_seconds = Math.floor(86400 * fractional_day);

          var seconds = total_seconds % 60;

          total_seconds -= seconds;

          var hours = Math.floor(total_seconds / (60 * 60));
          var minutes = Math.floor(total_seconds / 60) % 60;
          // console.log(new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds))
          if (new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds) != 'Invalid Date') {
            // console.log(new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds))
            // console.log(excelDateNumber(row[key]))
            pushVal[value] = excelDateNumber(row[key]);


          } else {
            // console.log('error')
            setErrorStates({
              err: true,
              msg: "Invalid date.please use YYYY/MM/DD and Try again.",
            });
          }
          // if(row[key].indexOf("-") !== -1){
          // console.log(row[key]);
          // console.log(row[key].indexOf('-',1));
          // console.log(row[key].indexOf('-',2));
          // }
          // console.log(row[key])
          // console.log(excelDateNumber(row[key]))


        }
      }
      bodyData.push(pushVal);
    });
    // ## TODO: Call post request function here passing bodyData.
    await postHoldItems(bodyData);
  }
  // Reset states
  const resetDeleteRowStates = () => {
    setDeleteRowStates({
      loading: false,
      error: false,
      success: false,
      msg: ''
    });
  }
  const resetErrorStates = () => {
    setErrorStates({
      err: false,
      msg: ''
    });
  }
  const fileReadError = async () => {
    setImportErrorMsg(
      "There was some problem importing the file. Try again."
    );
    setImportingFile("ERROR");
  }

  // Other components.
  // Component for importing file dialog that appears.
  const ImportDialogComp = () => {
    return (
      <>
        <input
          style={{ display: "none" }}
          id="upload-file"
          type="file"
          onChange={handleFileUploadCoverageRestriction}
        />
        {
          uploadFileName
            ?
            <span style={{ marginRight: '2rem' }}>
              <CloudUploadIcon />{uploadFileName}
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setUploadFileName('')}
                aria-label="close"
                style={{ color: "black" }}
              >
                <CloseIcon />
              </IconButton>
            </span>
            :
            <Button
              variant="contained"
              size="small"
              component="label"
              className={classes.appBarButton}
              startIcon={<CloudUploadIcon />}
              style={{ marginRight: "10px" }}
              htmlFor="upload-file"
            >
              Upload
            </Button>
        }
        <Button
          variant="contained"
          size="small"
          component="label"
          className={classes.appBarButton}
          startIcon={<CloudDownloadIcon />}
          style={{ marginRight: "10px" }}
          onClick={() => exportFile()}
        >
          Download Template
        </Button>
      </>
    );
  }




  //INSPECTION CHARGES DILOG BOX

  // const InspectionChardesPopup = () => {
  //   return (
  //     <>
  //       <hr />
  //       <table>
  //         <tr>
  //           <td>
  //             Markup Per Line
  //           </td>
  //           <td>
  //             :
  //           </td>
  //           <td>
  //             <TextField
  //               type="number"
  //               variant="outlined"
  //               size="small"
  //               // ref={input => input && input.focus()}
  //               ref={ref}
  //               placeholder="Markup Per Line "
  //               value={markUpPerLineKP}
  //               onChange={(e) => kpmarkupperline(e.target.value)}
  //             // helperText={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? "" : "you need to set some value"}
  //             // error={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? false : true}
  //             />
  //           </td>
  //         </tr>
  //         <tr>
  //           <td>
  //             Discount Per Line
  //           </td>
  //           <td>
  //             :
  //           </td>
  //           <td>
  //             <TextField
  //               type="number"
  //               variant="outlined"
  //               size="small"
  //               placeholder="Discount Per Line "
  //               value={discountPerLineKP}
  //               onChange={(e) => kpdiscountperline(e.target.value)}
  //             // helperText={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? "" : "you need to set some value"}
  //             // error={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? false : true}
  //             />

  //           </td>
  //         </tr>
  //         <tr>
  //           <td>
  //             List Price
  //           </td>
  //           <td>
  //             :
  //           </td>
  //           <td>
  //             <TextField
  //               type="number"
  //               variant="outlined"
  //               size="small"
  //               placeholder="List Price "
  //               value={ListPriseKP}
  //               onChange={(e) => kplistprice(e.target.value)}
  //             // helperText={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? "" : "you need to set some value"}
  //             // error={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? false : true}
  //             />
  //           </td>
  //         </tr>
  //       </table>


  //       <hr />
  //     </>
  //   );
  // }
  // Filter Handlers
  const onSearchFilter = (value) => {
    if (value === '' || value === null || value === undefined) {
      setState({ ...state, filteredData: [] });
      setFilter({ ...filter, search: '' });
      return;
    }

    let newFilteredData = state.data.filter(val => {
      // console.log(val['pro_name'])
      // console.log(value)
      // console.log(val)
      // BOTH FIELDS [NOT PRESENT].
      if (!val['instanceNumber'] && !val['serialNumber'] && !val['pro_name']) return false;
      // Instance Number [PRESENT] & Serial Number [NOT PRESENT].
      if (val['instanceNumber'] && !val['serialNumber'] && !val['pro_name']) {
        return val['instanceNumber'].toLowerCase().includes(value.toLowerCase());
      }
      // Serial Number [PRESENT] & Instance Number [NOT PRESENT].
      if (val['serialNumber'] && !val['instanceNumber'] && !val['pro_name']) {
        return val['serialNumber'].toLowerCase().includes(value.toLowerCase());
      }
      // Serial Number [PRESENT] & Instance Number [NOT PRESENT].
      if (val['pro_name'] && !val['instanceNumber'] && !val['serialNumber']) {
        return val['pro_name'].toLowerCase().includes(value.toLowerCase());
      }
      // BOTH PRESENT.
      return ((
        val['instanceNumber'].toLowerCase().includes(value.toLowerCase())
        ||
        val['serialNumber'].toLowerCase().includes(value.toLowerCase())
        ||
        val['pro_name'].toLowerCase().includes(value.toLowerCase())
      ))
    });
    setState({ ...state, filteredData: newFilteredData });
    setFilter({ ...filter, search: value });
  }



  //   var  totalAmount = ( sumProperty(state.data, 'quoted_rate') ).toFixed(2); 
  // console.log(  totalAmount  ); 
  // Main Component Rendering.
  return (
    <React.Fragment>


      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <BackdropLoader Open={deleteRowStates.loading} />
        <BackdropLoader Open={state.loadingData} />
        <BackdropLoader Open={emailButtonClicked} />
        <CustomizedSnackbars
          Open={deleteRowStates.success || deleteRowStates.error}
          Variant={deleteRowStates.success ? 'success' : 'error'}
          Message={deleteRowStates.msg}
          EnableTimer={deleteRowStates.success}
          Timer={3000}
          CloseCallback={resetDeleteRowStates}
          OnExit={resetDeleteRowStates}
        />
        <CustomizedSnackbars
          Open={errorStates.err}
          Variant={'error'}
          Message={errorStates.msg}
          OnExit={resetErrorStates}
        />
        {state.dataResponseCode === 500 && (
          <CustomizedSnackbars
            Variant="error"
            Message="Server Error. Please try refresh."
            Open={true}
          />
        )}
        {state.dataResponseCode === 404 && (
          <CustomizedSnackbars
            Variant="error"
            Message="Server Error. Please contact administrator."
            Open={true}
          />
        )}
        {state.dataResponseCode === 204 && (
          <CustomizedSnackbars
            Variant="info"
            Message="No product data. There is no product data for this."
            Open={true}
          />
        )}
        {importingFile === "ERROR" && (
          <CustomizedSnackbars
            Variant="error"
            Message={importErrorMsg}
            Open={true}
            CloseCallback={() => setImportingFile("")}
          />
        )}
        {importingFile === "SUCCESS" && (
          <CustomizedSnackbars
            Variant="success"
            Message={ImportSuccessMsg}
            Open={true}
            CloseCallback={() => setImportingFile("")}
          />
        )}
        <AppBar className={classes.appBar}>
          <Toolbar>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ color: "white" }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {PAGE_HEADER}
            </Typography>
            {/* <TextField id="time" type="text" /> */}


            <ExportCSV
              csvData={CsvDATA}
              fileName={fileNameCsvData}
              exportHeadersList={exportHeadersList}
              editableColumns={editableColumns}
              setFileInfo={setFileInfo}
            />
            {/* {
              estimateType !== "T2" && */}
            {/* {estimateType === "T2" && optionType ==="5" ?"": */}

            <Button
              variant="contained"
              size="small"
              component="label"
              className={classes.appBarButton}
              style={{ marginRight: "10px" }}
            >
              Import{" "}
              <input
                type="file"
                hidden
                onChange={(e) => importReadExcel(e.target.files[0])}
              />
            </Button>
            {/* } */}
            {/* } */}
            {/* Uploading for T2 */}
            {
              estimateType === "T2" &&
              <ConfirmDialog
                dialogTitle="Upload Hold Items"
                confirmLabel="Submit"
                cancelLabel="Cancel"
                confirmCallback={handleSubmitUploadCoverageRestriction}
                renderButton={(handleOpen) =>
                  <Button
                    variant="contained"
                    size="small"
                    component="label"
                    className={classes.appBarButton}
                    style={{ marginRight: "10px" }}
                    onClick={handleOpen}
                  >
                    Upload Hold Items{" "}
                  </Button>
                }
                DialogContentComp={ImportDialogComp}
              />
            }
            <Button
              variant="contained"
              size="small"
              className={classes.appBarButton}
              onClick={onClickSubmit}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>



        {
          estimateType === 'T2'
          &&
          <div>

            <SearchInput
              type="text"
              variant="outlined"
              size="small"
              placeholder="Search serial number / instance number / Part Number..."
              value={filter.search}
              onChange={(e) => onSearchFilter(e.target.value)}
              InputProps={{
                margin: 'dense',
                startAdornment: (
                  <InputAdornment position="start" >
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="clear search filter"
                    onClick={() => onSearchFilter("")}
                    style={{ height: "0.2em", width: "0.2em", margin: 0 }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            {optionType === "5" &&
              <span style={{ float: 'right', marginRight: ' 1%' }}>
                <b>End Customer Price (Rs) :</b>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="End Customer Price (Rs)"
                  value={udateendCustomerPrice}
                  onChange={(e) => endCustomerPriceAdd(e.target.value)}
                  helperText={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? "" : "you need to set some value"}
                  error={udateendCustomerPrice != 0 || udateendCustomerPrice != 0 ? false : true}
                />
              </span>
            }


          </div>
        }


        {
          estimateType !== 'T2' &&
          <div style={{ border: "solid #bab4b4 1px", margin: "10px", padding: "1px 20px" }}>
            {/* <h6>Profesional Charges</h6> */}
            <DialogContent>

              <span style={{
                fontSize: '14px',
                margin: '0% 4%'
              }}
              >
                Total List Price (RS): <b>{totalListPrice} </b>
              </span>


              &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;

              {
                (estimateType === 'T6' || estimateType === 'T6R')
                &&
                <span style={{ float: 'right', marginRight: ' 1%' }}>
                  {/* <b>Purchase Credit Total:</b> */}
                  <TextField
                    label="Purchase Credit Total"
                    type="number"
                    variant="outlined"
                    size="small"
                    placeholder="Purchase Credit Total"
                    value={udatesubscription_credit_sum}
                    onChange={(e) => subscription_credit_sumPriceAdd(e.target.value)}
                  />
                </span>
              }
            </DialogContent>

          </div>
        }
        {/* {
          (estimateType === 'T6' || estimateType === 'T6R')
          &&
          <div>
            <span style={{ float: 'right', marginRight: ' 1%' }}>
              <b>Purchase Credit Total:</b>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                placeholder="Purchase Credit Total"
                value={udatesubscription_credit_sum}
                onChange={(e) => subscription_credit_sumPriceAdd(e.target.value)}
              // helperText={udatesubscription_credit_sum != 0 || udatesubscription_credit_sum != 0 ? "" : "you need to set some value"}
              // error={udatesubscription_credit_sum != 0 || udatesubscription_credit_sum != 0 ? false : true}
              />
            </span>
          </div>
        } */}



        {!state.loadingData ? (
          <EstimateViewProductTable
            data={Boolean(filter.search) ? state.filteredData : state.data}
            deleteRowReqCallback={deleteRowReq}
            importCsvData={importCsvData}
            setChangedDataDetails={updateChangedDataDetails}
            setCsvDATA={setCsvDATA}
            estimateId={props.EstimateId}
            optionType={optionType}
            estimateType={estimateType}
            setExportHeadersList={setExportHeadersList}
            setEditableColumns={setEditableColumns}
            disableEdit={props.disableEdit}
          />
        ) : null}



        {
          (estimateType === 'T6' || estimateType === 'T6R')
          &&
          <span style={{ float: 'right', marginRight: ' 1%' }}>
            {/* <b>Purchase Credit Total:</b> */}
            <TextField
              label="Purchase Credit Total"
              type="number"
              variant="outlined"
              size="small"
              placeholder="Purchase Credit Total"
              value={udatesubscription_credit_sum}
              onChange={(e) => subscription_credit_sumPriceAdd(e.target.value)}
            />
          </span>
        }
      </Dialog>





      {viewEstCalcModal ? (
        <EstCalcDetailsModal
          Open={viewEstCalcModal}
          Data={state.estCalcData}
          Id={props.Id}
        />
      ) : null}
    </React.Fragment>
  );
};

const SearchInput = withStyles({
  root: {
    width: "40%",
    justifyContent: "center",
    '& .MuiOutlinedInput-root': {
      height: "75%",
      marginLeft: '5px',
      marginTop: '1px',
      marginBottom: '1px',
      marginRight: '5px',
      borderRadius: '10px',
      '&. Mui-focused fieldset': {
        borderWidth: '0.5px',
        borderColor: 'black',
      },
    },
  },
})(TextField);

export default CCWDownloadPageModal;
