import React,{Component} from 'react';
import 'css/views/adduserview.css';
import { USER_TYPE } from 'globalConstants';

const BASE_URI='/pro';

const USERS = [
    {
        value: USER_TYPE.USER,
        name: 'User' 
    },
    {
        value: USER_TYPE.CASHFLOW_SYSTEM,
        name: 'Cash Flow User'
    },
    {
        value: USER_TYPE.ADMIN,
        name: 'Admin/User'
    },
    {
        value: USER_TYPE.QUOTATION_SYSTEM,
        name: 'Quotation User'
    },
    {
        value: USER_TYPE.PMC_USER,
        name: 'PMC User'
    },
    {
        value: USER_TYPE.PMC_ADMIN,
        name: 'PMC Admin'
    }
];
class AddUserView extends Component{
    state={
        userAdded:false,
        errorMessage:'',
        token:'',
    }
    handleAddUserSubmit=async(event)=>{
        event.preventDefault();
        const data=new FormData(event.target);
        let newUserData={
            firstName:data.getAll('firstname')[0],
            lastName:data.getAll('lastname')[0],
            emailId:data.getAll('emailid')[0],
            password:data.getAll('password')[0],
            userType:data.getAll('usertype')[0],
            employeeId:data.getAll('employeeid')[0],
        }
        const response=await fetch(`${BASE_URI}/user_create/`,{
            method:'POST',
            headers:{
                Authorization:`Token ${this.state.token}`
            },
            body:JSON.stringify({
                first_name:newUserData.firstName,
                last_name:newUserData.lastName,
                user_type:newUserData.userType,
                email:newUserData.emailId,
                password:newUserData.password,
                emp_id:newUserData.employeeId,
            })
        });
        if(response.status===200){
            this.setState({
                userAdded:true,
            })
        }else{
            this.setState({
                userAdded:false,
                errorMessage:'Email Id already registered (exists).',
            });
        }
    }
    handleCancel=(event)=>{
        event.preventDefault();
        sessionStorage.clear();
        this.props.history.replace('/');
    }
    componentDidMount(){
        this.setState({
            token:sessionStorage.getItem('token'),
        })
    }
    handleAddAnotherUser=()=>{
        this.setState({
            userAdded:false,
        });
    }
    render(){
        return (
        <div className="adduser-view">
            <h3 className="mt-3 adduser-header">Add New User Form</h3>
            <hr/>
            {this.state.userAdded
            ?
        <div style={{textAlign:"center"}}>
                <p> User Added.</p>
                <button className="form-button btn btn-primary" onClick={this.handleAddAnotherUser}>Add User</button>
                <button className="form-button btn btn-danger ml-2" onClick={this.handleCancel}>Exit</button>
            </div>
            :
            <div>
                <form className="adduser-form" method="post" onSubmit={this.handleAddUserSubmit}>
                    <div className="form-group">
                        <label>First Name:</label>
                        <input name="firstname" className="form-control input-field" placeholder="First Name" required/>
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <input name="lastname" className="form-control input-field" placeholder="Last Name" required/>
                    </div>
                    <div className="form-group">
                        <label>Employee Id:</label>
                        <input name="employeeid" className="form-control input-field" placeholder="Employee Id" required/>
                    </div>
                    <div className="form-group">
                        <label>Email Id:</label>
                        <input name="emailid" type="email" className="form-control input-field" placeholder="Email Id" required/>
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input name="password"
                        type="password"
                        className="form-control input-field"
                        placeholder="Password"
                        pattern=".{8,}"
                        title="8 characters minimum"
                        required/>
                    </div>
                    <div className="form-group">
                        <label>User Type:</label>
                        <select name="usertype" className="ml-2 select-user-type">
                            {
                                USERS.map((user, index) => (
                                    <option value={user.value} key={index}>{user.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    {this.state.errorMessage!==''?<p style={{color:'red'}}>{this.state.errorMessage}</p>:null}
                    <div className="form-group">
                        <button type="submit" className="form-button btn btn-primary">Create User</button>
                        <button type="submit" className="form-button btn btn-danger ml-2" onClick={this.handleCancel}>Cancel</button>
                    </div>
                </form>
            </div>
            }
        </div>
        );
    }
}
export default AddUserView;