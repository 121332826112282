import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
    /* Make table full width. */
    display: block;
    max-width: 100%;
    overflow: auto;
    /* Makes table scrollable when screen is too small. */
    .tableWrap {
        display: block;
        min-height: 80vh;
        max-width: 100%;
        overflow: auto;
        /* overflow-x: scroll; */
        /* overflow-y: hidden; */
    }
    table {
        margin: auto;
        margin-top: 2rem;
        /* margin-bottom: 2rem; */
        width: 90%;
        border-spacing: 0;
        border: 1px solid black;
        
        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        th, td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            /* Each cell should grow equally */
            /* width: 1%; */
            /* But "collapsed" cells should be as small as possible */
            &.collapse {
              width: 0.0000000001%;
            }
            :last-child {
                border-right: 0;
            }
            input {
              font-size: 0.75rem;
              height: 100%;
              padding: 0;
              margin: 0;
              border: 0;
              /* border-bottom: 0.5px solid black; */
              width: 100%;
              &:focus{
                outline: none;
                /* border-bottom: 1px solid #4D90FE; */
              }
            }
        }
        th {
            font-size: 0.75rem;
        }
        td {
            font-size: 0.75rem;
        }
        .row-clickable { 
          cursor: pointer;
          &:hover {
            background-color: #E8E8E8;
          }
        }
    }
    .pagination { 
      padding: 0.5rem;
      justify-content: center;
    }
`;

const headersList = [
    'Latest in PMC', 
    'Adjusted by Orders & Losses Finalized not in PMC', 
    'Adjusted by Forecasted Orders & Losses'
];

const dataMapping = [
    {
        rowHeading: 'Achievement (%)',
        columnData: [
            {
                header: 'Latest in PMC',
                accessor: 'achievement_latest'
            },
            {
                header: 'Adjusted by Orders & Losses Finalized not in PMC',
                accessor: 'achievement_adjusted_order'
            },
            {
                header: 'Adjusted by Forecasted Orders & Losses',
                accessor: 'achievement_adjusted_forecasted'
            }
        ]
    },
    {
        rowHeading: 'Rebate (%)',
        columnData: [
            {
                header: 'Latest in PMC',
                accessor: 'rebate_latest'
            },
            {
                header: 'Adjusted by Orders & Losses Finalized not in PMC',
                accessor: 'rebate_adjusted_order'
            },
            {
                header: 'Adjusted by Forecasted Orders & Losses',
                accessor: 'rebate_adjusted_forecasted'
            }
        ]
    }
];

const PerfMetricsComp = ({ data }) => {    
    return (
        <Styles>
            { 
                data.length !== 0 &&
               <table>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            {
                                headersList.map((header, index) => (
                                    <th key={index}>
                                        {header}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataMapping.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.rowHeading}</td>
                                    {
                                        row.columnData.map((column, index) => (
                                            <td key={index}>
                                                {data[0][column.accessor]} %
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </Styles>
    )
};

export default PerfMetricsComp;