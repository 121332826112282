import React, { useState, useContext, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import BackdropLoader from "components/loaders/BackdropLoader";
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
// import QuotationFormEmailModal from 'components/modals/QuotationFormEmailModal';
import {
  BillingTableForm,
  QuotationOptionsTableForm,
  QuotationTypeTableForm, MeraKiQuoteTableForm
} from 'quotationSystem/Forms/EstimateForm/CustomTableForm';
import QuotationInfo from 'quotationSystem/Forms/EstimateForm/QuotationInfo';
import { EstimateTableContext } from 'quotationSystem/EstimateTableContext';
import CCWDownloadPageModal from 'quotationSystem/Modals/CCWDownloadPageModal';


// If environment is production use BASE_URI='/pro' in API calls
// If environment is development use BASE_URI='' in API calls
const BASE_URI = process.env.NODE_ENV === 'production' ? '/pro' : '';

// Custom Functions
Number.prototype.countDecimals = function () {
  if (isNaN(this)) return 0;
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
}

const useStyles = makeStyles({
  formHeading: {
    color: "black",
    fontWeight: 600,
    paddingTop: "1vh",
    paddingBottom: 0,
    fontSize: 25,
    textAlign: "center",
    "&$focused": {
      color: "black"
    },
    "&.Mui-focused": { color: "black" },
    "&.Mui-error": { color: "black" }
  },
  formLabels: {
    color: "black",
    "&.Mui-error": { color: "black" },
    "&.Mui-focused": { color: "black" }
  },
  formSectionHeading: {
    color: "black",
    fontWeight: 600,
    "&$focused": {
      color: "black"
    },
    "&.Mui-focused": { color: "black" },
    "&.Mui-error": { color: "black" }
  },
  formLabelText: {
    color: "black",
    fontWeight: 500,
    "&$focused": {
      color: "black"
    },
    "&.Mui-focused": { color: "black" },
    "&.Mui-error": { color: "black" }
  },
  gridComponents: {
    paddingLeft: "2vw",
  },
  gridNestedContainerComponent: {
    marginBottom: "2%",
  }
});

const formHeader = "CCW Fetch Page";
const marginHeaderName = {
  "1": "MARGIN",
  "2": "END CUSTOMER DISCOUNT",
  "3": "MARKUP ON CISCO CONSOLIDATED",
  "4": "MARKUP ON CISCO PRODUCT",
  "5": "END CUSTOMER PRICE"
};
const estimateTypeMapping = {
  '0': '0',
  '1': 'T1',
  '2': 'T2',
  '3': 'T6',
  '4': 'T4'
};
const EstimateForm = props => {
  const classes = useStyles();
  // Estimate Table Context
  const {
    viewProductContext,
    optionTypeContext,
    estimateTypeContext,
    estimateDataContext
  } = useContext(EstimateTableContext);
  const [viewProductsActive, setViewProductsActive] = viewProductContext;
  const [, setOptionType] = optionTypeContext;
  const [, setEstimateType] = estimateTypeContext;
  const [, setEstimateData] = estimateDataContext;
  //
  const inputPropsForDiscounts = {
    inputProps: { min: 0, max: 100, step: 0.0001 },
    endAdornment: "%"
  };
  const inputPropsForDiscountsspecial = {
    inputProps: { min: -20, max: 100, step: 0.0001 },
    endAdornment: "%"
  };
  const inputPropsForNumber = {
    inputProps: { min: 0, max: 100, step: 1 },
    endAdornment: "Rs"
  };
  const inputPropsForNumbers = inputPropsForNumber;
  const inputPropsForMargin = inputPropsForDiscounts;
  const inputPropsForMarginspecial = inputPropsForDiscountsspecial;
  const [ccwId, setCCWId] = useState(0);
  // console.log(sessionStorage.getItem('estimate_type_by_login'))
  // console.log(sessionStorage.getItem('option_type'))
  const [formData, setFormData] = useState({
    ccwEstimate: sessionStorage.getItem('ccw_estimate'),
    customerType: sessionStorage.getItem('cust_type'),
    customerName: sessionStorage.getItem('customer_name'),
    servicesZeroRate: null,
    goodsFTWZ: null,
    customerBillingLocation: sessionStorage.getItem('cust_billing_location'),
    goodsZeroRatedGST: null,
    discountProducts: "0",
    discountServices: "0",
    discountSub: "0",
    marginProducts: "0",
    marginServices: "0",
    marginSub: "0",
    otherCost: "0",
    meraki_quote: null,
    optionType: null,
    // quotationType: sessionStorage.getItem('estimate_type_by_login') !== "Other" ? sessionStorage.getItem('estimate_type_by_login').slice(1) : "0",
    quotationType: sessionStorage.getItem('estimate_type_by_login'),
    quoted_rate: "0",
    dealId: sessionStorage.getItem('dealId'),
    billingBranch: sessionStorage.getItem('billing_branch'),
  });
  // setFormData({ ...formData, optionType:sessionStorage.getItem('option_type') });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [formSubmitErrorCode, setFormSubmitErrorCode] = useState(0);
  const [formSubmitErrorMsg, setFormSubmitErrorMsg] = useState(null);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

  const [errorEstimate, setErrorEstimate] = useState(false);
  const [errorBillingLocation, setErrorBillingLocation] = useState(false);
  const [errorBillingBranch, setErrorBillingBranch] = useState(false);
  const [errormeraki_quote, setErrormeraki_quote] = useState(false);
  const [erroroption_type, setErroroption_type] = useState(false);
  const [error, setError] = useState(false);
  //
  const [errorCustomerName, setErrorCustomerName] = useState(false);
  const [errorDealId, setErrorDealId] = useState(false);
  const [errorTableRadioButton, setErrorTableRadioButton] = useState(false);
  const [tableRadioButtonCode, setTableRadioButtonCode] = useState(sessionStorage.getItem('cust_type'));

  // Effects
  useEffect(() => {
    if (props.formValues !== null) {
      setFormData({
        ...formData,
        dealId: props.formValues.dealId,
        // customerName: props.formValues.customerName
      });
    }
  }, []);

  useEffect(() => {
    if (ccwId !== 0) setViewProductsActive(true);
  }, [ccwId]);

  // OnChange Handlers
  const onChangeEstimate = event => {
    setFormData({ ...formData, ccwEstimate: event.target.value.trim() });
    setErrorEstimate(false);
    setError(false);
  }
  const onChangeBillingLocation = event => {
    setFormData({ ...formData, customerBillingLocation: event.target.value });
    setErrorBillingLocation(false);
    setError(false);
  };
  const onChangeDiscountProducts = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, discountProducts: val });
  }
  const onChangeDiscountServices = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, discountServices: val });
  }
  const onChangeDiscountSub = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, discountSub: val });
  }
  const onChangeMarginProducts = event => {
    console.log(event.target.value)
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, marginProducts: val });
  }
  const onChangeMarginServices = event => {
    let val = parseFloat(event.target.value);

    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, marginServices: val });
  }
  const onChangeMarginSub = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, marginSub: val });
  }
  const onChangeBillingBranch = event => {
    setFormData({ ...formData, billingBranch: event.target.value });
    setErrorBillingBranch(false);
    setError(false);
  };
  const onChangeOtherCost = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    if (val.countDecimals() > 4) {
      val = val.toFixed(4);
    }
    if (val > 100) {
      val = val / 10;
    }
    setFormData({ ...formData, otherCost: val });
  }


  const onChangequotedRate = event => {
    let val = parseFloat(event.target.value);
    // Number.prototype.countDecimals is a custom function.
    // if(val.countDecimals()>4){
    //   val=val.toFixed(4);
    // }
    // if(val>100){
    //   val=val/10;
    // }
    setFormData({ ...formData, quoted_rate: val });
  }



  const onChangeOptionType = event => {
    setFormData({ ...formData, optionType: event.target.value });
  }

  const onChangeMeraliQuoteType = event => {
    setFormData({ ...formData, meraki_quote: event.target.value });
  }


  const onChangeQuotationType = event => {
    setFormData({ ...formData, quotationType: event.target.value });
  }
  const onChangeCustomerName = event => {
    setFormData({ ...formData, customerName: event.target.value });
    setErrorCustomerName(false);
    setError(false);
  }
  const onChangeTableRadioButton = event => {
    setTableRadioButtonCode(event.target.value);
    if (event.target.value === "SFZZ" || event.target.value === "SDFF" || event.target.value === "SDZZ" || event.target.value === "TFZF" || event.target.value === "TDFF") {
      setFormData({ ...formData, customerBillingLocation: "interstate" });
    } else {
      setFormData({ ...formData, customerBillingLocation: null });
    }
    setErrorTableRadioButton(false);
    setError(false);
    setErrorBillingLocation(false);
  }
  const onChangeDealId = event => {
    setFormData({ ...formData, dealId: event.target.value });
    setErrorDealId(false);
    setError(false);
  }
  //
  const checkInputs = () => {
    let errorFound = false;
    if (tableRadioButtonCode == null) {
      setErrorTableRadioButton(true);
      errorFound = true;
    }
    if (formData.ccwEstimate == null) {
      setErrorEstimate(true);
      errorFound = true;
    }
    if (formData.customerBillingLocation == null) {
      setErrorBillingLocation(true);
      errorFound = true;
    }
    if (formData.customerName == null) {
      setErrorCustomerName(true);
      errorFound = true;
    }

    // if (!formData.optionType) {
    //   setErroroptionType(true);
    //   errorFound = true;
    // }
    if (formData.billingBranch === "" || formData.billingBranch === null) {
      setErrorBillingBranch(true);
      errorFound = true;
    }
    console.log(formData.optionType)
    if (!formData.optionType) {
      setErroroption_type(true);
      errorFound = true;
    }
    if (formData.quotationType === "1" && formData.meraki_quote === null) {
      setErrormeraki_quote(true);
      errorFound = true;
    }
    if (errorFound) {
      setError(true);
    } else {
      return true;
    }
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (checkInputs()) {
      setFormSubmitted(true);
      const ccw_estimate = formData.ccwEstimate;
      const cust_billing_location = formData.customerBillingLocation === "interstate" ? "Y" : "N";
      const splitTableCode = tableRadioButtonCode.split("");
      const cust_type = splitTableCode[0];
      const goods_FTWZ = splitTableCode[1];
      const goods_zero_rated_GST = splitTableCode[2];
      const services_zero_rate = splitTableCode[3];
      console.log(formData.quotationType)
      var bodyData = {
        ccw_estimate,
        customer_name: formData.customerName,
        billing_branch: formData.billingBranch,
        cust_billing_location,
        cust_type,
        services_zero_rate,
        goods_FTWZ,
        goods_zero_rated_GST,
        mat_disc: formData.discountProducts.toString(),
        ser_disc: formData.discountServices.toString(),
        xaas_disc: formData.discountSub.toString(),
        other: formData.otherCost.toString(),
        estimate_type: formData.quotationType,
        // estimate_type: estimateTypeMapping[formData.quotationType],
        deal_id: formData.dealId,
        option_type: formData.optionType,
        // meraki_quote:formData.meraki_quote

      };
      // if(formData.optionType==="5" && formData.quotationType==="2"){

      // }else{
      //   bodyData['mat_disc'] =  formData.discountProducts.toString();
      //   bodyData['ser_disc'] =  formData.discountServices.toString();
      //   bodyData['xaas_disc'] =  formData.discountSub.toString();
      // }
      if (formData.quotationType === '1' || formData.quotationType === 'T1') {
        bodyData['meraki_quote'] = formData.meraki_quote;
      }
      if (formData.optionType === '1') {
        bodyData['mat_markup'] = formData.marginProducts.toString();
        bodyData['ser_markup'] = formData.marginServices.toString();
        bodyData['xaas_markup'] = formData.marginSub.toString();
      } else if (formData.optionType === '2') {
        bodyData['pro_cust_disc_line'] = formData.marginProducts.toString();
        bodyData['ser_cust_disc_line'] = formData.marginServices.toString();
        bodyData['xaas_cust_disc_line'] = formData.marginSub.toString();
      } else if (formData.optionType === '3') {
        bodyData['pro_markup_per_line_consolidated'] = formData.marginProducts.toString();
        bodyData['ser_markup_per_line_consolidated'] = formData.marginServices.toString();
        bodyData['xaas_markup_per_line_consolidated'] = formData.marginSub.toString();

      } else if (formData.optionType === '4') {
        bodyData['pro_markup_per_line_net'] = formData.marginProducts.toString();
        bodyData['ser_markup_per_line_net'] = formData.marginServices.toString();
        bodyData['xaas_markup_per_line_net'] = formData.marginSub.toString();
      }
      // else if (formData.optionType === '5') {
      //   bodyData['quoted_rate'] = formData.quoted_rate.toString();
      // }
      console.log(bodyData);
      const response = await fetch(`${BASE_URI}/ccwEstimateAPI/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(bodyData)
      });

      if (response.status === 200) {
        setFormSubmitSuccess(true);
        setFormSubmitError(false);
        setFormSubmitted(false);
        const resp_json = await response.json();
        setCCWId(resp_json.Id);
        if (formData.quotationType === "3") {
          setEstimateType("T6");
        } else if (formData.quotationType === "1") {
          setEstimateType("T1");
        } else if (formData.quotationType === "2") {
          setEstimateType("T2");
        } else {
          setEstimateType(formData.quotationType);
        }
        setOptionType(formData.optionType);
        // Disable Estimate Form on Successful Submit
        // setDisableForm(true);
        // Clear up Estimate Form Prefill Values
        sessionStorage.removeItem('dealId');
        sessionStorage.removeItem('customerName');
        sessionStorage.setItem('disableForm', true);
        // setViewProductsActive(true);
        setEstimateData({
          uid: resp_json.Id,
          customerName: formData.customerName,
          version: resp_json.version_number || '',
          estimateId: formData.ccwEstimate
        });
      } else if (response.status === 406) {
        const responseJSON = await response.json();
        setFormSubmitSuccess(false);
        setFormSubmitError(true);
        setFormSubmitErrorCode(406);
        setFormSubmitErrorMsg(responseJSON.message);
        setFormSubmitted(false);
      } else {
        if (response.status === 204 || response.status === 500 || response.status === 404) {
          setFormSubmitError(true);
          setFormSubmitSuccess(false);
          setFormSubmitErrorCode(response.status)
          setFormSubmitted(false);
          setFormSubmitErrorMsg("Error while submitting the form.");

        } else {
          setFormSubmitError(true);
          setFormSubmitSuccess(false);
          setFormSubmitErrorCode(response.status)
          setFormSubmitted(false);
        }
      }
    }
  }
  const refreshPage = () => {
    window.location.reload(false);
  }
  const getTextFieldPercentageComponent = (id, InputProps, onChange, value, disable = false) => {
    return (
      <TextField
        variant="outlined"
        required
        id={id}
        type="number"
        size="small"
        InputProps={InputProps}
        onChange={onChange}
        value={value}
        disabled={disable}
      />
    );
  }


  const getTextFieldNumberComponent = (id, InputProps, onChange, value, disable = false) => {
    return (
      <TextField
        variant="outlined"
        required
        id={id}
        type="number"
        size="small"
        InputProps={InputProps}
        onChange={onChange}
        value={value}
        disabled={disable}
      />
    );
  }
  const NotificationComponent = () => (
    <>
      {/* {
        formSubmitErrorCode === 406 ?
          <CustomizedSnackbars
            Variant="error"
            Open={true}
            OnExit={refreshPage}
            Message={formSubmitErrorMsg}
            CloseCallback={() => { setFormSubmitErrorCode(0); setFormSubmitError(false) }}
            EnableTimer
            Timer={3000}
          />
          : null
      } */}
      {
        formSubmitError
          &&
          formSubmitErrorCode !== 0
          ?
          <CustomizedSnackbars
            Variant="error"
            Open={true}
            OnExit={refreshPage}
            Message={formSubmitErrorMsg}
            EnableTimer
            Timer={3000}
            CloseCallback={() => { setFormSubmitErrorCode(0); setFormSubmitError(false) }}
          />
          : null
      }
      {
        formSubmitSuccess
          &&
          viewProductsActive
          ?
          <CustomizedSnackbars
            Variant="success"
            Open={true}
            Message="Quotation submitted successfully."
            EnableTimer
            Timer={3000}
            CloseCallback={() => { setFormSubmitErrorCode(0); setFormSubmitError(false) }}
          />
          : null
      }
    </>
  )
  return (
    <React.Fragment>
      <BackdropLoader Open={formSubmitted} />
      {!formSubmitted ?
        <NotificationComponent /> : null}

      <form noValidate autoComplete="off" style={{ flexGrow: 1 }} onSubmit={handleFormSubmit}>
        <Grid container>
          <FormControl style={{ flexGrow: 1 }} error={error}>
            <FormLabel component="legend" className={classes.formHeading}>
              {formHeader}
            </FormLabel>
            <hr style={{ color: "black", backgroundColor: "black", width: "98%" }} />
            <Grid
              container
              direction="column"
              alignItems="stretch"
              style={{ minWidth: "95vw" }}
              className={classes.gridComponents}
            >
              <QuotationInfo
                classes={classes}
                formValues={props.formValues}
                onChangeEstimate={onChangeEstimate}
                onChangeBillingBranch={onChangeBillingBranch}
                onChangeCustomerName={onChangeCustomerName}
                onChangeDealId={onChangeDealId}
                errorEstimate={errorEstimate}
                errorBillingBranch={errorBillingBranch}
                errorCustomerName={errorCustomerName}
                errorDealId={errorDealId}
              />
              <hr style={{ color: "black", backgroundColor: "black", width: "98%" }} />
              <Grid container item className={classes.gridNestedContainerComponent}>
                <BillingTableForm
                  RadioButtonValue={tableRadioButtonCode}
                  OnSelectRadioButton={onChangeTableRadioButton}
                  Error={errorTableRadioButton}
                />
              </Grid>
              {/* Grid for Radio Buttons choices */}
              <Grid container>
                <Grid item xs={2}>
                  <FormLabel
                    component="legend"
                    className={classes.formLabelText}
                    style={{ paddingTop: "1vw" }}
                  >
                    DTA Billing
                  </FormLabel>
                  <FormHelperText error={errorBillingLocation}>{errorBillingLocation ? "Please select an option" : null}</FormHelperText>
                </Grid>
                <Grid item xs={10}>
                  <RadioGroup
                    row
                    aria-label="customerBillingLocation"
                    name="customerBillingLocation"
                    value={formData.customerBillingLocation}
                    onChange={onChangeBillingLocation}
                  >

                    {sessionStorage.getItem('cust_type') === "null"
                      ?
                      <FormControlLabel value="interstate" control={<Radio />} label="Interstate - including SEZ (Y)" />
                      :
                      <FormControlLabel
                        checked={sessionStorage.getItem('cust_billing_location') === "Y"}
                        value="interstate"
                        control={<Radio />}
                        label="Interstate - including SEZ (Y)" />
                    }

                    {sessionStorage.getItem('cust_type') === "null"
                      ?
                      <FormControlLabel value="intrastate" disabled={tableRadioButtonCode === "SFZZ" || tableRadioButtonCode === "SDFF" || tableRadioButtonCode === "SDZZ" || tableRadioButtonCode === "TFZF" || tableRadioButtonCode === "TDFF"} control={<Radio />} label="Intrastate (N)" />
                      :
                      <FormControlLabel
                        value="intrastate"
                        checked={sessionStorage.getItem('cust_billing_location') === "N"}
                        disabled={tableRadioButtonCode === "SFZZ" || tableRadioButtonCode === "SDFF" || tableRadioButtonCode === "SDZZ" || tableRadioButtonCode === "TFZF" || tableRadioButtonCode === "TDFF"} control={<Radio />} label="Intrastate (N)" />
                    }

                  </RadioGroup>

                </Grid>
              </Grid>
              <hr style={{ color: "black", backgroundColor: "black", width: "98%", paddingLeft: "1vw" }} />
              {/* Quotation Types/Sales Types */}
              <Grid container item className={classes.gridNestedContainerComponent}>
                <QuotationTypeTableForm
                  RadioButtonValue={formData.quotationType}
                  OnChange={onChangeQuotationType}
                />
              </Grid>

              {/* Quotation Types/Sales Types */}
              {/* {console.log(formData)} */}
              <Grid container item className={classes.gridNestedContainerComponent}>
                <MeraKiQuoteTableForm
                  RadioButtonValue={formData.meraki_quote}
                  goods_FTWZ={tableRadioButtonCode}
                  OnChange={onChangeMeraliQuoteType}
                  QuotationTypeValue={formData.quotationType}
                // QuotationTypeValue={estimateTypeMapping[formData.quotationType]}
                //  MerakiQuoteTypeValue={estimateTypeMapping[formData.meraki_quote]}
                />
                {formData.quotationType === "1" ?
                  <FormHelperText error={errormeraki_quote}>{errormeraki_quote ? "Please select an option" : null}</FormHelperText>
                  : null}
              </Grid>


              <hr style={{ color: "black", backgroundColor: "black", width: "98%" }} />
              {/* Quotation Options */}
              <Grid container item className={classes.gridNestedContainerComponent}>


                <FormHelperText error={erroroption_type}>{erroroption_type ? "Please select an option" : null}</FormHelperText>
                <QuotationOptionsTableForm
                  RadioButtonValue={formData.optionType}
                  OnChange={onChangeOptionType}
                  // QuotationTypeValue={estimateTypeMapping[formData.quotationType]}
                  QuotationTypeValue={formData.quotationType}
                />

              </Grid>
              {/* Grid for Discounts */}

              {/* {formData.optionType === "5" && formData.quotationType === "2"
                ?
                '' : */}
              {formData.quotationType === "5" || sessionStorage.getItem('estimate_type_by_login') == "T6R" ? null :
                <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="flex-start">
                  <br />
                  <Grid item xs={12}>
                    <FormLabel component="legend" className={classes.formSectionHeading}>
                      CISCO DISCOUNTS
                    </FormLabel>
                  </Grid>
                  {/* {
            formData.quotationType !== "2" &&  */}
                  <Grid item xs={4}>
                    <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                      Product/Software:
                    </FormLabel>
                    {getTextFieldPercentageComponent("discount_products", inputPropsForDiscounts, onChangeDiscountProducts, formData.discountProducts.toString(),
                      (formData.quotationType == 'T2' || formData.quotationType == 'T6'))}
                    {/* {console.log(formData.quotationType)} */}
                  </Grid>
                  {/* } */}
                  <Grid item xs={4}>
                    <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                      Services:
                    </FormLabel>
                    {getTextFieldPercentageComponent("discount_services", inputPropsForDiscounts, onChangeDiscountServices, formData.discountServices.toString(),
                      (formData.quotationType == 'T6'))}
                    {/* {getTextFieldPercentageComponent("discount_services", inputPropsForDiscounts, onChangeDiscountServices, sessionStorage.getItem('estimate_type_by_login') == "T2" ? sessionStorage.getItem('ser_disc') : formData.discountServices.toString(), sessionStorage.getItem('estimate_type_by_login') == "T2")} */}
                  </Grid>
                  {/* { */}
                  {/* formData.quotationType !== "2" &&  */}
                  <Grid item xs={4}>
                    <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                      XAAS:
                    </FormLabel>
                    {getTextFieldPercentageComponent("discount_sub", inputPropsForDiscounts, onChangeDiscountSub, formData.discountSub.toString(),
                      (formData.quotationType == 'T2'))}
                  </Grid>
                  {/* } */}
                </Grid>

              }
              {/* } */}
              <hr style={{ color: "black", backgroundColor: "black", width: "98%" }} />
              {/* Grid for Margin */}
              {
                formData.optionType !== "0" && formData.optionType !== "5"
                  ?
                  <Grid container spacing={1}>
                    <br />
                    <Grid item xs={12}>
                      <FormLabel component="legend" className={classes.formSectionHeading}>
                        {marginHeaderName[formData.optionType]}
                        {/* {console.log(marginHeaderName[formData.optionType])} */}
                      </FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                        Product/Software:
                      </FormLabel>
                      {getTextFieldPercentageComponent("margin_products", inputPropsForMargin, onChangeMarginProducts, formData.marginProducts.toString(),
                        (formData.quotationType == 'T2' || formData.quotationType == 'T6' || formData.quotationType == 'T6R'))}
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                        Services:
                      </FormLabel>
                      {console.log(formData.quotationType)}
                      {(formData.optionType == "T1" || sessionStorage.getItem('estimate_type_by_login') == "T1") || formData.optionType == "T4" ?
                        getTextFieldPercentageComponent("margin_services", inputPropsForMarginspecial, onChangeMarginServices, formData.marginServices.toString())
                        :
                        getTextFieldPercentageComponent("margin_services", inputPropsForMargin, onChangeMarginServices, formData.marginServices.toString(),
                          (formData.quotationType == 'T6' || formData.quotationType == 'T6R'))
                      }
                      {/* {getTextFieldPercentageComponent("margin_services",inputPropsForMargin,onChangeMarginServices,formData.marginServices.toString())} */}
                      {/* {getTextFieldPercentageComponent("margin_services", inputPropsForMargin, onChangeMarginServices, sessionStorage.getItem('estimate_type_by_login') == "T2" ? sessionStorage.getItem('ser_cust_disc_line') : formData.marginServices.toString(), sessionStorage.getItem('estimate_type_by_login') == "T2")} */}
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                        XAAS:
                      </FormLabel>
                      {getTextFieldPercentageComponent("margin_sub", inputPropsForMargin, onChangeMarginSub, formData.marginSub.toString(),
                        (formData.quotationType == 'T2'))}
                    </Grid>
                  </Grid>
                  :
                  null
              }
              <Grid container item style={{ marginTop: "2%" }}>

                <Grid item xs={5}>
                  <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                    Other Costs:
                  </FormLabel>
                  {getTextFieldPercentageComponent("other_costs", inputPropsForMargin, onChangeOtherCost, sessionStorage.getItem('estimate_type_by_login') == "T2" ? sessionStorage.getItem('other') : formData.otherCost.toString())}
                  {/* {getTextFieldPercentageComponent("other_costs", inputPropsForMargin, onChangeOtherCost, sessionStorage.getItem('estimate_type_by_login') == "T2" ? sessionStorage.getItem('other') : formData.otherCost.toString(), sessionStorage.getItem('estimate_type_by_login') === "T2")} */}
                </Grid>

                {/* (id, InputProps, onChange, value, disable = false) */}
                {/* {formData.optionType === "5" && formData.quotationType === "2"
                  ?
                  <Grid item xs={5}>
                    <FormLabel component="label" className={classes.formLabelText} style={{ paddingTop: "0.75vw", paddingRight: "0.5vw" }}>
                      End Customer Price:
                    </FormLabel>
                    {getTextFieldNumberComponent("quoted_rate", inputPropsForNumber, onChangequotedRate, formData.quoted_rate.toString())}
                  </Grid>
                  : ''} */}
                <Grid item xs={6}>
                  <Typography variant="body2">Please ensure that the ID is of an Estimate <u>not</u> a Quote and you have shared this Estimate with the CCO email <b>proactive_eps@proactive.co.in</b></Typography>
                </Grid>
              </Grid>
              <br />
              <Grid item>
                <br />
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={sessionStorage.getItem('disableForm') === 'true' ? true : false}
                >
                  Submit
                </Button>
                <FormHelperText error={error}>{error && "Incomplete form."}</FormHelperText>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </form>
      {
        viewProductsActive
          ?
          <CCWDownloadPageModal
            Open={viewProductsActive}
            EstimateId={formData.ccwEstimate}
            Id={ccwId}
            refreshData={false}
            quotationType={formData.quotationType}

          />
          : null
      }
    </React.Fragment>
  );
}

export default withRouter(EstimateForm);