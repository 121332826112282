import React,{Component} from 'react';
import loginRightIcon from 'assets/login-right-arrow.png';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import 'css/pages/login.css';
import CircularIndeterminate from 'components/loaders/CircularIndeterminate';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import { COMMON_ENDPOINTS } from 'endpoints';
// Custom Objects.
import { USER_TYPE } from 'globalConstants';
class Login extends Component{
  state={
    loginSuccess:false,
    twoFactorSuccess:false,
    authInProcess:false,
    userName:'',
    token:'',
    userType:-1,
    errorMessage:'',
    userEmail:'',
    responseLogin:0,
  }
  getAccessToken = async(loginData) => {
    /**
     * This function makes a request to backend for
     * getting access token after user click Login.
     * 
     * @param { Object } loginData - Object having
     * emailId and password.
     */
    const bodyData = {
      username: loginData.emailId,
      password: loginData.password
    };
    const response = await fetch(COMMON_ENDPOINTS.LOGIN, {
      method:'POST',
      body:JSON.stringify(bodyData)
    });
    const responseJSON=await response.json();
    if(response.status === 200){
      this.setState({
        token:responseJSON.token,
        userType:responseJSON.User_Type,
        userName:responseJSON.Name,
        loginSuccess:true,
        userEmail:loginData.emailId,
        authInProcess:false,
        responseLogin:response.status
      });
    } else {
      if(response.status===204|| response.status===500 || response.status===404){
        this.setState({
          authInProcess:false,
          responseLogin:response.status
        });
      }else{
        this.setState({
          authInProcess:false,
          errorMessage:responseJSON.message,
          responseLogin:response.status,
        });
      }
    }
  }
  
  componentDidUpdate(){
    if(this.state.loginSuccess){
      /** 
      * Set all sessionStorage items after
      * successful login. 
      */
      sessionStorage.setItem('loggedIn', true);
      sessionStorage.setItem('username', this.state.userName);
      sessionStorage.setItem('usertype', this.state.userType);
      sessionStorage.setItem('token', this.state.token);
      sessionStorage.setItem('emailId', this.state.userEmail);
      if(sessionStorage.getItem('usertype') === USER_TYPE.APPROVAL_PORTAL_ADMIN){
        // Final Approved
        sessionStorage.setItem('approveValue',4);
        sessionStorage.setItem('rejectValue',1);
      }else if(sessionStorage.getItem('usertype') === USER_TYPE.APPROVAL_PORTAL_USER) {
        // Sent for Final Approval
        sessionStorage.setItem('approveValue',5);
        sessionStorage.setItem('rejectValue',3);
      }
      this.props.history.push("/dashboard");    
    }
  }

  handleLoginSubmit=(event)=>{
    // Other Functionality
    event.preventDefault();
    const data=new FormData(event.target);
    let loginData={
      emailId:data.getAll('email')[0],
      password:data.getAll('password')[0],
    }
    this.setState({authInProcess:true});
    this.getAccessToken(loginData);
  }
  resetState = () => {
    /**
     * Resets state for all.
     */
    this.setState({
      ...this.state,
      loginSuccess: false,
      twoFactorSuccess: false,
      authInProcess: false,
      responseLogin: 0,
      errorMessage: ''
    })
  }
  render(){
    return (
       <div>
        {this.state.responseLogin===500?<CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true} CloseCallback={this.resetState} />:null}
        {this.state.responseLogin===404?<CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true} CloseCallback={this.resetState} />:null}
        <form className="login-form" method="post" onSubmit={this.handleLoginSubmit}>
          <div className="form-row justify-content-center mb-3">
            <h2 className="login-text">Login</h2>
          </div>
          <div className="form-row justify-content-center mb-3">
            <p className="login-description">Welcome back! Login to the Proactive Portal.</p>
          </div>
          {this.state.errorMessage===''
            ?null
            :<div className="form-row justify-content-center">
            <p className="error-message">Failed to login. {this.state.errorMessage}</p>
            </div>
          }
          <div className="form-row justify-content-center">
            <div className="form-group">
              <input name="email" type="email" className="form-control form-user-input" id="userEmailId" placeholder="Email Address" required/>
            </div>
          </div>
          <div className="form-row justify-content-center">
            <div className="form-group">
              <input name="password" type="password" className="form-control form-user-input" id="userPassword" placeholder="Password" required/>
            </div>
          </div>
          {
            this.state.authInProcess
            ?
            <CircularIndeterminate Type="Login"/>
            :
            <div>
              <div className="form-row justify-content-center">
                <button type="submit" className="form-button btn btn-primary">Continue<img className="login-arrow ml-2" src={loginRightIcon} alt="right-icon"/></button>
              </div>
              <div className="form-row justify-content-center">
                <Link className="create-account-text" to="/resetPass">Forgot Password</Link>
              </div>
              <div className="form-row justify-content-center login-create-account-block mb-2">
                <Link className="create-account-text" to="/signup">Create Account! (Admin Only)</Link>
              </div>
            </div>
          }
        </form>
      </div>
    );
  }
}

export default withRouter(Login);