import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
} from "@material-ui/core";
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import TableChartTwoToneIcon from '@material-ui/icons/TableChartTwoTone';
import CustomizedSnackbars from "components/notification/CustomizedSnackbars";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';

// import {withRouter} from 'react-router';
import { useHistory } from 'react-router';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 0,
  },
  drawerOpen: {
    backgroundImage: "linear-gradient(#ced4da, #ced4da73, #ced4da80)",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  drawerClose: {
    backgroundImage: "linear-gradient(#ced4da, #ced4da73, #ced4da80)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  iconButton: {
    '&:visited': {
      outline: 0
    },
    '&:focus': {
      outline: 0
    }
  },
  listItemIconClosedDrawer: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: "100%",
    justifyContent: "center",
  },
  listItemIconOpenDrawer: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: "20%",
  }
}));

const SideNavBarQuotationSystem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [formDisabled, setFormDisabled] = React.useState(false);
  const [showFormDisabledMsg, setShowFormDisabledMsg] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const redirectQuotationForm = () => {
    if (sessionStorage.getItem('disableForm')) {
      setFormDisabled(true);
      setShowFormDisabledMsg(true);
    } else {
      history.push("/estimateForm");
    }
  }
  const redirectQuotationTable = () => {
    history.push("/estimateTable");
  }

  const navigationItemsList = [
    // Add new navigation items here.

    // This is for redirection to form page.
    { textTitle: "CCW Fetch Page", key: "ccw_fetch_page", icon: <ListAltTwoToneIcon />, onClick: redirectQuotationForm },

    // This is for redirection to table page.
    { textTitle: "Estimate Table", key: "estimate_table", icon: <TableChartTwoToneIcon />, onClick: redirectQuotationTable },
  ];
  return (
    // <div className={classes.root}>
    //   <Drawer
    //     variant="permanent"
    //     className={clsx(classes.drawer, {
    //       [classes.drawerOpen]: open,
    //       [classes.drawerClose]: !open
    //     })}
    //     classes={{
    //       paper: clsx({
    //         [classes.drawerOpen]: open,
    //         [classes.drawerClose]: !open
    //       })
    //     }}
    //   >
    //     <Toolbar variant="dense"/>
    //     <div className={classes.toolbar}>
    //       <IconButton className={classes.iconButton} onClick={open?handleDrawerClose:handleDrawerOpen}>
    //         {open ? (
    //           <ChevronLeftIcon/>
    //         ) : (
    //           <ChevronRightIcon/>
    //         )}
    //       </IconButton>
    //     </div>
    //     <Divider />
    //     <List >
    //       {navigationItemsList.map((listItem)=>(
    //         <ListItem button key={listItem.key} onClick={listItem.onClick} disableGutters={!open}>
    //         <ListItemIcon className={clsx({
    //           [classes.listItemIconOpenDrawer]:open,
    //           [classes.listItemIconClosedDrawer]:!open
    //         })}>
    //           {listItem.icon}
    //         </ListItemIcon>
    //         <ListItemText primary={listItem.textTitle} />
    //       </ListItem>
    //       ))}
    //     </List>
    //     <Divider />
    //   </Drawer>
    //   <main className={classes.content}>
    //     <div className={classes.toolbar} />
    //         <CustomizedSnackbars Open={showFormDisabledMsg&&formDisabled} Variant={"warning"} Message={"Access is not allowed. Try going through Zoho CRM."} CloseCallback={()=>setShowFormDisabledMsg(false)}/>
    //         {props.RenderComponent}
    //   </main>
    // </div>
    <> <main className={classes.content}>
        <div className={classes.toolbar} />
        <CustomizedSnackbars Open={showFormDisabledMsg && formDisabled} Variant={"warning"} Message={"Access is not allowed. Try going through Zoho CRM."} CloseCallback={() => setShowFormDisabledMsg(false)} />
        {props.RenderComponent}
      </main>
      {navigationItemsList.map((listItem) => (
        <Button onClick={listItem.onClick}>
          {listItem.textTitle}
        </Button >
      ))}




     
    </>
  );
}
// export default withRouter(SideNavBarQuotationSystem);
export default SideNavBarQuotationSystem;
