import React,{useState,useEffect} from 'react';
import TickMarkIcon from '../TickMarkIcon';
import StatusButton from 'components/buttons/StatusButton';
import ClipLoader from 'react-spinners/ClipLoader';
import TableButton from 'components/buttons/TableButton';
import 'css/tabledata.css';


const Modal=props=>{
    const modalData=props.data;
    const statusCode=modalData["Status"];
    const [buttonClicked,setButtonClicked]=useState(0);
    const [statusUpdated,setStatusUpdated]=useState(false);
    const [otherCharges,setOtherCharges]=useState(0);
    useEffect(()=>{
        if(statusUpdated&&buttonClicked){
            setTimeout(function(){
                props.closeModalHandler();
            },1000);
        }
    });
    function callBackForStatusUpdate(responseCode){
        console.log(responseCode);
        if(responseCode===200){
            setTimeout(function(){
                setStatusUpdated(true);
                setOtherCharges(0);
            },500);
        }
    }
    function onChangeCharges(event){
        setOtherCharges(event.target.value);
    }
    return (
        <div className="modal overlay">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4>{modalData["Document No_"]}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row no-gutters">
                            <div className="col">
                                <h6>Approver Employee ID</h6>
                                <p className="modal-details-text">{modalData["Approver Employee ID"]}</p>
                            </div>
                            <div className="col">
                                <h6>Date-Time For Approval</h6>
                                <p className="modal-details-text">{Intl.DateTimeFormat('en-IN',{
                                    dateStyle:'short',
                                    timeStyle:'short'
                                }).format(new Date(modalData["Date-Time Sent for Approval"]))}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col">
                                <h6>Amount (&#8377;)</h6>
                                <p className="modal-details-text">{Math.round(modalData["Amount"]*100)/100}</p>
                            </div>
                            <div className="col">
                                <h6>Amount (LCY)</h6>
                                <p className="modal-details-text">{Math.round(modalData["Amount (LCY)"]*100)/100}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col">
                                <h6>Purchase Amount(&#8377;)</h6>
                                <p className="modal-details-text">{isNaN(Math.round(modalData["Purchase Amount"]*100)/100)?"-":Math.round(modalData["Purchase Amount"]*100)/100}</p>
                            </div>
                            <div className="col">
                                <h6>Theoretical Margin(&#8377;)</h6>
                                <p className="modal-details-text">{isNaN(Math.round(modalData["Theoretical Margin"]*100)/100)?"-":Math.round(modalData["Theoretical Margin"]*100)/100}</p>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-6 col-6 col-input">
                            {statusCode===0||statusCode===1?
                            <div className="user-input">
                                <p className="user-input-text">Other Amount(&#8377;): </p>
                                <input className="user-input-box" type="number" name="otherCharges" min="0" onChange={onChangeCharges}/>
                              </div>
                            :null
                            }
                        </div>
                        <div className="col-md-6 col-6 col-buttons">
                            {statusCode===0||statusCode===1?
                            <div>
                                {buttonClicked!==0?null:
                                    <div className="status-buttons">
                                        <TableButton 
                                            onClickHandler={()=>{
                                                setButtonClicked(1);
                                                props.updateStatus(modalData,4,otherCharges,callBackForStatusUpdate);
                                            }}
                                            buttonText="Approve"
                                        />
                                        <TableButton 
                                            onClickHandler={()=>{
                                                setButtonClicked(2);
                                                props.updateStatus(modalData,3,otherCharges,callBackForStatusUpdate);
                                            }}
                                            buttonText="Reject"
                                        />
                                    </div>
                                }
                            </div>
                            :
                            <StatusButton Type="Modal" StatusValue={statusCode}/>
                            }
                            {!statusUpdated&&buttonClicked!==0?
                            <div>
                                {buttonClicked===1?
                                <ClipLoader size={35} color={"#2EB150"}/>
                                :
                                <ClipLoader size={35} color={"rgb(177, 46, 46)"}/>
                                }
                            </div>
                            :null
                            }
                            {statusUpdated?
                            <TickMarkIcon bgColor={
                                buttonClicked===1?"#2EB150":"rgb(177, 46, 46)"
                            }/>
                            :null
                            }
                            <button type="button" className="btn btn-secondary close-button" onClick={props.closeModalHandler}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Modal;