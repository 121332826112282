import React from "react";
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import LoginView from "components/views/loginView";
import RedirectLogin from "containers/RedirectLogin";
import DashboardView from "components/views/dashboardView";
import PurchaseOrderView from "components/views/purchaseorderView";
import SignUpView from "components/views/signupView";
import AddUserView from "components/views/adduserView";
import ChangeUserPasswordView from "components/views/changeuserpasswordView";
import ForgotPasswordView from "components/views/forgotPasswordView";
import CashFlowView from "components/views/cashFlowView";
import EstimateFormView from "quotationSystem/Views/EstimateFormView";
import EstimateTableView from "quotationSystem/Views/EstimateTableView";
import PMCView from "pmcSystem/PMCView";

import Footer from "components/Footer";
import Header from "components/Header";

import "css/page-transitions.css";

import { USER_TYPE } from "globalConstants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoute = ({
  component: Component,
  pageName: HeaderTitle,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const loggedIn = sessionStorage.getItem('loggedIn');
      if (loggedIn === "true") {
    const usertype = sessionStorage.getItem('usertype');
        switch (usertype) {
          case USER_TYPE.CASHFLOW_SYSTEM:
            return (
              <Redirect
                to={{
                  pathname: "/CF",
                  state: { from: props.location },
                }}
              />
            );
          case USER_TYPE.QUOTATION_SYSTEM:
            return (
              <Redirect
                to={{
                  pathname: "/estimateForm",
                  state: { from: props.location },
                }}
              />
            );
          case USER_TYPE.PMC_ADMIN || USER_TYPE.PMC_USER:
            return (
              <Redirect
                to={{
                  pathname: "/pmc/view",
                  state: { from: props.location },
                }}
              />
            );
          default:
            return (
              <div>
                <Header pageName={HeaderTitle} />
                  <Component {...props} />
                <Footer />
              </div>
            );
        }
      }
      return (
        <Redirect
          to={{ pathname: "/", state: { from: props.location } }}
        />
      );
    }}
  />
);

const PrivateRouteModified = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("adminVerified") === "true" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signup/addUser",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PrivateRouteCF = ({
  component: Component,
  pageName: HeaderTitle,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("usertype") === USER_TYPE.CASHFLOW_SYSTEM &&
      sessionStorage.getItem("loggedIn") === "true" ? (
        <div>
          <Header pageName={HeaderTitle} />
            <Component {...props} />
          <Footer />
        </div>
      ) : (
        <Redirect
          to={{ pathname: "/", state: { from: props.location } }}
        />
      )
    }
  />
);

const PrivateRouteQuotation = ({
  component: Component,
  pageName: HeaderTitle,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("usertype") === USER_TYPE.QUOTATION_SYSTEM &&
      sessionStorage.getItem("loggedIn") === "true" ? (
        <React.Fragment>
          <Header pageName="" />
          <Component {...props} />
        </React.Fragment>
      ) : (
        <Redirect
          to={{ pathname: "/", state: { from: props.location } }}
        />
      )
    }
  />
);
// For redirecting users coming from Zoho CRM.
const RedirectRoute = ({ component: Component, ...rest }) => {
  const queryParams = useQuery();
  const params = {
    owner: queryParams.get("owner"),
    dealId: queryParams.get("deal_id"),
    dealName: queryParams.get("deal_name"),
    customerName: queryParams.get("customer_name"),
    token: queryParams.get("token"),
  };
  return <Component queryParams={params} {...rest} />;
};

const PrivateRoutePMC = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    const usertype = sessionStorage.getItem("usertype");
      return (
    loggedIn === "true" &&
        (
      usertype === USER_TYPE.PMC_ADMIN ||
      usertype === USER_TYPE.PMC_USER 
    )
    ?
        Component
    : 
        <Redirect
          to={{ pathname: "/", state: { from: props.location } }}
        />
    );
    }}
  />
);

const Routes = () => (
  <Router history={createBrowserHistory()}>
    <Switch>
      {/* Open Routes */}
      <Route exact path="/" render={() => <LoginView />} />
      <Route exact path="/signup" render={() => <SignUpView />} />
      <Route
        exact
        path="/resetPass"
        render={() => <ForgotPasswordView />}
      />
      <RedirectRoute
        path="/redirect_login"
        component={RedirectLogin}
        exact
      />
      {	
      /* Private/Protected Routes, 
      *  Accessible after user verification only (login success). 
      */
      }
      <PrivateRouteModified
        path="/signup/addUser"
        component={AddUserView}
        exact
      />
      <PrivateRoute
        path="/user/changePassword"
        component={ChangeUserPasswordView}
        exact
      />
      <PrivateRoute
        path="/dashboard"
        component={DashboardView}
        pageName="DASHBOARD"
        exact
      />
      <PrivateRoute
        path="/PO"
        component={PurchaseOrderView}
        pageName="PURCHASE ORDERS"
        exact
      />
      <PrivateRouteCF
        path="/CF"
        component={CashFlowView}
        pageName="Cash Flow"
        exact
      />
      <PrivateRouteCF
        path="/user/CF/changePassword"
        component={ChangeUserPasswordView}
        exact
      />
      <PrivateRouteQuotation
        path="/estimateForm"
        component={EstimateFormView}
        exact
      />
      <PrivateRouteQuotation
        path="/estimateTable"
        exact
        component={EstimateTableView}
      />
      <PrivateRoutePMC
        path="/pmc/upload"
        exact
        component={<PMCView content="upload" />}
      />
      <PrivateRoutePMC
        path="/pmc/view"
        exact
        component={<PMCView content="view" />}
      />
      {/* <Route path="/pmc/upload" exact render={()=><PMCView content="upload"/>}/> */}
      {/* <Route path="/pmc/view" exact render={()=><PMCView content="view"/>}/> */}
      {/* <PrivateRoute path="/SBSO" component={BlanketSalesOrderView} pageName="BLANKET SALES ORDERS" exact/> */}
      {/* <PrivateRoute path="/CF" component={CashFlowView} pageName="Cash Flow" exact/>
      <PrivateRoute path="/user/CF/changePassword" component={ChangeUserPasswordView} exact/> */}
      {/* <PrivateRoute path="/BSO" component={SalesOrderView} pageName="SALES ORDERS" exact/> */}
    </Switch>
  </Router>
);

export default Routes;
