import React from 'react';
import 'css/footer.css';

/**
 * This component is used for Footer.
 **/
const Footer = (props) => (
  <div className="footer navbar fixed-bottom">
    <div className="col-md-3 offset-md-9">
      <h6 className="footer-content">&copy;Proactive Data Systems Pvt. Ltd.</h6>
    </div>
  </div>
);

export default Footer;