import React from 'react';
import PropTypes, { element } from 'prop-types';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'css/tabledata.css';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, Button } from '@material-ui/core';
import { EstimateTableContext } from 'quotationSystem/EstimateTableContext';
import { viewProductColumns } from 'quotationSystem/Table/tableColumns';
import { dateTimeFormatting } from 'utility/dateTimeFormat';
import ConfirmDialog from 'components/ConfirmDialog';
import styled from 'styled-components';
import { CancelScheduleSendOutlined } from '@material-ui/icons';

const editableColumnAccessorId = new Set(['edit-1', 'edit-2']);
// Export columns other than editables.
const exportColumns = new Set(['Line No.', 'Part Number', 'List Price (Rs)']);

const headerAccessorMapping = {
    'est_number': 'Estimate Number',
    'line_num': 'Line No.',
    'part_number': 'Part Number',
    'initial_term': 'Initial Term (months)',
    'billing_model': 'Billing Model',
    'xaas_installments': 'No. of Installment',
    'quantity': 'Qty',
    'uom': 'UOM',
    'list_price': 'List Price (Rs)',
    'discount_per_line': 'Cisco Discount (%)',
    /** 
     * NOTE: markup_per_line is a dynamic column.
     * It changes upon based on other parameters that's why it's not included here.
     */
    'service_duration': 'Service Duration',
    'extended_list_amount': 'Extended List (Rs)',
    'xaas_pricing_term': 'Pricing Term (months)',
    'installment_value': 'Quoted Pricing Term Rate',
    'contract_total_value': 'Quoted Contract Value (Rs)',
    'part_category': 'Major/Minor Part Category',
    'ccw_freight_ftwz_net': 'Freight Amount',
    'ccw_duty_slc_net': 'Duty Amount',
    'hsn_code': 'HSN Code'
};
class EstimateViewProductTable extends React.Component {
    static contextType = EstimateTableContext;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            changedDataDetails: {},
            deleteRowsDetails: new Set([]),
            secondColumnId: ''
        }
    };
  
    componentDidMount() {
        console.log("propssssssssssssss",this.props)
        this.updateColumnId(this.props.optionType);

    };
    componentDidUpdate(prevProps) {
        if (this.props.importCsvData !== prevProps.importCsvData && this.props.importCsvData !== []) {
            // If import csv data is updated in props. Update the data here also.
            this.updateData(this.props.importCsvData);
            // console.log(this.props.importCsvData)
        }
        if (this.props.data !== prevProps.data && this.props.data !== []) {
            // If Table data is passed as prop is updated. Update data in state here.
            this.setState({ ...this.state, data: this.props.data });

        }
    };

    // Handle imported data processing.
    updateData = (importCsvData) => {
        // console.log(importCsvData)
        /**
         * Handles imported data processing.
         * @param {Array.<Dict>} importCsvData - imported data processed using XLSX.
         */
        var currentData = Object.assign([], this.state.data);
        var changedData = {};
        importCsvData.forEach(row => {
            const line_num = row[headerAccessorMapping['line_num']];
            const discount_per_line = row[headerAccessorMapping['discount_per_line']];
            const dynamic_editable = row[this.conditionHeader()];
            currentData.every((currRow, index) => {
                // Iterates over every row of current data.
                // console.log(line_num)
                // console.log(currRow['line_num'])
                if (currRow['line_num'] === line_num) {
                    // console.log('Condition 1 True');
                    if ((currRow['discount_per_line'] !== discount_per_line)
                        || (currRow[this.state.secondColumnId] !== dynamic_editable)) {
                        changedData[index] = {
                            'line_num': line_num.toString(),
                            'discount': discount_per_line,
                        };
                        changedData[index][this.state.secondColumnId] = dynamic_editable;
                    }
                    currRow['discount_per_line'] = discount_per_line;
                    currRow[this.state.secondColumnId] = dynamic_editable;
                    // Stop loop and exit when matching line number gets updated.
                    return false;
                }
                // Continue loop until matching line number found.
                return true;
            });
        });
        // Update state with imported data and changed data details.
        this.setState({
            ...this.state,
            data: currentData,
            changedDataDetails: changedData
        });
        // Sending changed data details back to Parent Component.
        this.props.setChangedDataDetails(changedData);
    }
    updateColumnId = (optionType) => {
        var columnId = '';
        switch (optionType) {
            case '1':
                // Option Type = 1  ==> Mark-up Based
                // Id = markup_per_line
                columnId = 'markup_per_line'
                break;
            case '2':
                // Option Type = 2 ==> End Customer Discount Based
                // Id = cust_disc_line
                columnId = 'cust_disc_line'
                break;
            case '3':
                // Option Type = 3 ==> Cisco Consolidated Markup on Cisco Consolidated Based
                // Id = markup_per_line_consolidated
                columnId = 'markup_per_line_consolidated'
                break;
            case '4':
                // Option Type = 4 ==> Cisco Net Markup on Cisco Net Based
                // Id = markup_per_line_net
                columnId = 'markup_per_line_net'
                break;
            case '5':
                // Option Type = 5 ==> End Customer Price Based
                // Id = nothing yet
                console.log(this.props.estimateType)
                if (this.props.estimateType === 'T6' || this.props.estimateType === 'T6R') {
                    columnId = 'contract_total_value'
                } else {
                    columnId = 'quoted_rate'
                }
                break;
            default:
                columnId = 'markup'
        }
        this.setState({
            // Csvdata:this.props.Data,
            data: this.props.data,
            secondColumnId: columnId
        });
        this.getCSVDATA(this.props.data);
    }
    makeDeleteRowReq = () => {
        this.props.deleteRowReqCallback(this.state.deleteRowsDetails);
    }

    // Change handlers.
    handleCellChange = (cellInfo, id, newValue) => {
        console.log(cellInfo)
        console.log(id)
        console.log(newValue)
        /**
         * cellInfo - CellInfo Object from ReactTable.
         * id - changed column id for eg. markup_per_line 
         * newValue - pass new value
         * NOTE: Either you can impement your own logic of updating changedDataDetails (Recommended)
         * OR
         * You can use this one. (More complex)
         */
        let data = [...this.state.data];
        let changedDataDetails = this.state.changedDataDetails;
        // let value=parseFloat(event.target.value);
        let value = parseFloat(newValue);
        // Number.prototype.countDecimals is a custom function.
        // Check EstimateForm.js file for function implementation.

        console.log(this.state.secondColumnId)
        console.log(id)
        if (value.countDecimals() > 4) {
            value = value.toFixed(4);
        }
        if (value > 100 && id !== 'quoted_rate' && id !== "xaas_monthly_unit" && id !== "contract_total_value") {
            value = value / 10;
        }
        data[cellInfo.index][id] = value;
        changedDataDetails[cellInfo.index] = {
            "line_num": data[cellInfo.index]["line_num"],
            "discount": data[cellInfo.index]["discount_per_line"],
        };
        if (id === this.state.secondColumnId) {
            changedDataDetails[cellInfo.index][this.state.secondColumnId] = value;
        } else {
            console.log(data[cellInfo.index][this.state.secondColumnId])
            if (
                (this.state.secondColumnId === "quoted_rate")
                &&
                (data[cellInfo.index][this.state.secondColumnId] === undefined)
            ) {
                changedDataDetails[cellInfo.index][this.state.secondColumnId] = 0;
            } else {
                changedDataDetails[cellInfo.index][this.state.secondColumnId] = data[cellInfo.index][this.state.secondColumnId];
            }
        }
        this.setState({ data, changedDataDetails });
        // Sending Changed Data Details To Parent Component
        this.props.setChangedDataDetails(changedDataDetails);
    }
    handleCheckBox = (e, rowDetails) => {
        // console.log(rowDetails);
        // console.log(e.target.checked);
        if (e.target.checked) {
            this.addDeleteRow(rowDetails);
        } else {
            this.removeDeleteRow(rowDetails);
        }
    }
    removeDeleteRow = (rowDetails) => {
        var newDeleteRowDetails = new Set([...this.state.deleteRowsDetails]);
        newDeleteRowDetails.delete(rowDetails.id);
        this.setState({
            ...this.state,
            deleteRowsDetails: newDeleteRowDetails
        });
        // this.props.setDeleteRowsDetails(newDeleteRowDetails);
    }
    addDeleteRow = (rowDetails) => {
        // const val = {
        // "id": rowDetails.id,
        // "line_num": rowDetails.line_num,
        // };
        var newDeleteRowDetails = new Set([...this.state.deleteRowsDetails]);
        newDeleteRowDetails.add(rowDetails.id);
        this.setState({
            ...this.state,
            deleteRowsDetails: newDeleteRowDetails
        });
    }

    // Rendering help functions.
    conditionHeader = () => {
        const opType = this.props.optionType;
        switch (opType) {
            case '1':
                return "Proactive Mark-up (%)";
            case '2':
                return "End Customer Discount (%)";
            case '3':
                return "Markup on Cisco Consolidated (%)";
            case '4':
                return "Markup on Cisco Product (%)";
            case '5':
                if (this.props.estimateType === 'T6' || this.props.estimateType === 'T6R') {
                    return "Monthly Unit List Price (Rs)"
                }
                else {
                    return "End Customer Price (Rs)";

                }
            default:
                return "Proactive Mark-up (%)";
        }
    }
   
    renderEditable = (cellInfo) => {
        const rowValue = this.state.data[cellInfo.index];
        console.log(cellInfo)  
        console.log(this.props.estimateType)
        // console.log(cellInfo.index) 
        // if (this.props.estimateType === "T2" && this.props.optionType === '5' && item?.conditionHeader) {}
        var cellValue = 0;
        var id = 0;
        if((cellInfo.column.id === 'contract_total_value') && (this.props.estimateType === "T6" || this.props.estimateType === "T6R")){
            id = 'contract_total_value';
            cellValue = rowValue[id];
            console.log(cellValue)
        }
        if (cellInfo.column.id === 'markup_per_line' || cellInfo.column.id === 'edit-2') {
            switch (rowValue.option_type) {
                case '1':
                    // Option Type = 1  ==> Mark-up Based
                    // Id = markup_per_line
                    id = 'markup_per_line'
                    //id='edit-2'
                    break;
                case '2':
                    // Option Type = 2 ==> End Customer Discount Based
                    // Id = cust_disc_line
                    id = 'cust_disc_line'
                    break;
                case '3':
                    // Option Type = 3 ==> Cisco Consolidated Markup on Cisco Consolidated Based
                    // Id = markup_per_line_consolidated
                    id = 'markup_per_line_consolidated'
                    break;
                case '4':
                    // Option Type = 4 ==> Cisco Net Markup on Cisco Net Based
                    // Id = markup_per_line_net
                    id = 'markup_per_line_net'
                    break;
                case '5':
                    // Option Type = 5 ==> End Customer Price Based
                    // Id = nothing yet
                    if (this.props.estimateType === "T6" || this.props.estimateType === "T6R") {
                        id = "xaas_monthly_unit"
                    } else {
                        id = 'quoted_rate'
                    }
                    // contract_total_value
                    break;
                default:
                    id = 'markup_per_line'
            }
            cellValue = rowValue[id];
            // console.log(cellValue)

           
        } else {
            id = cellInfo.column.id;
            cellValue = rowValue[cellInfo.column.id];
        }
        if (id === 'edit-1') {
            id = 'discount_per_line';
            cellValue = rowValue[id];
        }
        
            // console.log(cellValue)
        // Rounding off to 4 decimal places
        if (parseFloat(cellValue) === parseInt(cellValue)) {
            cellValue = parseInt(cellValue);
        } else {
            if (parseFloat(cellValue).countDecimals() > 4) {
                cellValue = parseFloat(cellValue).toFixed(4);
            }
        }
        let inputProps = { min: 0, max: 100 };
        if (id === "xaas_monthly_unit") {
            inputProps = { min: 0 };
        }
        // console.log(cellValue)
        // if(this.props.estimateType === "T2" && this.props.optionType ==="5"){
        //     console.log(cellValue)
        //     return ( 
        //         cellValue===undefined?"":cellValue
        //     )
        // }else{
        console.log(id)
        return (
            <TextField
                size="small"
                name="input"
                type="number"
                disabled={
                    (this.props.estimateType === "T2" && this.props.optionType === '5' && id === 'quoted_rate') 
                    ||  
                    (this.props.estimateType === "T6R" && id === 'discount_per_line') 
                    ||
                    ((this.props.estimateType === "T6R" || this.props.estimateType === "T6") && this.props.optionType === '5' && id === 'xaas_monthly_unit') 
                    ||
                    ((this.props.estimateType === "T6R" || this.props.estimateType === "T6") && this.props.optionType !== '5' && id === 'contract_total_value') 
                    ? 'disable':this.props.disableEdit}
                value={cellValue === undefined ? "0" : cellValue}
                onChange={event => this.handleCellChange(cellInfo,id, event.target.value)}
                // onBlur={this.handleCellChange.bind(null,cellInfo,id)}
                InputProps={{
                    inputProps: inputProps,
                    style: {
                        fontSize: '1.1em',
                        fontWeight: 500
                    }
                }}
            />

        )
        // }

    }

    // For building columnHeaders array for this table.
    buildTableHeadersArray = (item) => {
        var retVal = {};
        console.log(item)
        if (item.title === 'Delete' && item.showFor.has(this.props.estimateType)) {
            // retVal.Header = item.title;\
            retVal.Header = () => (
                <ConfirmDialog
                    renderButton={(handleOpen) =>
                        <DeleteButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleOpen}>Delete</DeleteButton>
                    }
                    dialogTitle={"Are you sure you want to delete the selected rows ?"}
                    confirmCallback={this.makeDeleteRowReq}
                />
            );
            // <div>
            //     <DeleteButton variant="contained" color="secondary" size="small">Delete</DeleteButton>
            // </div>
            retVal.headerClassName = 'view-more-table-header';
            retVal.Cell = props => (
                <div style={{
                    'display': 'flex',
                    'height': '100%',
                    'alignItems': 'center',
                    'justifyContent': 'center',
                }}>
                    <input type="checkbox" onClick={e => this.handleCheckBox(e, props.original)} />
                </div>
            );
            retVal.sortable = false;
            return retVal;
        }

        retVal = {
            Header: item.title,
            accessor: item.accessor,
            headerClassName: 'view-more-table-header'
        };
        if (item?.id) retVal.id = item.id;
        if (item.show === false) retVal.show = item.show;
        if (item?.width) retVal.width = item.width;
        if (item?.currency) {
            retVal.Cell = props => (
                <span className="number">
                    {(Math.round(props.value * 100) / 100).toLocaleFixed(2)}
                </span>
            );
        }
        if (item?.integer) {
            retVal.Cell = props => (
                <span>{isNaN(parseInt(props.value)) ? "-" : parseInt(props.value)}</span>
            );
        }
        if (item?.Cell) retVal.Cell = item.Cell;
        // if (item?.editable) retVal.Cell = this.renderEditable;
        if (item?.conditionHeader) retVal.Header = this.conditionHeader();
        // console.log(item?.conditionHeader)
        if (this.props.estimateType === "T2" && this.props.optionType === '5' && item?.conditionHeader) {
            if (item?.editable) retVal.Cell  = this.renderEditable;
            console.log(item)
        } else {
            if (item?.editable) retVal.Cell = this.renderEditable;
        }


        if ((this.props.estimateType === "T6"||this.props.estimateType === "T6R") && this.props.optionType === '5' && item?.conditionHeader) {
            if (item?.editable) retVal.Cell  = this.renderEditable;
            // console.log(item)
        }

        if (item?.showFor) {
            // Only show for T6.
            console.log(item.showFor)
            console.log(item.showFor)
            console.log(this.props.estimateType)
            if (item.showFor.has("T6")) retVal.show = (this.props.estimateType === "T6" || this.props.estimateType === "T6R");
            if (item.showFor.has("T2")) retVal.show = this.props.estimateType === "T2";
            if (item.showFor.has("T1")) retVal.show = this.props.estimateType === "T1"; 

        }
        if (item?.dontShowFor) {
            // Don't show for T6 but show for rest.
            if (item.dontShowFor.has("T6")) retVal.show = (this.props.estimateType !== "T6" || this.props.estimateType !== "T6R"); 
            if (item.dontShowFor.has("T2")) retVal.show = this.props.estimateType !== "T2";
            if (item.dontShowFor.has("T2") && item.dontShowFor.has("T6")) {
                retVal.show = (this.props.estimateType !== "T6" || this.props.estimateType !== "T6R") && this.props.estimateType !== "T2";
            }
        }
        if (item?.dateTime) {
            retVal.Cell = props => {
                if (props.value === "") return <span>{"-"}</span>;
                const { formattedDate, formattedTime } = dateTimeFormatting(props.value);
                return <span>{formattedDate}</span>;
            }
        }
        if (item?.boolean) {
            retVal.Cell = props => (
                props.value ? (
                    <CheckIcon style={{ color: "green" }} />
                ) : (
                    <CloseIcon style={{ color: "red" }} />
                ))
        }
        return retVal;
    }

    // Table columns details.
    columnHeaders = viewProductColumns.map(header => this.buildTableHeadersArray(header));

    // Setting export data.
    getCSVDATA(data) {
        var exportHeadersList = new Set([]);
        var editableColumns = new Set([]);
        const CSVDATA = data.map(d => {
            const csvData = {};
            this.columnHeaders.forEach(column => {
                // Only add those columns which satisfy these conditions:
                // -> Show property != false.  (AND)  Is present in exportColumns set.
                //      (OR)
                // -> Is an editable column.
                if ((column.show !== false && exportColumns.has(column.Header)) || editableColumnAccessorId.has(column.id)) {
                    // Arrange csv data for exporting.
                    csvData[column.Header] = d[column.accessor];
                    // Add header to export header list.
                    exportHeadersList.add(column.Header);
                    // If column is editable add to list.
                    if (editableColumnAccessorId.has(column.id)) editableColumns.add(column.Header);
                } else if (column.Header === 'Line No.') {
                    // **NOTE: Always add Line No. to exporting excel 
                    // DO NOT REMOVE THIS. We are using this line no. when import excel again to 
                    // match rows.
                    exportHeadersList.add(column.Header);
                    csvData[column.Header] = d[column.accessor];
                }
            });
            return csvData;
        });
        // Callbacks for setting states.
        this.props.setEditableColumns(editableColumns);
        this.props.setExportHeadersList(exportHeadersList);
        this.props.setCsvDATA(CSVDATA);
    };

    render() {
        return (
            <ReactTable
                className="-striped table-style"
                style={{ overflow: "wrap", width: "100vw", height: "82vh" }}
                previousText="<"
                nextText=">"
                defaultPageSize={18}
                resizable={false}
                data={this.state.data}
                showPageJump={false}
                columns={this.columnHeaders}
                getTheadProps={() => {
                    return {
                        style: { background: 'rgb(47, 38, 85)', color: 'whitesmoke' }
                    }
                }}
                getTbodyProps={() => {
                    return {
                        style: {
                            overflowX: "hidden",
                        }
                    }
                }}
                getTrProps={(state, ri, ci, instance) => {
                    if (ri) {
                        if (this.state.deleteRowsDetails.has(ri.original.id)) {
                            return {
                                // onClick: (e) => {
                                //     const elem = document.getElementById(ri.original.id);
                                //     elem.checked = !elem.checked;
                                //     elem.click();
                                // },
                                style: {
                                    backgroundColor: 'rgba(255, 0, 0, 0.2)'
                                }
                            }
                        }
                        // return {
                        //     onClick: (e) => {
                        //         const elem = document.getElementById(ri.original.id);
                        //         elem.checked = !elem.checked;
                        //         elem.click();
                        //     }
                        // };
                    }
                    return { ri };
                }}
                getTdProps={() => {
                    return {
                        style: {
                            textAlign: 'center',
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                            fontSize: '0.75em',
                            padding: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                        }
                    }
                }}
            />
        )
    }
}

EstimateViewProductTable.propTypes = {
    data: PropTypes.array,
    importCsvData: PropTypes.array,
    setChangedDataDetails: PropTypes.func,
    setCsvDATA: PropTypes.func,
    estimateId: PropTypes.string,
    optionType: PropTypes.string,
    estimateType: PropTypes.string,
    setExportHeadersList: PropTypes.func,
    setEditableColumns: PropTypes.func,
    disableEdit: PropTypes.bool,
}

const DeleteButton = styled(Button)`
    text-transform: none;
    padding: 0;
    font-size: 0.75rem;    
`;

export default EstimateViewProductTable;