import React,{Component} from 'react';
import TableData from '../table/TableData';
import PropTypes from 'prop-types';
/**
 * This component is used to retreive purchase order data and display it using Table Data component.
 */
const BASE_URI='/pro';
class PurchaseOrderData extends Component{
    static propTypes={
        /**
         * User Token.
         */
        token:PropTypes.string,
        /**
         * API call to get data from backend.
         * Gets called on this component mount.
         **/
        getData:PropTypes.func,
    }
    constructor(props){
        super(props);
        this.state={
            data:[],
            token:this.props.token,
            responseStatus:0,
            loadingData:true,
        };
        this.getData=this.getData.bind(this);
    }
    getData(){
        console.log(this.state.token)
        let responseStatus=0;
        fetch(`${BASE_URI}/getPoApi/`,{
            method:"GET",
            headers:{
                Authorization: `Token ${this.state.token}`,
            }
            
        })
       
        .then(response=>{
            responseStatus=response.status;
            if(responseStatus===204||responseStatus===500||responseStatus===404){
                // Empty
                return [{}];
            }
            return response.json();
        })
        .then(responseJSON=>{
            if(responseStatus===204||responseStatus===500||responseStatus===404){
                this.setState({data:[],responseStatus,loadingData:false});
            }else{
                this.setState({data:responseJSON,responseStatus,loadingData:false});
            }
        });
    }
    componentDidMount(){
        this.getData();
    }
    render(){
        return(
            <div className="container-fluid">
                <TableData
                Data={this.state.data}
                refreshData={this.getData}
                typeModal={1}
                ResponseStatus={this.state.responseStatus}
                LoadingData={this.state.loadingData}
                />
            </div>
        );
    }
}

export default PurchaseOrderData;