import React,{useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'css/tabledata.css';
import ButtonComponent from 'components/table/ButtonComponent';
import VendorDetails from 'components/table/VendorDetails';
import TermsModal from 'components/table/TermsModal';
import PurchaseHover from 'components/table/PurchaseHover';
import {Button} from '@material-ui/core';

import 'react-table-hoc-fixed-columns/lib/styles.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import CustomizedSnackbars from 'components/notification/CustomizedSnackbars';
import BackdropLoader from 'components/loaders/BackdropLoader';

const ReactTableFixedColumns=withFixedColumns(ReactTable);

// eslint-disable-next-line
Number.prototype.toLocaleFixed=function(n){
    return this.toLocaleString("en-IN",{
        minimumFractionDigits:n,
        maximumFractionDigits:n
    });
}
const BASE_URI='/pro';
const useStyles=makeStyles(theme=>({
    appBar:{
        position:'relative',
        // position:'sticky',
        // position:'-webkit-sticky',
        // position:'-moz-sticky',
        // position:'-ms-sticky',
        // position:'-o-sticky',
        backgroundImage:'linear-gradient(to left, #c8c8c8, #c4c3cd, #bfbfd2, #b8bbd7, #b0b7dc, #b0b7dc, #b0b7dc, #b0b7dc, #b8bbd7, #bfbfd2, #c4c3cd, #c8c8c8);',
    },
    title:{
        marginLeft:theme.spacing(2),
        flex:1,
        color:'black',
        fontWeight:'600',
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ViewMoreModal=(props)=>{
    console.log(props)
    const classes=useStyles();
    const headerStyle={
        fontWeight:"500",
        // borderStyle:'solid solid solid solid',
        // borderWidth:'thin',
    };
    const columnHeaders=[
        {
            Header:"Document Line No.",
            headerClassName:"view-more-table-header",
            fixed:"left",
            width:75,
            accessor:"Document Line No_",
            Cell:props=><div className="test view-more-table-data"><span>{props.value}</span></div>,
            filterMethod:(filter,row)=>{
                if(filter.value===row['Document Line No_']){
                    return false;
                }
                return true;
            }
        },
        {
            Header:"Document No",
            accessor:"Document No_",
            Cell:props=><div className="test view-more-table-data"><span>{props.value}</span></div>,
            show:false,
        },
        {
            Header:<span>Part No.</span>,
            accessor:"Part No_",
            fixed:"left",
            Cell:props=><div className="view-more-table-data">{props.value}</div>,
            headerClassName:"view-more-table-header",
        },
        {
            Header:<span>Description</span>,
            accessor:"Description",
            width:130,
            Cell:props=><div className="view-more-table-data">{props.value}</div>,
            headerClassName:"view-more-table-header",
        },
            // More Additional Fields
        {
            Header:<span>Qty</span>,
            accessor:"Quantity",
            width:50,
            Cell:props=><div className="number view-more-table-data">{Math.round(props.value*100)/100}</div>,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        {
            accessor:"Theoritical Cost",
            Header:<span>Theoretical Cost</span>,
            headerClassName:"view-more-table-header",
            className:"number",
            width:80,
            Cell:props=><span className="number view-more-table-data">{
                props.value!=="0E-20"?
                (Math.round(props.value*100)/100).toLocaleFixed(2)
                :
                "-"
            }</span>
        },
        {
            Header:<span>PO Cost</span>,
            accessor:"Unit Cost",
            // Cell:props=><div className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</div>,
            Cell:props=><span className="number view-more-table-data">{
                    props.value!=="0E-20"?
                    (Math.round(props.value*100)/100).toLocaleFixed(2)
                    :
                    "-"
                }</span>,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        {
            Header:<span>PO Total</span>,
            accessor:"Amount",
            // Cell:props=><span className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</span>,
            Cell:props=><span className="number view-more-table-data">{
                    props.value!=="0E-20"?
                    (Math.round(props.value*100)/100).toLocaleFixed(2)
                    :
                    "-"
                }</span>,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        {
            Header:"PO Total (LCY)",
            accessor:"Amount (LCY)",
            // Cell:props=><span className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</span>,
            Cell:props=><span className="number view-more-table-data">{
                    props.value!=="0E-20"?
                    (Math.round(props.value*100)/100).toLocaleFixed(2)
                    :
                    "-"
                }</span>,
            width:105,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        {
            Header:<span>Sales Amount (LCY)</span>,
            accessor:"Sales Line Amount",
            // Cell:props=><span className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</span>,
            Cell:props=><span className="number view-more-table-data">{
                    props.value!=="0E-20"?
                    (Math.round(props.value*100)/100).toLocaleFixed(2)
                    :
                    "-"
                }</span>,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        {
            Header:<span>Theoretical Margin (LCY)</span>,
            accessor:"Theoritical Margin",
            width:125,
            // Cell:props=><span className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</span>,
            Cell:props=><span className="number view-more-table-data">{
                    props.value!=="0E-20"?
                    <div>
                        {console.log(props.value)}
                        {(Math.round(props.value*100)/100).toLocaleFixed(2)} ({
                                props.original["Theoritical Margin _"]!=="0E-20"?
                                // (Math.round(props.original["Theoretical Margin_"]*100)/100).toLocaleFixed(2)
                                <span>{parseFloat(props.original["Theoritical Margin _"]).toLocaleFixed(2)} %</span>
                                :
                                <span>0 %</span>
                            })
                    </div>
                    :
                    "-"
                }</span>,
            headerClassName:"view-more-table-header",
            className:"number",
        },
        // Additional Columns
        {
            Header:<span>Last Purchase Cost - 1</span>,
            accessor:'Ist Purchase Unit Cost',
            headerClassName:"view-more-table-header",
            // Cell:props=><span className="number view-more-table-data">{(Math.round(props.value*100)/100).toLocaleString('en-IN')}</span>,
            Cell:props=><span className="number view-more-table-data">{
                props.value!=="0E-20"?
                (Math.round(props.value*100)/100).toLocaleFixed(2)
                :
                "-"}</span>,
            className:"number",
        },
        {
            Header:<span>Last PO Date - 1</span>,
            accessor:'Ist Posting Date',
            headerClassName:"view-more-table-header",
            Cell:props=><div className="modal-details-text view-more-table-data">
            {
                props.value==="1753-01-01T00:00:00"
                ?
                "-"
                :
                <div>
                {Intl.DateTimeFormat('en-IN',{
                    dateStyle:'medium',
                }).format(new Date(props.value))}
                </div>
            }</div>
        },
        {
            Header:<span>Last Purchase Vendor - 1</span>,
            accessor:'Ist Purch_ Vendor Name',
            // Cell:props=><div className="view-more-table-data">{props.value}</div>,
            Cell:props=><PurchaseHover VendorName={props.value} InvoiceNo={props.original["Ist Purch Invoice No_"]} TextClasses="view-more-table-data"/>,
            headerClassName:"view-more-table-header",
        },
        {
            Header:<span>Last Purchase Cost - 2</span>,
            accessor:'IInd Purchase Unit Cost',
            headerClassName:"view-more-table-header",
            Cell:props=><span className="number view-more-table-data">{
                props.value!=="0E-20"?
                (Math.round(props.value*100)/100).toLocaleFixed(2)
                :
                "-"}</span>,
            className:"number",
        },
        {
            Header:<span>Last PO Date - 2</span>,
            accessor:'IInd Posting Date',
            headerClassName:"view-more-table-header",
            Cell:props=><div className="modal-details-text view-more-table-data">
            {
                props.value==="1753-01-01T00:00:00"
                ?
                "-"
                :
                <div>
                {Intl.DateTimeFormat('en-IN',{
                    dateStyle:'medium',
                }).format(new Date(props.value))}
                </div>
            }</div>
        },
        {
            Header:<span>Last Purchase Vendor - 2</span>,
            accessor:'IInd Purch_ Vendor Name',
            // Cell:props=><div className="view-more-table-data">{props.value}</div>,
            Cell:props=><PurchaseHover VendorName={props.value} InvoiceNo={props.original["IInd Purch Invoice No_"]} TextClasses="view-more-table-data"/>,
            headerClassName:"view-more-table-header",
        },
    ]
    const [filtered,setFiltered]=useState([]);
    const [docNumber,setDocNumber]=useState('');
    const [data,setData]=useState([]);
    const [open,setOpen]=useState(false);
    const [baseLine,setBaseLine]=useState([]);
    const [showVendorModal,setShowVendorModal]=useState(false);
    const [dataVendorModal,setDataVendorModal]=useState([]);
    const [responseVendorModal,setResponseVendorModal]=useState(0);
    const [showTermsModal,setShowTermsModal]=useState(false);
    const [dataTermsModal,setDataTermsModal]=useState([]);
    const [loadingVendorDetails,setLoadingVendorDetails]=useState(false);
    const [totalTheoreticalMargin,setTotalTheoreticalMargin]=useState(0);
    const [totalPOValue,setTotalPOValue]=useState(0);
    useEffect(()=>{
        setFiltered([{id:'Document No_',value:props.DocNumber},{id:"Document Line No_",value:props.DocLineNumber}]);
        setDocNumber(props.DocNumber);
        setOpen(props.Open);
        setData(props.Data);
        setBaseLine(props.BaseLine);
        setDataTermsModal({
            paymentTermsDesc:props["Payment Terms Description"],
            shipmentTermsDesc:props["Shipment Terms Description"],
        });
        setTotalTheoreticalMargin(props.TotalTheoreticalMargin.total.toLocaleFixed(2));
        calculateTotalPoValue(props.Data);
    },[props]);
    const calculateTotalPoValue=(data)=>{
        let total=0;
        for(var entry in data){
            let line=data[entry];
            if(line["Document Line No_"]!==0){
                total=total+Number(line["Amount (LCY)"])
            }
        }
        setTotalPOValue(total);
    }
    const handleClose=()=>{
        setOpen(false);
        props.CloseModal();
    }
    const handleVendorModalClose=()=>{
        setShowVendorModal(false);
        setDataVendorModal([]);
    }
    const handleVendorModalOpen=()=>{
        setLoadingVendorDetails(true);
        getVendorData(baseLine["Source No_"],sessionStorage.getItem('token'));
    }
    const getVendorData=(vendorCode,token)=>{
        setTimeout(async function(){
            const response=await fetch(`${BASE_URI}/getVendorApi/${vendorCode}`,{
                method:"GET",
                headers:{
                    Authorization: `Token ${token}`,
                }
            });
            if(response.status===204||response.status===500||response.status===404){
                setResponseVendorModal(response.status);
                setDataVendorModal([{}]);
            }else{
                const responseJSON=await response.json();
                setResponseVendorModal(200);
                setDataVendorModal(responseJSON);
            }
            setShowVendorModal(true);
            setLoadingVendorDetails(false);
        },1000);
    }
    const handleTermsModalOpen=()=>{
        setDataTermsModal({
            paymentTermsDesc:baseLine["Payment Terms Description"],
            shipmentTermsDesc:baseLine["Shipment Terms Description"],
            // paymentTermsDesc:baseLine["paymentTermsDescription"],
            // shipmentTermsDesc:baseLine["ShipmentTermsDescription"],
        });
        setShowTermsModal(true);
    }
    const handleTermsModalClose=()=>{
        setShowTermsModal(false);
    }
    return (
        <React.Fragment>
        <BackdropLoader Open={props.ShowLoader}/>
        <div style={{width:"100vw"}}>
        {
            showVendorModal?
            <VendorDetails
            Open={showVendorModal}
            CloseCallback={handleVendorModalClose}
            Data={dataVendorModal}
            Response={responseVendorModal}
            />
            :
            null
            }
        {
            showTermsModal?
            <TermsModal
            Open={showTermsModal}
            CloseCallback={handleTermsModalClose}
            Data={dataTermsModal}
            />
            :null
        }
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            {/* Error for view more details*/}
            {props.ResponseStatus===500?<CustomizedSnackbars Variant="error" Message="Server Error. Unable to get PO comments data. Please try refresh." Open={true}/>:null}
            {props.ResponseStatus===404?<CustomizedSnackbars Variant="error" Message="Server Error. Please contact administrator." Open={true}/>:null}
            {props.ResponseStatus===204?<CustomizedSnackbars Variant="info" Message="No further data for this PO is available." Open={true}/>:null}
            {/* Error for vendor details */}
            {responseVendorModal===500?<CustomizedSnackbars Variant="error" Message="Server Error. Please try refresh." Open={true}/>:null}
            {/* Loader for View More Details */}
            <BackdropLoader Open={loadingVendorDetails}/>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{color:"black"}}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="subtitle2" className={classes.title}>
                        PO No: {docNumber}<br/>
                        Customer Name: {baseLine["Customer Name"]===""?"-":baseLine["Customer Name"]}<br/>
                        Vendor: {baseLine["Source No_"]===""?"-":baseLine["Source No_"]}<button type="button" style={{padding:0,paddingLeft:5}}onClick={handleVendorModalOpen} className="btn btn-link"><Typography variant="subtitle2" style={{color:"black",fontWeight:600}}>View Details</Typography></button><br/>
                        Vendor Name: {baseLine["Source Name"]}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title}>
                        PO Date: {
                                        baseLine["Posting Date"]==="1753-01-01T00:00:00"||baseLine["Posting Date"]===undefined
                                        ?
                                        "-"
                                        :
                                        Intl.DateTimeFormat('en-IN',{
                                            dateStyle:'medium',
                                        }).format(new Date(baseLine["Posting Date"]))
                                        }<br/>
                        Branch Name: {
                                        baseLine["Branch Name"]===""
                                        ?"-":baseLine["Branch Name"]
                                     }<br/>
                                     {console.log(typeof(baseLine["Amount Excluding GST"]))}
                        PO Value: <span className="number">{(Math.round(baseLine["Amount Excluding GST"]*100)/100).toLocaleFixed(2)}</span><br/>
                        Currency Code : {baseLine["Currency Code"]!==""?baseLine["Currency Code"]:"INR"}<br/>
                        Total Theoretical Margin : <span className="number">{totalTheoreticalMargin} ({((props.TotalTheoreticalMargin.total/totalPOValue)*100).toLocaleFixed(2)} %)</span>
                    </Typography>
                    <Button variant="contained" color="primary" disableElevation onClick={handleTermsModalOpen} style={{textTransform:"capitalize"}}>
                        <Typography variant="subtitle2">Terms</Typography>
                    </Button>
                    <ButtonComponent
                    RowStatus={props.RowStatus}
                    HandleApproveClick={props.ClickHandlerApprove}
                    HandleRejectClick={props.ClickHandlerReject}
                    />
                </Toolbar>
            </AppBar>
            <ReactTableFixedColumns
            className="-striped table-style"
            previousText="<"
            nextText=">"
            // style={{overflow:"wrap",width:"150vw",marginTop:"86px"}}
            style={{overflow:"wrap",width:"100vw"}}
            // style={{overflow:"wrap"}}
            data={data}
            filtered={filtered}
            showPageJump={false}
            columns={columnHeaders}
            defaultPageSize={10}
            resizable={false}
            getTheadProps={()=>{
                return {
                    style:headerStyle,
                }
            }}
            getTdProps={()=>{
                return {
                    style:{
                        borderStyle:'none solid none solid',
                        borderWidth:'thin',
                    }
                }
            }}
            getTheadThProps={()=>{
                return {
                    style:{
                        borderStyle:'solid solid none solid',
                        // borderStyle:'solid',
                        borderWidth:'0.15em',
                        borderBottom:'solid 0.1em',
                    }
                }
            }}
            getTrGroupProps={()=>{
                return {
                    style:{
                        // borderStyle:'none solid none solid',
                        borderStyle:'solid',
                        borderWidth:'thin',
                    }
                }
            }}
            />
        </Dialog>
        </div>
        </React.Fragment>
    );
}

export default ViewMoreModal;