import React from 'react';
import '../css/tickmarkicon.css';
import PropTypes from 'prop-types';
/**
 * This component is used for animated tick mark icon.
 */
const TickMarkIcon=props=>{
    return (
            <div className="checkmark-circle">
                <div className="background" style={{background:props.bgColor}}>
                    <div className="checkmark draw"/>
                </div>
            </div>
    );
}
TickMarkIcon.propTypes={
    /**
     * Background Color of Tick Mark.
     */
    bgColor:PropTypes.string,
}

export default TickMarkIcon;