import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
// React Table v7 imports.
import { 
  useTable, 
  usePagination, 
  useExpanded,
  // useAbsoluteLayout,
  // useBlockLayout,
  // useFlexLayout,
  useFilters,
  useSortBy
} from 'react-table';
import { matchSorter } from 'match-sorter';
// Material UI components imports.
import {
  TablePagination,
  IconButton,
} from '@material-ui/core';
// Material UI icon imports.
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// Helpful for combining multiple conditional classNames.
import cx from 'classnames';

// Table styling.
const Styles = styled.div`
    /* Make table full width. */
    display: block;
    max-width: 100%;
    overflow: auto;
    /* Makes table scrollable when screen is too small. */
    .tableWrap {
        display: block;
        /* min-height: 80vh; */
        max-width: 100%;
        overflow: auto;
        /* overflow-x: scroll; */
        /* overflow-y: hidden; */
    }
    table {
        width: 100%;
        border-spacing: 0;
        border: 1px solid black;
        
        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        th, td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            /* Each cell should grow equally */
            /* width: 1%; */
            /* But "collapsed" cells should be as small as possible */
            &.collapse {
              width: 0.0000000001%;
            }
            :last-child {
                border-right: 0;
            }
            input {
              font-size: 0.75rem;
              height: 100%;
              padding: 0;
              margin: 0;
              border: 0;
              /* border-bottom: 0.5px solid black; */
              width: 100%;
              &:focus{
                outline: none;
                /* border-bottom: 1px solid #4D90FE; */
              }
            }
        }
        th {
          font-size: 0.75rem;
          text-align: center;
        }
        td {
          /* display: flex; */
          font-size: 0.75rem;  
          /* overflow: auto; */
        }
        .row-clickable { 
          cursor: pointer;
          &:hover {
            background-color: #E8E8E8;
          }
        }
        .search-input { 
          border: 0.5px solid grey;
          padding: 0.25rem;
          border-radius: 5px;
          &:focus {
            outline: darkblue;
          }
        }
        .overflow-auto {
          overflow: auto;
        }
    }
    .pagination { 
      padding: 0.5rem;
      justify-content: center;
    }
    .no-scrollbars{
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    
      &::-webkit-scrollbar {
        width: 1px;
      }
    
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
    .no-scrollbars::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
`;

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val;

const Table = ({ 
    columns, 
    data, 
    disablePagination = false,
    renderRowSubComponent = null, 
    defaultPageSize = null,
    defaultColumn = {},
    hiddenColumns = []
  }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        visibleColumns,
        // For pagination.
        nextPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { hiddenColumns } 
        },
        useExpanded,
        usePagination,
    );

    // For filling empty rows.
    const createEmptyRow = () => {
      return(
        headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    <td {...column.getHeaderProps()}>
                        <div><span>&nbsp;</span></div>
                    </td>
                ))}
            </tr>
        ))
      );
    };
    const getEmptyRows = () => {
      let emptyRows = [];
      if(data.length===0){
        // No data received. Render no data row.
        emptyRows.push(<tr key="empty"><td colSpan={visibleColumns.length-1}>No data.</td></tr>);
        return emptyRows;
      }
      if(page.length%pageSize !== 0 && !nextPage)
        for(let i = 0;i<(pageSize-page.length%pageSize);i++)
          emptyRows.push(createEmptyRow());
      if(data.length === 0 || page.length === 0)
        for (let i = 0; i < pageSize; i++)
          emptyRows.push(createEmptyRow());
      return emptyRows
    };

    // useEffects.
    useEffect(()=>{
      if(defaultPageSize!==null){
        setPageSize(Number(defaultPageSize));
      }
    }, [defaultPageSize, setPageSize]);
    
    return (
    <Styles>
        <div className="tableWrap">
        {data.length===0 ?'': <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps({
                              className: column.collapse ? 'collapse' : '',
                            })}>
                              {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <React.Fragment key={i}>
                          <tr 
                            {...row.getRowProps({
                              className: renderRowSubComponent!==null ? 'row-clickable': '',
                            })}
                            onClick={()=>row.toggleRowExpanded(!row.isExpanded)}
                          >
                              {row.cells.map(cell => (
                                  <td {...cell.getCellProps({
                                    className: cx(
                                      { 'collapse': cell.column.collapse },
                                    ) 
                                  })}>{cell.render('Cell')}</td>
                              ))}
                          </tr>
                          {/* If row is expanded. Render Subcomponent. */}
                          {
                            row.isExpanded && renderRowSubComponent && (
                              <tr>
                                <td colSpan={visibleColumns.length}>
                                  { renderRowSubComponent({row})}
                                </td>
                              </tr>
                            )
                          }
                        </React.Fragment>
                    )
                })}
                  {getEmptyRows()}
            </tbody>
        </table>
  }
        {
          data.length===0  ?'':
          !disablePagination &&  
          <div className="pagination">
            <TablePagination 
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              colSpan={4}
              page={pageIndex}
              count={pageOptions.length}
              rowsPerPage={pageSize}
              // page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page'},
                native: true
              }}
              onPageChange={(e, pageNo)=>{
                gotoPage(pageNo)
              }}
              onRowsPerPageChange={e => {
                setPageSize(Number(e.target.value))
              }}
              // Pass default props and any additional props.
              ActionsComponent={(defaultProps)=>
                <CustomTablePaginationActions 
                  canPreviousPage={canPreviousPage} 
                  canNextPage={canNextPage}
                  {...defaultProps}/>
              }
            />
          </div>
        }
      </div>
    </Styles>
    );
};

// TODO: Remove this component and find a way to work with
// conditional hooks for the default table component 
// above.
const EditableTable = ({
  columns, 
  data,
  updateMyData,
  initialSelectedRowIds,
  skipPageReset,
  renderRowSubComponent = null, 
  defaultPageSize = null,
  defaultColumn={},
  hiddenColumns=[]
}) => {
  const defaultCol = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Filter types
  const filterTypes = React.useMemo(
    () => ({
      fuzzyTest: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    visibleColumns,
    // For pagination.
    // nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
        columns,
        data,
        defaultColumn: defaultCol,
        filterTypes,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
        disableMultiSort: true,
        initialState: { hiddenColumns }
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  // useEffects  
  useEffect(()=>{
    if(defaultPageSize!==null){
      setPageSize(Number(defaultPageSize));
    }
  }, [defaultPageSize, setPageSize]);

  return (
      <Styles>
          <div className="tableWrap">
          <table {...getTableProps()}>
              <thead>
                  {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                              <th {...column.getHeaderProps({
                                className: column.collapse ? 'collapse' : '',
                              })}>
                                <div>
                                  <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    {
                                      column.isSorted
                                      ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                      : ''
                                    }
                                  </span>
                                </div>
                                <div>
                                  {
                                    column.canFilter 
                                    ? 
                                      column?.filter 
                                      ? 
                                      column.render('Filter') 
                                      : null 
                                    : null
                                  }
                                </div>
                              </th>
                          ))}
                      </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                      prepareRow(row)
                      return (
                          <React.Fragment key={i}>
                            <tr 
                              {...row.getRowProps({
                                className: renderRowSubComponent!==null ? 'row-clickable': '',
                              })}
                              onClick={()=>row.toggleRowExpanded(!row.isExpanded)}
                            >
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps({
                                      className: cx(
                                        { 'collapse': cell.column.collapse },
                                      ) 
                                    })}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            {/* If row is expanded. Render Subcomponent. */}
                            {
                              row.isExpanded && renderRowSubComponent && (
                                <tr>
                                  <td colSpan={visibleColumns.length}>
                                    { renderRowSubComponent({row})}
                                  </td>
                                </tr>
                              )
                            }
                          </React.Fragment>
                      )
                  })}
                    {/* {getEmptyRows()} */}
              </tbody>
          </table>
          <div className="pagination">
            <TablePagination 
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              colSpan={4}
              page={pageIndex}
              count={pageOptions.length}
              rowsPerPage={pageSize}
              // page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page'},
                native: true
              }}
              onPageChange={(e, pageNo)=>{
                gotoPage(pageNo)
              }}
              onRowsPerPageChange={e => {
                setPageSize(Number(e.target.value))
              }}
              // Pass default props and any additional props.
              ActionsComponent={(defaultProps)=>
                <CustomTablePaginationActions 
                  canPreviousPage={canPreviousPage} 
                  canNextPage={canNextPage}
                  {...defaultProps}/>
              }
            />
          </div>
        </div>
      </Styles>
    );
};

// Filters 
// Default Filter = Search Input
const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter }, }) => {
  const count = preFilteredRows.length;
  return (
    <input
      className="search-input"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};



const CustomTablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange, canPreviousPage, canNextPage } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page-1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page+1);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count/ rowsPerPage)-1));
  };
  return (
    <>
      <PaginationButton
        onClick={handleFirstPageButtonClick}
        disabled={!canPreviousPage}
        aria-label="first page"
      >
        <FirstPageIcon/>
      </PaginationButton>
      <PaginationButton
        onClick={handleBackButtonClick}
        disabled={!canPreviousPage}
        aria-label="previous page"
      >
        <NavigateBeforeIcon/>
      </PaginationButton>
      <PaginationButton
        onClick={handleNextButtonClick}
        disabled={!canNextPage}
        aria-label="next page"
      >
        <NavigateNextIcon/>
      </PaginationButton>
      <PaginationButton
        onClick={handleLastPageButtonClick}
        disabled={!canNextPage}
        aria-label="last page"
      >
        <LastPageIcon/>
      </PaginationButton>
    </>
  );
};

const PaginationButton = styled((props)=>(
  <IconButton {...props}/>
))`
  &:hover {
    background-color: #bfc9ff;
  }
  &:focus {
    outline: none;
    background-color: #bfc9ff;
  }
`;

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export { Table, EditableTable };