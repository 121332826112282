import React,{Component} from 'react';
import SideNavBar from '../SideNavBar';
import ChangeUserPassword from '../../containers/ChangePassword';

class ChangeUserPasswordView extends Component{
    render(){
        return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2 col-4">
                    {sessionStorage.getItem("usertype")!=="6"?<SideNavBar/>:null}
                </div>
                <div className="col-md-10 col-8">
                    <ChangeUserPassword/>
                </div>
            </div>
        </div>
        );
    }
}
export default ChangeUserPasswordView;