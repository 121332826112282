import React, { useState, createContext } from 'react';
// import PropTypes from 'prop-types';


const PMCContext = createContext({
  uploadExcel: [[],function(){}],
  viewList: [[],function(){}],
});

const PMCProvider = (props) => {
  // Upload Excel Data.
  const [uploadData, setUploadData] = useState({
    metadata: [],
    fileData: []
  });
  // View List Data.
  const [viewList, setViewList] = useState({
    data:[]
  });
  return (
    <PMCContext.Provider 
      value={{
        uploadExcel: [uploadData, setUploadData],
        viewList: [viewList, setViewList]
      }}
    >
      {props.children}
    </PMCContext.Provider>
  );

};

export {PMCContext, PMCProvider};