import PropTypes from 'prop-types';

const BASE_URI = '/pro';

/**
 * Makes fetch requests to the backend APIs. 
 * Only authorized requests are made via this, which requires access tokens.
 * 
 * 
 * @param {string} url - Request URL.
 * @param {string} type - Type of request. GET,POST,PUT,DELETE.
 * @param {Object{}} [body] - Optional. Body data as dictionary (key-value pairs). Not applicable for GET request. 
 * 
 * @returns {Object[], number, boolean|Object} - Returns { responseData, statusCode, error}. responseData: is .json() formatted. [{}]
 */
const fetchAPI = async(url, type, body={}, ignoreBase=false) => {
    var responseData = [];
    var statusCode = 0;
    var error = false;
    const token = sessionStorage.getItem("token");
    let requestOptions = {
        method: type,
        headers: {
            Authorization: `Token ${token}`
        }
    };
    if(type === 'POST' || type === 'PUT' || type === 'DELETE') {
        requestOptions.body = JSON.stringify(body);
    }

    const makeRequest = async(reqOptions) => {
        try {
            const URL = ignoreBase ? `/${url}`:`${BASE_URI}/${url}`;
            const response = await fetch(URL, reqOptions);
            statusCode = response.status;
            if(statusCode === 200) responseData = await response.json();
            if(statusCode === 404 || statusCode === 500) {
                error = true;
            }
        } catch (err) {
            error = true;
            console.log(err);
        }
    }
    
    await makeRequest(requestOptions);
    return { responseData, statusCode, error };
}

fetchAPI.propTypes = {
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    body: PropTypes.shape({})
}

export default fetchAPI;