import React,{Component} from 'react';
import SignUp from '../../containers/SignUp';
import companyLogo from '../../assets/proactive_logo.png';
import '../../css/views/loginview.css';

/**
 * This component is used to make a view for Sign Up page view.
 * @ignore
 */
class SignUpView extends Component{
    render(){
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2">
                        <img className="company-image" src={companyLogo} alt="company-logo"/>
                    </div>
                </div>
                <div className="container h-100 d-flex justify-content-center">
                    <div className="row align-items-center">
                        <div className="col align-self-center">
                            <SignUp/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUpView;