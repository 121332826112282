import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import readExcel from './readExcel';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#bfc9ff',
        '&:hover': {
            backgroundColor: '#9ba3cf'
        }
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    }
}));

const UploadButton = ({ fileInfo, onClickCallback, setDataCallback, fileReadErrorCallback }) => {
    const classes = useStyles();
    const [readData, setReadData] = React.useState({
        metadata: [],
        fileData: []
    });
    React.useEffect(()=>{
        if(readData.fileData.length!==0){
            setDataCallback(readData);
        }
    },[readData]);
    const handleFileUpload = async(e)=>{
        onClickCallback();
        readExcel(e.target.files[0], fileInfo, setReadData, fileReadErrorCallback);
    };
    return (
        <Button variant="contained" component="label" className={classes.button}>
            <BackupIcon className={classes.buttonIcon}/>
            Upload <input type="file" hidden onChange={handleFileUpload}/>
        </Button>
    );
};

export default UploadButton;