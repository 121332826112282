import React,{Component} from 'react';
import CashFlowData from '../components/data/CashFlowData.js';
import '../css/pages/purchaseorder.css';

class CashFlow extends Component{
    render(){
        return(
            <div className="purchase-order ml-4">
                <CashFlowData token={sessionStorage.getItem('token')}/>
            </div>
        );
    }
}

export default CashFlow;