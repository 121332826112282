import React from 'react';
import PropTypes from 'prop-types';
/**
 * Component for rendering buttons based on status of order.
 */
const StatusButton = ({ Type, StatusValue })=>{
    const type = Type;
    const statusValue = StatusValue;
    if(type === "Table"){
        if(statusValue===4){
            return <button type="button" className="btn btn-success status-button">Approved</button>
        }else if(statusValue===3){
            return <button type="button" className="btn btn-danger status-button">Rejected</button>;
        }else if(statusValue===2){
            return <button type="button" className="btn btn-secondary status-button">Cancelled</button>;
        }else if(statusValue===1 || statusValue===0){
            return <button type="button" className="btn btn-warning status-button">Pending</button>;
        }else if(statusValue===5){
            if(sessionStorage.getItem('usertype')==='1'){
                // Admin
                return <button type="button" className="btn btn-warning status-button">Pending</button>
            }else{
                return <button type="button" className="btn btn-info status-button">Final Approval</button>;
            }
        }
        else{
            return <button type="button" className="btn btn-info status-button">Created</button>;
        }
    }else if(type === "Modal"){
        if(statusValue===4){
            return <button type="button" className="btn btn-success mr-2 disabled">Approved12</button>;
        }else if(statusValue===3){
            return <button type="button" className="btn btn-danger mr-2 disabled">Rejected13</button>;
        }else if(statusValue===2){
            return <button type="button" className="btn btn-secondary mr-2 disabled">Cancelled14</button>;
        }else{  
            return null;
        }
    }
}
StatusButton.propTypes={
    // Place where button is required.(String)
    Type:PropTypes.oneOf(["Table","Modal"]).isRequired,
    // Type of button required (Integer)
    StatusValue:PropTypes.oneOf([5,4,3,2,1]),
}
export default StatusButton;